import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
// import { Column } from "primereact/column";
// import { ColumnGroup } from "primereact/columngroup";
// import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
// import { Skeleton } from "primereact/skeleton";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../../components/loader/Loader";
import { GetInvestorIncomeListThunk } from "../../../../store/features/taxmanagement/1099/InterestIncomeByInvestor/getinvestorincomelist/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import { exportExcel } from "../../../../utils/excel-dataformat";
import {
  convertExcelHeaders,
  transformedHeader,
} from "../../../../utils/helper";

// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";

export default function InterestIncomeByInvestor() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  // const portState = useAppSelector((state) => state.GetMenuList.loading);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const list = useAppSelector((state) => state.GetInvestorIncomeList?.data2);
  const title = useAppSelector((state) => state.GetInvestorIncomeList?.data1);
  const supplierIntList = useAppSelector(
    (state) => state.GetInvestorIncomeList?.data
  );

  const [dataCreateElemnts, setDataCreateElemnts] = useState<any>([]);
  const [exportExcelHeader, setExportExcelHeader] = useState<any>([]);
  const [dataTableElements, setdataTableElements] = useState<any>([]);
  const [stateName, setName] = useState<any>(false);
  const [loading, setloading] = useState<any>(false);
  const [columnSums, setColumnSums] = useState<any>([]);
  const [openDailog, setOpenDailog] = useState(false);

  const previousYear = new Date().getFullYear();
  const currentYear = previousYear - 1;

  const inputData: any = [];

  const totalSumsArray: any = [];
  const prepareData = (lists: any, titles: any) => {
    if (lists.length > 0) {
      const totalObj = { ...lists?.[0] };
      const objTotal: any = {};
      for (const val of lists) {
        // Loop through the lists to calculate column sums
        let totalSum = 0;

        for (const key in val) {
          // Check if the key is a numeric property (excluding InvestorID and InvestorName)

          if (key in objTotal) {
            objTotal[key] += val[key];
          } else {
            objTotal[key] = val[key];
          }

          totalSum += key === "4" ? val[key] : val[key];
        }
        const newData = Object.fromEntries(
          Object.entries(objTotal).filter(
            ([key]) =>
              key !== "InvestorName" &&
              key !== "InvestorID" &&
              key !== "LPPartnerID" &&
              key !== "Tax1099Amount"
          )
        );
        totalSumsArray.push(totalSum);
        setColumnSums(newData);
      }

      inputData.push(totalObj);

      const formattedValues = lists
        ? lists.map((item: any) => {
            const formattedItem: any = {};
            for (const key in item) {
              formattedItem[key] =
                key === "InvestorName"
                  ? item[key]
                  : currencyFormat(Number(item[key]));
            }
            return formattedItem;
          })
        : [];

      return formattedValues || [];
    }
  };
  const formatCurrency = (rowData: any) => {
    // Convert rowData to a string if it's not already
    const rowDataString = String(rowData);

    const finalValue = rowDataString.includes("$")
      ? Number.parseFloat(rowDataString.replace("$", ""))
      : Number(rowDataString);

    const parseValue = finalValue
      ? currencyFormat(Number(finalValue))
      : currencyFormat(Number(finalValue));

    return `${parseValue}`;
  };

  // console.log("columnSums---", columnSums);

  // Format the values to add '$' symbol to all cells except headers

  const createcolumns = (li: any, ti: any) => {
    if (li && ti && li?.length > 0 && ti?.length > 0) {
      // const arrayData: any = [];
      // const itemss = ti.split(",");

      // const columnStyles: any = {
      //   InvestorName: { minWidth: "230px" },
      //   TotalAmount: { minWidth: "150px" },
      //   LendingHome: { minWidth: "180px" },

      //   LBXInvestments: { minWidth: "180px" },
      //   QCCapitalGroup: { minWidth: "150px" },
      //   RREAFHoldingsLLC: { minWidth: "160px" },
      //   LoneStarCapitalCalrovCREPartners: { minWidth: "260px" },
      //   InvestonMainStreet: { minWidth: "160px" },
      //   FreestyleCapitalGroup: { minWidth: "180px" },
      //   HourglassDWGCapitalPartners: { minWidth: "250px" },
      //   WestmountRealityCapital: { minWidth: "230px" },
      //   HappyCamperCapital: { minWidth: "230px" },
      //   GreenlightEquityGroup: { minWidth: "230px" },
      //   ChiragPooledFundofFunds: { minWidth: "230px" },
      // };

      const allData = [
        {
          field: "InvestorName",
          header: "Investor Account",
          style: { minWidth: "200px", width: "270px" },
          body: (rowData: any) => (
            <span className="left-align" title={rowData.InvestorName}>
              {rowData.InvestorName}
            </span>
          ),
        },
        {
          field: "TotalAmount",
          header: "Total Amount",
          body: (rowData: any) => rowData.TotalAmount,
          style: { minWidth: "200px", width: "270px" },
        },
      ];

      const allData1: any = ti?.map((key: any, index: any) => {
        return {
          field: key.SupplierID,
          header: transformedHeader(key.SupplierName),
          style: { minWidth: "200px", width: "270px" },
        };
      });

      allData.push(...allData1);
      console.log("allData", allData1);
      return allData;
    } else {
      return [];
    }
  };

  const keys = Object.keys(columnSums);

  // Reorder keys, moving "TotalAmount" to the first place
  const reorderedKeys = [
    "TotalAmount",
    ...keys.filter((key) => key !== "TotalAmount"),
  ];
  const columns = reorderedKeys.map((key, index) => (
    // <Column key={index} footer={"$" + columnSums[key]?.toFixed(2)} />
    <Column
      key={index}
      footer={
        columnSums[key] === undefined
          ? columnSums[key]
          : currencyFormat(Number(columnSums[key]))
      }
      colSpan={1}
    />
  ));

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total Amount  "
          // colSpan={1}
          // footerStyle={{ textAlign: "right" }}
        />
        {columns}
      </Row>
    </ColumnGroup>
  );

  // const newData: any[] = []; // Declare an array to hold the new data
  useEffect(() => {
    // prepareData(list, title);
    if (title && list && title!.length > 0 && list!.length > 0) {
      const header = createcolumns(title, list);
      setDataCreateElemnts(header);
      setExportExcelHeader(
        convertExcelHeaders(header, "stringCurrency", ["InvestorName"])
      );
      setdataTableElements(prepareData(title, list));
    }
  }, [list, title]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      Date: currentYear.toString(),
      Partner: "",
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter year";
      }

      return errors;
    },
    onSubmit: async () => {
      setloading(true);

      const reqParam = {
        legalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      dispatch(GetInvestorIncomeListThunk(reqParam))
        .then(() => {
          setName(false);
          setloading(false);
        })
        .catch(() => {
          setName(false);
          setloading(false);
        })
        .finally(() => {
          setName(false);
          setloading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    setName(true);
  }, [formik.values.Date]);

  useEffect(() => {
    if (fundDropdown.fundid) {
      setName(true);
      formik.resetForm();
    }
  }, [fundDropdown.fundid]);

  // useEffect(() => {
  //   document.body.classList.add("no-scroll");
  //   document.body.style.overflowX = "unset";
  //   document.documentElement.style.overflowX = "unset";
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //     document.body.style.overflowX = "hidden";
  //     document.documentElement.style.overflowX = "hidden";
  //   };
  // }, []);

  useEffect(() => {
    if (fundDropdown.fundid) {
      setloading(true);
      const reqParam = {
        legalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      dispatch(GetInvestorIncomeListThunk(reqParam))
        .then(() => {
          setName(false);
          setloading(false);
        })
        .catch(() => {
          setName(false);
          setloading(false);
        })
        .finally(() => {
          setName(false);
          setloading(false);
        });
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    if (formik.values.Date.toString() === currentYear.toString()) {
      setloading(true);
      const reqParam = {
        legalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      dispatch(GetInvestorIncomeListThunk(reqParam))
        .then(() => {
          setName(false);
          setloading(false);
        })
        .catch(() => {
          setName(false);
          setloading(false);
        })
        .finally(() => {
          setName(false);
          setloading(false);
        });
    } else {
      setName(true);
    }
  }, [formik.values.Date]);
  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}
        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">
              1099: Interest income by investor
            </h1>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() => {
                console.log("dataTableElements", dataTableElements);
                exportExcel(
                  exportExcelHeader,
                  dataTableElements,
                  "1099: Interest income by investor",
                  true
                );
              }}
              data-pr-tooltip="exportExcel"
              disabled={
                dataTableElements === undefined ||
                dataTableElements?.length === 0 ||
                dataTableElements === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  list === undefined || list?.length === 0 || list === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card flex">
        <div className="formgrid grid">
          <div className="d-block sm:d-flex sm:flex-column mr-4 md:col-6 col-12 mt-2 ml-2 p-1">
            <div className=" d-flex justify-content-center">
              <Calendar
                name="Date"
                value={
                  formik.values.Date === null || formik.values.Date === ""
                    ? undefined
                    : new Date(+formik.values.Date, 0, 1)
                }
                onChange={(e) => {
                  const selectedYear = new Date(Number(e.value)).getFullYear();
                  formik.setFieldValue("Date", selectedYear);
                }}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("Date"),
                  "card-inputBox": true,
                })}
                placeholder="Select year"
                view="year"
                dateFormat="yy"
              />
              {getFormErrorMessage("Date")}
            </div>
          </div>

          <div className="col-12 mt-2 md:col-1 p-1 m-0 mb-md-0">
            <div className="d-flex align-items-center">
              <Button
                className="btn-nav w-7rem justify-content-center"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  // setIsFormSubmitted(true);
                  // console.log("clicked");
                  formik.handleSubmit();
                }}
              >
                Show
              </Button>
            </div>
          </div>

          <div className="col-12 mt-2 md:col-1 p-1 m-0 mb-md-0 ">
            <div className="d-flex align-items-center">
              <Button
                className=" btn-nav w-7rem justify-content-center"
                onClick={(e) => {
                  e.preventDefault();
                  // setIsFormSubmitted(true);
                  // console.log("clicked");
                  formik.resetForm();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
        <div className="">
          {title && title?.length > 0 && !stateName && (
            <>
              {supplierIntList !== null ? (
                <div className="col-12 md:col-6">
                  <div className="d-flex align-items-center">
                    {" "}
                    <span>
                      {`  Total Amount for GP includes supplier cash interest of     `}
                    </span>
                    &nbsp;
                    <span className="font-bold">
                      {supplierIntList !== undefined
                        ? ` ${formatCurrency(supplierIntList)} `
                        : "$0"}
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <DataTableComponent
                valueData={dataTableElements}
                fieldsElements={dataCreateElemnts}
                footerElement={footerGroup}
                // isDownload={true}
                isPaginationAllowed={true}
                fileName="FundIncome"
                scrollable={true}
                className="gridcell"
                columnResizeMode="expand"
                maxHeight={"calc(-265px + 100vh)"}
                isFullView={true}
                setOpenDailog={setOpenDailog}
                openDailog={openDailog}
              />
            </>
          )}
          {title && title?.length === 0 && !stateName && (
            <>
              <span>
                {`  Total Amount for GP includes supplier cash interest of  `}
              </span>{" "}
              {supplierIntList ? (
                <span className="font-bold">{` ${supplierIntList} `}</span>
              ) : (
                <span className="font-bold">{` 0.00 `}</span>
              )}
              <DataTableComponent
                valueData={[]}
                fieldsElements={dataCreateElemnts}
                fileName="FundIncome"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
