import { useFormik } from "formik";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { TieredMenu } from "primereact/tieredmenu";
import { Toast } from "primereact/toast";
import { Tree } from "primereact/tree";
// import TreeNode from "primereact/treenode";
import { classNames } from "primereact/utils";
import React, { useEffect, useState, useRef } from "react";
import {
  RiDeleteBinFill,
  RiEdit2Fill,
  RiFile4Line,
  RiFileCopy2Fill,
  RiFileCopy2Line,
  RiFileUploadFill,
  RiFolder4Fill,
  RiFolderAddFill,
  RiGitRepositoryPrivateLine,
  RiLinkM,
  RiShareForward2Fill,
  RiShareForwardBoxFill,
  RiShareLine,
  // RiShareForwardBoxFill,
} from "react-icons/ri";
import { NavLink } from "react-router-dom";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import Loader from "../../../../components/loader/Loader";
import { IAddFolderData } from "../../../../store/features/filemanager/createfolder";
import { createFolderFilemanagerThunk } from "../../../../store/features/filemanager/createfolder/slice";
import { deleteFolderFileThunk } from "../../../../store/features/filemanager/deletefile/slice";
import { uploadFileThunk } from "../../../../store/features/filemanager/fileupload/slice";
import {
  GetFolderTreeFMThunk,
  GetFoldersFilesThunk,
  SaveFolderTransferThunk,
} from "../../../../store/features/filemanager/getfolderdetailsbyparentfolderid/slice";
import { getPrimaryFolderFileManagerThunk } from "../../../../store/features/filemanager/getprivatedocuments/slice";
import { editFolderFileByIdFileMangerThunk } from "../../../../store/features/filemanager/renamefolder/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getSasKeyThunk } from "../../../../store/sas-key/slice";
import dateFormatter from "../../../../utils/date-formatter";
import { isValidUrlRegexNew } from "../../../../utils/investing-approval/multi-investing-account/validation";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

interface MasterDirectory {
  typeId: number;
  categoryName: string;
  entityBaseFolderId: number;
  isShared: boolean;
}

export default function FileManagerNew() {
  const dispatch = useAppDispatch();
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const iconColor = useAppSelector((state) => state.color.Color);
  const toast = useRef<Toast>(null);
  const breadCrumbRef = useRef<any>(null);
  const [items, setItems] = useState<MenuItem[]>([]);
  const [tempItems, setTempItems] = useState<MenuItem[]>([]);
  const [dropdownItems, setDropdownItems] = useState<MenuItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [isReadOnlyAccess, setIsReadOnlyAccess] = useState<Boolean>();
  const [activeTypeId, setActiveTypeId] = useState<{
    typeId: number;
    isShared: boolean;
  }>({ typeId: 0, isShared: false });
  const [masterDirectory, setMasterDirectory] = useState<any>();
  // eslint-disable-next-line no-unused-vars
  const [activeFolderName, setActiveFolderName] = useState<string>("");
  const [activeFolderId, setActiveFolderId] = useState<number>(0);
  const [activeFoldersFiles, setActiveFoldersFiles] = useState<any>([]);
  const [legalEntityId, setLegalEntityId] = useState<number>();
  const [removeFileFolderState, setRemoveFileFolder] = useState({
    id: undefined,
    isFolder: false,
    isLink: false,
  });

  const addSpaceBeforeCapital = (name: string) => {
    return name.replace(/([a-z])([A-Z])/g, "$1 $2");
  };
  const fetchFolderAndFiles = async () => {
    try {
      setLoading(true);
      const payload = {
        typeId: activeTypeId?.typeId,
        IsShared: activeTypeId.isShared,
        folderId: activeFolderId,
        legalEntityId,
      };

      const folderAndFilesRes = await dispatch(GetFoldersFilesThunk(payload));
      setActiveFoldersFiles(
        folderAndFilesRes?.payload?.response?.data?.foldersFiles
      );
      setIsReadOnlyAccess(
        folderAndFilesRes?.payload?.response?.data?.isReadOnlyAccess
      );
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const iconItemTemplate = (item: any) => {
    return (
      <a
        onClick={() => item.command()}
        className="cursor-pointer p-menuitem-link"
      >
        <span
          className={`${item.icon} no-underline p-menuitem-text`}
          style={{ fontSize: "15px" }}
        />
        <span className="pl-1 p-menuitem-text">
          {addSpaceBeforeCapital(item?.label)}
        </span>
      </a>
    );
  };
  const handleClickFolder = async (
    typeId: number,
    folderId: number,
    folderName: string,
    legalEntityID: number,
    isShared: boolean,
    isRoot: boolean = false
  ) => {
    try {
      setLoading(true);
      setActiveFolderId(folderId);
      setActiveTypeId({ typeId, isShared });
      setActiveFolderName(folderName);
      setLegalEntityId(legalEntityID);
      const payload = {
        typeId,
        IsShared: isShared,
        legalEntityId: legalEntityID,
        folderId,
      };

      const folderAndFilesRes = await dispatch(GetFoldersFilesThunk(payload));
      setActiveFoldersFiles(
        folderAndFilesRes?.payload?.response?.data?.foldersFiles
      );
      setIsReadOnlyAccess(
        folderAndFilesRes?.payload?.response?.data?.isReadOnlyAccess
      );

      // Set BreadCrumb

      const newItem: MenuItem = {
        label: folderName,
        command: () => {
          handleClickFolder(
            typeId,
            folderId,
            folderName,
            legalEntityID,
            isShared,
            !folderId
          );
        },
      };
      if (isRoot) {
        setItems([]);
        setItems([
          { ...newItem, icon: "pi pi-home", template: iconItemTemplate },
        ]);
      } else {
        setItems([...items, newItem]);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  // Modal State
  const [transferDisabled, setTransferDisabled] = useState(false);
  const [visibleCreateEditFolder, setVisibleCreateEditFolder] = useState(false);
  const [visibleFileUpload, setVisibleFileUpload] = useState({
    isOpen: false,
    isLink: false,
  });
  const [visibleFolderFileRename, setVisibleFolderFileRename] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleShare, setVisibleShare] = useState<{
    visible: boolean;
    visibleRename: boolean;
    transferType: number;
    data?: {
      typeID: number;
      legalEntityID: number;
      folderID: number;
      name: string;
      fileID: number;
      documentLink: string;
      description: string;
      isLink: boolean;
      isShared: boolean;
    };
  }>({
    visible: false,
    visibleRename: false,
    transferType: 0,
    data: undefined,
  });
  const fileRef = useRef<any>(null);

  const newBtnRef = useRef<any>(null);

  const [DocName, setDocName] = useState("");
  const [docLink, setDocLink] = useState("");

  const formik = useFormik({
    initialValues: {
      folderName: "",
      Description: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (values.folderName.trim()?.length === 0) {
        errors.folderName = "Folder name is required";
      } else if (values.folderName?.length > 200) {
        errors.folderName =
          "Folder Name exceeds maximum character limit of 200";
      } else if (/^\s+|\s+$/g.test(values.folderName)) {
        errors.folderName = "Folder Name start/end spaces not allowed";
      } else if (!values?.Description?.trim()) {
        errors.Description = "Description is required";
      } else if (values?.Description?.length > 200) {
        errors.Description =
          "Description exceeds maximum character limit of 200";
      }

      return errors;
    },
    onSubmit: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      createFolder();
    },
  });
  const handleChange = (name: string, value: string) => {
    formik.setFieldValue(name, value);
  };

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);
  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const renameFormik = useFormik({
    initialValues: {
      newFolderName: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (values.newFolderName.trim()?.length === 0) {
        errors.newFolderName = "Folder name is required";
      } else if (values.newFolderName?.length > 200) {
        errors.newFolderName =
          "Folder Name exceeds maximum character limit of 200";
      } else if (/^\s+|\s+$/g.test(values.newFolderName)) {
        errors.newFolderName = "Folder Name start/end spaces not allowed";
      }

      return errors;
    },
    onSubmit: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      saveFolderTransfer();
    },
  });

  const renameFormikTouched: any = renameFormik.touched;
  const renameFormikErrors: any = renameFormik.errors;
  const isRenameFormFieldInvalid = (name: any) =>
    !!(renameFormikTouched[name] && renameFormikErrors[name]);
  const getIsRenameFormErrorMessage = (name: any) => {
    return isRenameFormFieldInvalid(name) ? (
      <small className="p-error">{renameFormikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const createFolder = () => {
    setLoading(true);
    const payload: IAddFolderData = {
      TypeId: activeTypeId?.typeId,
      IsShared: activeTypeId?.isShared,
      LegalEntityId: legalEntityId || fundDropdown.fundid,
      NewFolderName: formik.values.folderName,
      ParentFolderID: activeFolderId,
      Description: formik.values.Description,
    };
    dispatch(createFolderFilemanagerThunk(payload))
      .then((response: any) => {
        if (!response?.payload?.response?.data?.isFailed) {
          setLoading(false);
          successToastMessage(toast, "Folder created successfully!!");
          formik.resetForm();
          setVisibleCreateEditFolder(false);
          fetchFolderAndFiles();
        } else {
          setLoading(false);
          errorToastMessage(toast, response?.payload?.response?.message);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        errorToastMessage(toast, "Opps something went wrong.");
        console.error("Error from getPrimaryFolderFileManagerThunk:", error);
      });
  };

  const formikRenameFolderFile = useFormik({
    initialValues: {
      FolderID: undefined,
      NewFolderName: "",
      FileId: undefined,
      NewFileName: "",
      isLink: false,
      Description: "",
      extension: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (values.FolderID && !values.NewFolderName.trim()) {
        errors.NewFolderName = "Folder name is required";
      } else if (values.FolderID && values.NewFolderName?.length > 200) {
        errors.NewFolderName =
          "Folder Name exceeds maximum character limit of 200";
      } else if (values.FolderID && /^\s+|\s+$/g.test(values.NewFolderName)) {
        errors.NewFolderName = "Folder Name start/end spaces not allowed";
      } else if (values.FolderID && !values?.Description?.trim()) {
        errors.Description = "Description is required";
      } else if (values.FolderID && values?.Description?.length > 200) {
        errors.Description =
          "Description exceeds maximum character limit of 200";
      }

      if (values.FileId && !values?.NewFileName.trim()) {
        errors.NewFileName = `${
          values.isLink ? "Link" : "File name"
        } is required`;
      } else if (
        !values.isLink &&
        values.FileId &&
        values.NewFileName?.length > 200
      ) {
        errors.NewFileName = `${
          values.isLink ? "Link" : "File name"
        } exceeds maximum character limit of 200`;
      } else if (
        values.isLink &&
        values.FileId &&
        !isValidUrlRegexNew(values.NewFileName)
      ) {
        errors.NewFileName = "Invalid link";
      } else if (values.FileId && /^\s+|\s+$/g.test(values.NewFileName)) {
        errors.NewFileName = `${
          values.isLink ? "Link" : "File name"
        } start/end spaces not allowed`;
      } else if (values.FileId && !values?.Description?.trim()) {
        errors.Description = "Description is required";
      } else if (values.FileId && values?.Description?.length > 200) {
        errors.Description =
          "Description exceeds maximum character limit of 200";
      }

      return errors;
    },
    onSubmit: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      renameFolderFile();
    },
  });

  const formikRenameTouched: any = formikRenameFolderFile.touched;
  const formikRenameErrors: any = formikRenameFolderFile.errors;
  const isFormRenameFieldInvalid = (name: any) =>
    !!(formikRenameTouched[name] && formikRenameErrors[name]);
  const getRenameFormErrorMessage = (name: any) => {
    return isFormRenameFieldInvalid(name) ? (
      <small className="p-error">{formikRenameErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleChangeRename = (name: string, value: string) => {
    formikRenameFolderFile.setFieldValue(name, value);
  };

  const renameFolderFile = () => {
    setLoading(true);
    const {
      FolderID,
      FileId,
      NewFolderName,
      NewFileName,
      Description,
      isLink,
      extension,
    } = formikRenameFolderFile.values;
    const payload = {
      FolderID,
      NewFolderName,
      FileId,
      NewFileName: NewFileName + extension,
      Description,
      isLink,
    };
    dispatch(editFolderFileByIdFileMangerThunk(payload))
      .then((response: any) => {
        if (!response?.payload?.response?.data?.isFailed) {
          setLoading(false);
          successToastMessage(
            toast,
            `${
              formikRenameFolderFile.values.FolderID
                ? "Folder"
                : formikRenameFolderFile.values.isLink
                ? "Link"
                : "File"
            } updated successfully!!`
          );
          setVisibleFolderFileRename(false);
          fetchFolderAndFiles();
          formikRenameFolderFile.resetForm();
        } else {
          setLoading(false);
          errorToastMessage(toast, response?.payload?.response?.message);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        errorToastMessage(toast, "Opps something went wrong.");
        console.error("Error from getPrimaryFolderFileManagerThunk:", error);
      });
  };

  const onDeleteFolderFile = async () => {
    try {
      setLoading(true);
      await dispatch(
        deleteFolderFileThunk({
          FolderId: removeFileFolderState?.isFolder
            ? removeFileFolderState.id
            : 0,
          FileId: removeFileFolderState?.isFolder
            ? 0
            : removeFileFolderState.id,
          legalEntityId,
          typeId: activeTypeId?.typeId,
          IsLink: removeFileFolderState?.isLink,
        })
      ).then((response: any) => {
        if (response?.payload?.response?.success === true) {
          setVisibleDelete(false);
          fetchFolderAndFiles();
          successToastMessage(
            toast,
            `${
              removeFileFolderState?.isFolder
                ? "Folder"
                : removeFileFolderState?.isLink
                ? "Link"
                : "File"
            } Deleted successfully.`
          );
          setRemoveFileFolder({
            id: undefined,
            isFolder: false,
            isLink: false,
          });
        } else {
          setVisibleDelete(false);
          setRemoveFileFolder({
            id: undefined,
            isFolder: false,
            isLink: false,
          });
          errorToastMessage(toast, "Something went wrong!");
        }
      });
    } catch (error) {
      errorToastMessage(toast, "Something went wrong!");
      console.error("Error:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (fundDropdown.fundid > 0) {
      setLoading(true);
      setItems([]);
      setLegalEntityId(fundDropdown.fundid);
      dispatch(getPrimaryFolderFileManagerThunk())
        .then((response: any) => {
          setMasterDirectory(
            response?.payload?.response?.data.sort(function (a: any, b: any) {
              return a.typeId - b.typeId;
            })
          );
          return response?.payload?.response?.data;
        })
        .then((res: any) => {
          const privateFolder = res.find(
            (item: any) => item?.typeId === 1 && !item.isShared
          );
          privateFolder &&
            handleClickFolder(
              privateFolder.typeId,
              privateFolder.entityBaseFolderId,
              privateFolder.categoryName,
              fundDropdown.fundid,
              privateFolder.isShared,
              true
            );
        })
        .catch((error: any) => {
          console.error("Error from getPrimaryFolderFileManagerThunk:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [fundDropdown.fundid]);

  const formatDate = (date: any) => {
    const dateFormat = dateFormatter(date);
    return dateFormat;
  };
  const generateSASKey = async (legalEntityID: number) => {
    return dispatch(getSasKeyThunk(legalEntityID))
      .then((response: any) => {
        if (response.meta.requestStatus === "fulfilled") {
          return response.payload.data.sasKey;
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [nodes, setTreeNodes] = useState<any[]>([]);
  const [selectedNode, setSelectedNode] = useState<any>([]);
  const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>({
    "0": true,
  });
  const [selectedNodeKey, setSelectedNodeKey] = useState<any>();
  const [filteredNodes, setFilteredNodes] = useState<any[]>(nodes);
  const [filterValue, setFilterValue] = useState<string>("");

  useEffect(() => {
    setFilteredNodes(nodes);
  }, [nodes]);
  // function removeDisabledChildren(folders: any) {
  //   return folders.map((folder: any) => {
  //     if (folder.isDisabled && folder.parentFolderId !== 0) {
  //       return { ...folder, children: [] };
  //     }
  //     return {
  //       ...folder,
  //       children: removeDisabledChildren(folder.children),
  //     };
  //   });
  // }

  const handleFolderAction = async (transferType: number, data: any) => {
    try {
      setLoading(true);
      const payload = {
        typeId: data?.typeID,
        isShared: data.isShared,
        legalEntityId: data.legalEntityID,
        transferType,
        excludeFolderId: data.folderID,
        IsFile: !!(data?.isLink || (data?.fileID ?? 0) > 0),
      };
      const folderTreeRes = await dispatch(GetFolderTreeFMThunk(payload));
      const treeNodes = folderTreeRes?.payload?.response?.data;

      setTreeNodes(treeNodes);

      setVisibleShare({
        transferType,
        visible: true,
        visibleRename: false,
        data,
      });
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const saveFolderTransfer = async () => {
    try {
      setLoading(true);
      const payload = {
        NewFolderId: selectedNode?.node.folderId,
        LegalEntityId: visibleShare?.data?.legalEntityID,
        TypeId: visibleShare?.data?.typeID,
        TransferType: visibleShare?.transferType,
        IsShared: visibleShare?.data?.isShared,
        FolderId: visibleShare?.data?.folderID,
        FileId: visibleShare?.data?.fileID,
        Rename: renameFormik?.values.newFolderName || "||",
      };
      const saveFolderResponse = await dispatch(
        SaveFolderTransferThunk(payload)
      );
      if (!saveFolderResponse?.payload?.response?.data.isFailed) {
        fetchFolderAndFiles();
        setVisibleShare({
          ...visibleShare,
          visible: false,
          visibleRename: false,
        });
        renameFormik.resetForm();
        successToastMessage(
          toast,
          saveFolderResponse?.payload?.response?.message
        );
        setTreeNodes([]);
        setSelectedNodeKey("");
        setSelectedNode([]);
      } else {
        renameFormik.resetForm();
        errorToastMessage(
          toast,
          saveFolderResponse?.payload?.response?.message
        );
        setVisibleShare({
          ...visibleShare,
          visibleRename: true,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const ShareTransferType = 1;
  const MoveTransferType = 2;
  const CopyTransferType = 3;

  const generateDropDownMenu = (data: any) => {
    const {
      documentLink,
      folderID,
      fileID,
      name,
      description,
      typeID,
      isLink,
      isShared,
    } = data;

    return [
      {
        label: "Edit",
        icon: <RiEdit2Fill color={"#f7a000"} className="mr-1" />,
        command: () => {
          const fileExtension = name.slice(
            Math.max(0, name.lastIndexOf(".") + 1)
          );
          const fileNameWithoutExtension = name.slice(
            0,
            Math.max(0, name.lastIndexOf("."))
          );
          formikRenameFolderFile.setValues({
            FolderID: documentLink ? 0 : folderID,
            NewFolderName: documentLink ? "" : name,
            FileId: documentLink ? fileID : 0,
            NewFileName: documentLink
              ? !isLink && fileNameWithoutExtension
                ? fileNameWithoutExtension
                : name
              : "",
            isLink,
            Description: description,
            extension:
              documentLink && !isLink && fileNameWithoutExtension
                ? "." + fileExtension
                : "",
          });
          setVisibleFolderFileRename(true);
        },
      },
      ...(typeID === 1 && !isShared
        ? [
            {
              label: "Share",
              icon: (
                <RiShareForwardBoxFill color={iconColor} className="mr-1" />
              ),
              command: () => {
                handleFolderAction(ShareTransferType, data);
              },
            },
          ]
        : typeID === 1 && isShared
        ? [
            {
              label: "Make it private",
              icon: (
                <RiGitRepositoryPrivateLine
                  color={iconColor}
                  className="mr-1"
                />
              ),
              command: () => {
                handleFolderAction(ShareTransferType, data);
              },
            },
          ]
        : []),
      {
        label: "Copy",
        icon: <RiFileCopy2Line color={iconColor} className="mr-1" />,
        command: () => {
          handleFolderAction(CopyTransferType, data);
        },
      },
      {
        label: "Move",
        icon: <RiFileCopy2Fill color={iconColor} className="mr-1" />,
        command: () => {
          handleFolderAction(MoveTransferType, data);
        },
      },
      {
        label: "Delete",
        icon: <RiDeleteBinFill color={"#ff3d32"} className="mr-1" />,
        command: () => {
          setVisibleDelete(true);
          setRemoveFileFolder({
            id: documentLink ? fileID : folderID,
            isFolder: !documentLink,
            isLink: data.isLink,
          });
        },
      },
    ];
  };
  const actionTemplate = (rowData: any) => {
    const rowActionRef = useRef<any>(null);
    return (
      <>
        <TieredMenu
          model={generateDropDownMenu(rowData)}
          popup
          ref={rowActionRef}
        />
        <Button
          onClick={(e) => rowActionRef?.current.toggle(e)}
          icon="pi pi-ellipsis-v"
          className="p-button p-button-rounded p-button-text iconButton py-0"
        />
      </>
    );
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const getFileHeaderIcon = (rowData: any) => {
    return (
      <React.Fragment>
        {rowData?.isShared ? (
          <RiShareForward2Fill title="Shared" color={iconColor} />
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  };
  const getNormalHeaderIcon = (rowData: any) => {
    return (
      <React.Fragment>
        {rowData?.documentLink !== null ? (
          rowData?.isLink ? (
            <RiLinkM title="Link" />
          ) : (
            <RiFile4Line title="File" />
          )
        ) : (
          <RiFolder4Fill title="Folder" color="#FFBF00" />
        )}
      </React.Fragment>
    );
  };

  const customSort = (event: any) => {
    const { field, order, data } = event;

    const sortedData = [...data].sort((a, b) => {
      // Folders first
      if (a.fileID === 0 && b.fileID !== 0) {
        return -1;
      } else if (a.fileID !== 0 && b.fileID === 0) {
        return 1;
      }

      // Both are either folders or files, sort by field case-insensitively
      const aValue = a[field] ? a[field].toString().toLowerCase() : "";
      const bValue = b[field] ? b[field].toString().toLowerCase() : "";

      if (aValue > bValue) {
        return order;
      } else if (aValue < bValue) {
        return -order;
      }

      return 0;
    });

    return sortedData;
  };

  const formikfile = useFormik({
    initialValues: {
      // eslint-disable-next-line unicorn/no-null
      files: null,
      FolderID: 0,
      TypeId: 0,
      LegalEntityId: 0,
      Description: "",
      DocumentLink: "",
    },
    validate: (values) => {
      const errors: any = {};

      if (!visibleFileUpload.isLink && values.files === "") {
        errors.files = "Please select file .";
      }

      if (visibleFileUpload.isLink && !values?.DocumentLink?.trim()) {
        errors.DocumentLink = "Link is required";
      } else if (
        visibleFileUpload.isLink &&
        !isValidUrlRegexNew(values?.DocumentLink)
      ) {
        errors.DocumentLink = "Invalid link";
      }

      if (!values?.Description?.trim()) {
        errors.Description = "Description is required";
      } else if (values?.Description?.length > 200) {
        errors.Description =
          "Description exceeds maximum character limit of 200";
      }
      return errors;
    },
    onSubmit: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      uploadDoc();
    },
  });

  const formikFileTouched: any = formikfile.touched;
  const formikFileErrors: any = formikfile.errors;
  const isFileFormFieldInvalid = (name: any) =>
    !!(formikFileTouched[name] && formikFileErrors[name]);
  const getFileFormErrorMessage = (name: any) => {
    return isFileFormFieldInvalid(name) ? (
      <small className="p-error">{formikFileErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const uploadFile = async (e: any) => {
    const file = e.files[0];
    const lastDotIndex = file.name.lastIndexOf(".");
    const fileNameWithoutExtension = file.name.slice(
      0,
      Math.max(0, lastDotIndex)
    );
    const fileExtension = file.name.slice(Math.max(0, lastDotIndex + 1));

    file.name.replace(/[^\s\w.]/gi, "");
    const regex =
      /(.*?).(docx|doc|pdf|xml|bmp|ppt|xls|png|jpeg|xlsx|txt|pptx|rtf|jpg|.mp4|.m4v)$/;

    if (!regex.test(file.name?.toLowerCase())) {
      errorToastMessage(
        toast,
        "Upload failed. Please Use Images,Excel,Word,Power points, Plain Text Documents,Video format only  "
      );
      fileRef?.current.clear();
      return;
    }

    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 10) {
      errorToastMessage(
        toast,
        "Upload failed. Please ensure the file size does not exceed 10mb"
      );
      fileRef?.current.clear();
      return;
    }
    // Remove special characters and underscores from the file name
    // const cleanFileName = fileNameWithoutExtension.replace(
    //   /[\s_]|[^\d.A-Za-z]/g,
    //   ""
    // );

    // Construct the modified filename with the original file extension
    const finalFileName = `${fileNameWithoutExtension}.${fileExtension}`;

    // Check if filename exceeds 200 characters
    if (fileNameWithoutExtension?.length > 200) {
      errorToastMessage(
        toast,
        "Upload failed. Filename exceeds maximum character limit of 200."
      );
      fileRef?.current.clear();
      return;
    }

    // Create a new File object with the modified filename
    const modifiedFile = new File([file], finalFileName, { type: file.type });
    setDocName(finalFileName);
    formikfile.setFieldValue("files", modifiedFile);
    setDocLink(URL.createObjectURL(modifiedFile));
    fileRef?.current.clear();
  };

  const uploadDoc = async () => {
    setLoading(true);
    if (
      (formikfile.values.files === null || formikfile.values.files === "") &&
      docLink === "" &&
      !visibleFileUpload.isLink
    ) {
      errorToastMessage(toast, "Please Select File");
      setLoading(false);
      return;
    }

    if (
      formikfile.values.files !== null ||
      formikfile.values.files !== "" ||
      formikfile.values.DocumentLink
    ) {
      const formdata = new FormData();
      formdata.append(
        "Files",
        formikfile.values.files === null ? "" : formikfile.values.files
      );
      formdata.append("FolderID", `${activeFolderId ?? 0}`);
      formdata.append("TypeId", `${activeTypeId?.typeId ?? 0}`);
      formdata.append("LegalEntityId", `${legalEntityId ?? 0}`);
      formdata.append(
        "DocumentLink",
        `${
          formikfile.values.DocumentLink.length > 0
            ? formikfile.values.DocumentLink
            : "||"
        }`
      );
      formdata.append("Description", `${formikfile.values.Description ?? ""}`);
      formdata.append("IsShared", `${activeTypeId?.isShared ?? false}`);
      formdata.append("IsLink", `${visibleFileUpload.isLink}`);
      setLoading(true);

      try {
        await dispatch(uploadFileThunk(formdata)).then((response: any) => {
          if (!response?.payload?.response?.data?.isFailed) {
            setVisibleFileUpload({ ...visibleFileUpload, isOpen: false });
            successToastMessage(toast, response?.payload?.response?.message);
            setDocLink("");
            setDocName("");
            fetchFolderAndFiles();
            formikfile.resetForm();
          } else {
            errorToastMessage(toast, response?.payload?.response?.message);
          }
        });
        setLoading(false);
        fileRef?.current.clear();
      } catch (error) {
        setLoading(false);
        formikfile.resetForm();
        console.log("Opps something went wrong", error);
      }
    } else {
      setLoading(false);
    }
  };

  const itemsSub = [
    {
      label: "Create Folder",
      icon: <RiFolderAddFill color="#FFBF00" className="mr-1" />,
      command: () => setVisibleCreateEditFolder(true),
    },
    {
      label: "Upload File",
      icon: <RiFileUploadFill color={iconColor} className="mr-1" />,
      command: () => setVisibleFileUpload({ isOpen: true, isLink: false }),
    },
    {
      label: "Add Link",
      icon: <RiLinkM color="#068DDA" className="mr-1" />,
      command: () => setVisibleFileUpload({ isOpen: true, isLink: true }),
    },
  ];

  const openInNewTab = async (
    documentLink: string,
    isLink: boolean,
    legalEntityID: number
  ) => {
    let key = "";
    if (!isLink) {
      setLoading(true);
      key = await (activeTypeId?.typeId === 2
        ? generateSASKey(0)
        : generateSASKey(legalEntityID));
      setDocLink(documentLink + key);
      setLoading(false);
      window &&
        window.open(documentLink + key, "_blank", "noopener,noreferrer");
    }
  };
  const handleCellClick = (event: any) => {
    if (event.field === "name") {
      const {
        typeID,
        folderID,
        legalEntityID,
        name,
        fileID,
        documentLink,
        isShared,
        isLink,
      } = event.rowData;
      if (!fileID) {
        handleClickFolder(typeID, folderID, name, legalEntityID, isShared);
      } else {
        openInNewTab(documentLink, isLink, legalEntityID);
      }
    }
  };

  const dataTableElements = [
    {
      header: <RiFile4Line />,
      className: "center-header-title text-center",
      body: (rowData: any) => getNormalHeaderIcon(rowData),
      style: {
        paddingTop: !isReadOnlyAccess ? "0" : "10px",
        paddingBottom: !isReadOnlyAccess ? "0" : "10px",
        width: "16px",
      },
      headerStyle: {
        borderRightWidth: "1px",
      },
    },
    {
      header: <RiShareLine title="Shared" />,
      className: "center-header-title text-center",
      body: (rowData: any) => getFileHeaderIcon(rowData),
      style: {
        paddingTop: !isReadOnlyAccess ? "0" : "10px",
        paddingBottom: !isReadOnlyAccess ? "0" : "10px",
        width: "16px",
      },
      headerStyle: {
        borderRightWidth: "1px",
      },
    },
    {
      header: "Name",
      field: "name",
      sortable: true,
      sortFunction: customSort,
      body: (rowData: any) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "inherit",
            cursor: "pointer",
          }}
          title={rowData?.name}
        >
          {rowData?.isLink ? (
            <a
              href={
                rowData?.documentLink.startsWith("http")
                  ? rowData.documentLink
                  : `http://${rowData.documentLink}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {rowData?.name}
            </a>
          ) : !rowData?.isLink && rowData?.fileID ? (
            <a
              className="link"
              onClick={() => handleCellClick({ field: "name", rowData })}
            >
              {rowData?.name}
            </a>
          ) : (
            rowData?.name
          )}
        </div>
      ),
      style: {
        width:
          activeTypeId.typeId === 1 &&
          activeTypeId.isShared &&
          activeFolderId === 0
            ? "20rem"
            : "10rem",
        paddingTop: !isReadOnlyAccess ? "0" : "10px",
        paddingBottom: !isReadOnlyAccess ? "0" : "10px",
      },
      headerStyle: {
        borderRightWidth: "1px",
      },
    },
    {
      field: "description",
      header: "Description",
      body: (rowData: any) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "inherit",
          }}
          title={rowData?.description}
        >
          {rowData?.description}
        </div>
      ),
      style: {
        width:
          activeTypeId.typeId === 1 &&
          activeTypeId.isShared &&
          activeFolderId === 0
            ? "23rem"
            : "10rem",
        paddingTop: !isReadOnlyAccess ? "0" : "10px",
        paddingBottom: !isReadOnlyAccess ? "0" : "10px",
      },
      headerStyle: {
        borderRightWidth: "1px",
      },
    },
    ...(activeTypeId.typeId === 1 &&
    activeTypeId.isShared &&
    activeFolderId === 0
      ? []
      : [
          {
            field: "updatedBy",
            header: "Modified By",
            style: {
              paddingTop: !isReadOnlyAccess ? "0" : "10px",
              paddingBottom: !isReadOnlyAccess ? "0" : "10px",
            },
            headerStyle: {
              borderRightWidth: "1px",
            },
          },
          {
            field: "createdDate",
            header: "Modified",
            style: {
              paddingTop: !isReadOnlyAccess ? "0" : "10px",
              paddingBottom: !isReadOnlyAccess ? "0" : "10px",
            },
            headerStyle: {
              borderRightWidth: "1px",
            },
            body: (rowData: any) => formatDate(rowData.createdDate),
            filterElement: dateFilterTemplate,
          },
        ]),
    {
      field: "fileSize",
      header: "Size",
      headerStyle: {
        width: "5%",
        borderRightWidth: "1px",
        borderBottom: "2px",
      },
      style: {
        paddingTop: !isReadOnlyAccess ? "0" : "10px",
        paddingBottom: !isReadOnlyAccess ? "0" : "10px",
      },
      body: (rowData: any) => {
        if (rowData?.documentLink) {
          const fileSizeBytes = rowData?.fileSize;
          const fileSizeKB = (fileSizeBytes / 1024).toFixed(2);
          const fileSizeMB = (fileSizeBytes / (1024 * 1024)).toFixed(2);

          if (fileSizeBytes === 0) {
            return ``;
          } else if (fileSizeBytes < 1024) {
            return `${fileSizeBytes} bytes`;
          } else if (fileSizeBytes < 1024 * 1024) {
            return `${fileSizeKB} KB`;
          } else {
            return `${fileSizeMB} MB`;
          }
        } else {
          return `${rowData?.fileSize} Item(s)`;
        }
      },
    },
    ...(isReadOnlyAccess
      ? []
      : [
          {
            header: "",
            body: actionTemplate,
            headerStyle: {
              width: "5%",
            },
            style: {
              paddingTop: !isReadOnlyAccess ? "0" : "10px",
              paddingBottom: !isReadOnlyAccess ? "0" : "10px",
            },
          },
        ]),
  ];
  useEffect(() => {
    const updateItems = () => {
      if (items.length > 5) {
        const tempDropdownItems = items.slice(0, -5);
        const tempVisibleItems = items.slice(-5);

        setDropdownItems(tempDropdownItems);
        setTempItems(tempVisibleItems);
      } else {
        setDropdownItems([]);
        setTempItems(items);
      }
    };

    updateItems();
  }, [items]);

  const customHome =
    dropdownItems.length > 0
      ? {
          template: () => (
            <span
              className="p-breadcrumb-home p-menuitem-link"
              onClick={(event) => breadCrumbRef.current.toggle(event)}
            >
              <i className="pi pi-bars"></i>
              <Menu model={dropdownItems} popup ref={breadCrumbRef} />
            </span>
          ),
        }
      : {};

  const onSelect = (event: any) => {
    if (event.node.isDisabled) {
      setTransferDisabled(true);
      setSelectedNode([]);
    } else {
      setTransferDisabled(false);
      setSelectedNode(event);
    }
  };

  const filterNodes = (filterNode: any[], filter: string): any[] => {
    let expandKeys: { [key: string]: boolean } = {};

    const filtered = filterNode?.reduce<any[]>((acc, node) => {
      if (node.label.toLowerCase().includes(filter.toLowerCase())) {
        acc.push({ ...node });
      } else if (node.children) {
        const [filteredChildren, expandedChildrenKeys] = filterNodes(
          node.children,
          filter
        );
        if (filteredChildren.length > 0) {
          acc.push({ ...node, children: filteredChildren });
          expandKeys[node.key] = true;
          expandKeys = { ...expandKeys, ...expandedChildrenKeys };
        }
      }
      return acc;
    }, []);

    return [filtered, expandKeys];
  };
  const onFilterChange = (e: any) => {
    const value = e.target.value;
    setFilterValue(value);

    const [filtered, expanded] = filterNodes(nodes, value);
    if (value) {
      setSelectedNodeKey("");
      setFilteredNodes(filtered);
      setExpandedKeys({ "0": true, ...expanded });
    } else {
      setFilteredNodes(nodes);
      setExpandedKeys({ "0": true });
    }
  };

  const nodeTemplate = (node: any, options: any) => {
    return (
      <>
        <span className={options.className}>
          {node.label}
          {node.isDisabled && node.key === selectedNodeKey && (
            <p className="m-0">
              <small className="p-error">
                Source and destination folder can not be same
              </small>
            </p>
          )}
        </span>
      </>
    );
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <Toast ref={toast} />
      <div>
        <div className="flex-none flex-row flex-wrap p-0 sm:flex sm:mb-1 col-12 mb-0">
          {Array.isArray(masterDirectory) &&
            masterDirectory?.map((item: MasterDirectory) => (
              <div
                key={item.categoryName}
                className="d-flex flex-column px-1 mb-2 sm:mb-0"
              >
                <Button
                  className={`p-button p-component btn-nav mr-1 ${
                    activeTypeId.typeId === item.typeId &&
                    item.categoryName === items[0]?.label
                      ? "btn-navActive"
                      : ""
                  }
                  ${item?.typeId === 2 ? "px-4" : "px-7"}`}
                  key={item.typeId}
                  onClick={() =>
                    handleClickFolder(
                      item.typeId,
                      item.entityBaseFolderId,
                      item.categoryName,
                      fundDropdown.fundid,
                      item.isShared,
                      true
                    )
                  }
                >
                  <span className="ml-auto mr-auto text-sm font-medium">
                    {addSpaceBeforeCapital(item?.categoryName)}
                  </span>
                </Button>
              </div>
            ))}
        </div>
        <div className="px-1 text-sm mb-2">
          {activeTypeId?.typeId === 1 && !activeTypeId?.isShared
            ? `Management team workspace is where fund managers can create folders and documents to share within your team that accesses the manager portal.`
            : activeTypeId?.typeId === 1 && activeTypeId?.isShared
            ? "Shared workspace is where fund managers can create folders and documents to share with all other funds on Avestor platform.  This can be useful for sharing deal information."
            : activeTypeId?.typeId === 2
            ? "Resources made available by Avestor."
            : ""}
        </div>
      </div>

      <div className="col-12 px-1 py-0">
        <div className="d-flex flex-wrap justify-content-end align-items-center">
          {/* <h5 className="pageHeader m-0">Files</h5> */}
          <div>
            {!isReadOnlyAccess && (
              <>
                <TieredMenu model={itemsSub} popup ref={newBtnRef} />
                <Button
                  label="New"
                  icon={"pi pi-plus"}
                  className="btn btn-navActive px-5"
                  onClick={(e) => newBtnRef?.current.toggle(e)}
                />
              </>
            )}
          </div>
        </div>
        <div>
          <div className="my-2">
            <BreadCrumb
              style={{
                background: "transparent",
                border: "none",
                padding: "5px 0",
                textTransform: "capitalize",
              }}
              className="custom-breadcrumb"
              model={tempItems}
              {...(customHome.template && { home: customHome })}
            />
          </div>
        </div>
        <div className="my-2 tabWithoutboxshadow">
          <div className="card">
            <DataTableComponent
              valueData={activeFoldersFiles}
              fieldsElements={dataTableElements}
              isPaginationAllowed={true}
              size="small"
              cellSelection={true}
              onCellClick={handleCellClick}
              resizableColumns={true}
              columnResizeMode="expand"
              className="allocateSliceTable editAllocateDialog"
              noGridLines={true}
            />
          </div>
        </div>
      </div>

      <Dialog
        header="Create Folder"
        visible={visibleCreateEditFolder}
        onHide={() => {
          setVisibleCreateEditFolder(false);
          formik.resetForm();
        }}
        headerClassName="border-bottom text-center flex justify-content-center align-item-center pageHeader display-1"
        contentClassName="top-lr-content"
        className="w-100 md:w-4"
      >
        <form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              formik.handleSubmit();
            }
          }}
        >
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-12 mt-2">
            <label className="inputLabel"> Folder Name *</label>
            <InputText
              name="folderName"
              autoFocus
              value={formik.values.folderName}
              onChange={(e) => {
                const { name, value } = e.target;
                // Remove special characters using a regular expression
                // const newValue = value.replace(/[^\s\w]/gi, "");
                handleChange(name, value);
              }}
              maxLength={200}
              autoComplete="off"
              className={classNames({
                "p-invalid": isFormFieldInvalid("folderName"),
                "card-inputBox": true,
                "p-filled": true,
              })}
              placeholder="Enter Folder Name "
            />
            {getFormErrorMessage("folderName")}
            <div className="mt-2 d-block sm:d-flex sm:flex-column p-1 col-12 sm:col-12">
              <div className="d-flex justify-content-between">
                <label className="inputLabel"> Description *</label>
                <div>{formik.values.Description.length}/200</div>
              </div>
              <InputTextarea
                name="Description"
                maxLength={200}
                value={formik.values.Description}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("Description"),
                  "card-inputBox": true,
                  "p-filled": true,
                })}
                autoComplete="off"
                placeholder="Enter Description"
              />

              {getFormErrorMessage("Description")}
            </div>
          </div>
          <div className="align-self-center flex align-items-center justify-content-between mx-1">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisibleCreateEditFolder(false);
                formik.resetForm();
              }}
              className="p-button p-component btn-navActive px-5 mx-auto d-block"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
              className="p-button p-component btn-navActive px-5 mx-auto d-block"
            >
              Save
            </Button>
          </div>
        </form>
      </Dialog>

      <Dialog
        header={`Rename ${visibleShare?.data?.fileID ? "File" : "Folder"}`}
        visible={visibleShare?.visibleRename}
        onHide={() => {
          setVisibleShare({
            ...visibleShare,
            visibleRename: false,
            visible: false,
          });
          renameFormik.resetForm();
        }}
        headerClassName="border-bottom text-center flex justify-content-center align-item-center pageHeader display-1"
        contentClassName="top-lr-content"
        className="w-100 md:w-4"
      >
        <form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              renameFormik.handleSubmit();
            }
          }}
        >
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-12 mt-2">
            <p className="mb-2">
              <small className="p-error">
                Note: {visibleShare?.data?.fileID ? "File" : "Folder"} with the
                same name already exists.
              </small>
            </p>
            <label className="inputLabel">
              {" "}
              New {visibleShare?.data?.fileID ? "file" : "folder"} name *
            </label>
            <InputText
              name="newFolderName"
              autoFocus
              value={renameFormik.values.newFolderName}
              onChange={(e) => {
                const { name, value } = e.target;
                // const newValue = value.replace(/[^\s\w]/gi, "");
                renameFormik.setFieldValue(name, value);
              }}
              maxLength={200}
              autoComplete="off"
              className={classNames({
                "p-invalid": isRenameFormFieldInvalid("newFolderName"),
                "card-inputBox": true,
                "p-filled": true,
              })}
              placeholder={`Enter new ${
                visibleShare?.data?.fileID ? "file" : "folder"
              } name`}
            />
            {getIsRenameFormErrorMessage("newFolderName")}
          </div>
          <div className="align-self-center flex align-items-center justify-content-between mx-1">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisibleShare({
                  ...visibleShare,
                  visibleRename: false,
                  visible: false,
                });
                renameFormik.resetForm();
              }}
              className="p-button p-component btn-navActive px-5 mx-auto d-block"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                renameFormik.handleSubmit();
              }}
              className="p-button p-component btn-navActive px-5 mx-auto d-block"
            >
              {visibleShare?.transferType === ShareTransferType
                ? "Share"
                : visibleShare?.transferType === MoveTransferType
                ? "Move"
                : "Copy"}
            </Button>
          </div>
        </form>
      </Dialog>

      <Dialog
        header={`Edit ${
          formikRenameFolderFile?.values?.FolderID
            ? "Folder"
            : formikRenameFolderFile?.values?.isLink
            ? "Link"
            : "File"
        }`}
        visible={visibleFolderFileRename}
        onHide={() => {
          setVisibleFolderFileRename(false);
          formikRenameFolderFile.resetForm();
        }}
        headerClassName="border-bottom text-center flex justify-content-center align-item-center pageHeader display-1"
        contentClassName="top-lr-content"
        className="w-100 md:w-4"
      >
        <form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              formikRenameFolderFile.handleSubmit();
            }
          }}
        >
          <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-12 mt-2">
            <label className="inputLabel">
              {formikRenameFolderFile?.values?.FolderID ? (
                "Folder Name"
              ) : formikRenameFolderFile?.values?.isLink ? (
                <>Link </>
              ) : (
                "File Name"
              )}
              *
            </label>
            {formikRenameFolderFile.values.FolderID ? (
              <>
                <InputText
                  name="NewFolderName"
                  maxLength={200}
                  autoFocus
                  value={formikRenameFolderFile.values.NewFolderName}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    // Remove special characters using a regular expression
                    // const newValue = value.replace(/[^\s\w]/gi, "");
                    handleChangeRename(name, value);
                  }}
                  className={classNames({
                    "p-invalid": isFormRenameFieldInvalid("NewFolderName"),
                    "card-inputBox": true,
                    "p-filled": true,
                  })}
                  autoComplete="off"
                  placeholder="Enter New Folder Name "
                />
                {getRenameFormErrorMessage("NewFolderName")}
              </>
            ) : formikRenameFolderFile?.values?.isLink ? (
              <>
                <InputText
                  name="NewFileName"
                  value={formikRenameFolderFile.values.NewFileName}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    handleChangeRename(name, value);
                  }}
                  className={classNames({
                    "p-invalid": isFormRenameFieldInvalid("NewFileName"),
                    "card-inputBox": true,
                    "p-filled": true,
                  })}
                  autoComplete="off"
                  autoFocus
                  placeholder="Enter Link"
                />
                {getRenameFormErrorMessage("NewFileName")}
              </>
            ) : (
              <>
                <div className="p-inputgroup flex-1 mb-1">
                  <InputText
                    maxLength={200}
                    name="NewFileName"
                    autoFocus
                    value={formikRenameFolderFile.values.NewFileName}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      // const newValue = !formikRenameFolderFile.values.isLink
                      //   ? value.replace(/[^\d.A-Za-z]/g, "") // Remove special characters and underscores while preserving dots
                      //   : value;
                      handleChangeRename(name, value);
                    }}
                    className={classNames({
                      "p-invalid": isFormRenameFieldInvalid("NewFileName"),
                      "card-inputBox": true,
                      "p-filled": true,
                    })}
                    autoComplete="off"
                    placeholder={`Enter new file name`}
                  />
                  {formikRenameFolderFile.values.extension && (
                    <div
                      className="bg-secondary ml-1 p-4 d-flex align-items-center rounded"
                      style={{
                        borderRight: "1px solid #c8c8c8 !important",
                      }}
                    >
                      {formikRenameFolderFile.values.extension}
                    </div>
                  )}
                </div>
                {getRenameFormErrorMessage("NewFileName")}
              </>
            )}
            <div className="mt-2 d-block sm:d-flex sm:flex-column p-1 col-12 sm:col-12">
              <div className="d-flex justify-content-between">
                <label className="inputLabel"> Description *</label>
                <div>
                  {formikRenameFolderFile.values.Description.length}/200
                </div>
              </div>
              <InputTextarea
                name="Description"
                maxLength={200}
                value={formikRenameFolderFile.values.Description}
                onChange={formikRenameFolderFile.handleChange}
                className={classNames({
                  "p-invalid": isFormRenameFieldInvalid("Description"),
                  "card-inputBox": true,
                  "p-filled": true,
                })}
                autoComplete="off"
                placeholder="Enter Description"
              />
              {getRenameFormErrorMessage("Description")}
            </div>
          </div>
          <div className="align-self-center flex align-items-center justify-content-between mx-1">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisibleFolderFileRename(false);
                formikRenameFolderFile.resetForm();
              }}
              className="p-button p-component btn-navActive px-5 mx-auto d-block"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                formikRenameFolderFile.handleSubmit();
              }}
              className="btn-navActive px-5 mx-auto d-block"
            >
              <span className="ml-auto mr-auto"> Save </span>
            </Button>
          </div>
        </form>
      </Dialog>

      <Dialog
        header={visibleFileUpload?.isLink ? "Add Link" : "Upload File"}
        visible={visibleFileUpload?.isOpen}
        onHide={() => {
          setDocName("");
          formikfile.resetForm();
          setVisibleFileUpload({ ...visibleFileUpload, isOpen: false });
        }}
        headerClassName="border-bottom text-center flex justify-content-center align-item-center pageHeader display-1"
        contentClassName="top-lr-content"
        className="w-100 md:w-4"
      >
        <div className="container col-10 p-0">
          {!visibleFileUpload?.isLink ? (
            <>
              <form
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    formikfile.handleSubmit();
                  }
                }}
              >
                <div className="w-100 mt-4">
                  <FileUpload
                    mode="basic"
                    name="demo"
                    ref={fileRef}
                    auto={true}
                    // maxFileSize={10_000_000}
                    customUpload={true}
                    chooseLabel="Select File"
                    className="btn-dialog border-round-md w-100 UploadAccreBtn"
                    uploadHandler={uploadFile}
                  />
                </div>

                {DocName === "" || DocName === undefined ? (
                  <></>
                ) : (
                  <div className="d-flex mt-1 mb-2">
                    <div className="flex flex-wrap">
                      <label className="inputLabel text-sm mb-0">
                        File Name:
                      </label>
                      <NavLink
                        style={{
                          marginLeft: "5px",
                          maxWidth: "90%",
                          wordBreak: "break-word",
                        }}
                        className="document-link text-sm"
                        to={""}
                      >
                        {DocName}
                      </NavLink>
                    </div>
                  </div>
                )}
                <div className="mt-2 d-block sm:d-flex sm:flex-column p-1 col-12 sm:col-12">
                  <div className="d-flex justify-content-between">
                    <label className="inputLabel"> Description *</label>
                    <div>{formikfile.values.Description.length}/200</div>
                  </div>
                  <InputTextarea
                    maxLength={200}
                    name="Description"
                    value={formikfile.values.Description}
                    onChange={formikfile.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("Description"),
                      "card-inputBox": true,
                      "p-filled": true,
                    })}
                    autoComplete="off"
                    placeholder="Enter Description"
                  />
                  {getFileFormErrorMessage("Description")}
                </div>
                <div className="align-self-center flex align-items-center justify-content-between mx-1">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setDocName("");
                      formikfile.resetForm();
                      setVisibleFileUpload({
                        ...visibleFileUpload,
                        isOpen: false,
                      });
                    }}
                    className="p-button p-component btn-navActive px-5 mx-auto d-block"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="btn-navActive px-5 mx-auto d-block"
                    onClick={(e) => {
                      e.preventDefault();
                      formikfile.handleSubmit();
                    }}
                    disabled={!DocName}
                  >
                    Upload
                  </Button>
                </div>
              </form>
            </>
          ) : (
            <>
              <form
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    formikfile.handleSubmit();
                  }
                }}
              >
                <div className="d-block sm:d-flex sm:flex-column p-1 col-12 sm:col-12 mt-2">
                  <label className="inputLabel"> Link * </label>
                  <InputText
                    name="DocumentLink"
                    value={formikfile.values.DocumentLink}
                    onChange={formikfile.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("DocumentLink"),
                      "card-inputBox": true,
                      "p-filled": true,
                    })}
                    autoComplete="off"
                    autoFocus
                    placeholder="Enter Link"
                  />
                  {getFileFormErrorMessage("DocumentLink")}
                </div>
                <div className="d-block sm:d-flex sm:flex-column p-1 col-12 sm:col-12">
                  <div className="d-flex justify-content-between">
                    <label className="inputLabel"> Description *</label>
                    <div>{formikfile.values.Description.length}/200</div>
                  </div>
                  <InputTextarea
                    maxLength={200}
                    name="Description"
                    value={formikfile.values.Description}
                    onChange={formikfile.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("Description"),
                      "card-inputBox": true,
                      "p-filled": true,
                    })}
                    autoComplete="off"
                    placeholder="Enter Description"
                  />
                  {getFileFormErrorMessage("Description")}
                </div>
                <div className="align-self-center flex align-items-center justify-content-between mx-1">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setDocName("");
                      formikfile.resetForm();
                      setVisibleFileUpload({
                        ...visibleFileUpload,
                        isOpen: false,
                      });
                    }}
                    className="p-button p-component btn-navActive px-5 mx-auto d-block"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      formikfile.handleSubmit();
                    }}
                    className="p-button p-component btn-navActive px-5 mx-auto d-block"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </>
          )}
        </div>
      </Dialog>

      <Dialog
        header={`${
          visibleShare?.transferType === ShareTransferType
            ? visibleShare?.data?.isShared
              ? "Private"
              : "Share"
            : visibleShare?.transferType === MoveTransferType
            ? "Move"
            : "Copy"
        } Folder`}
        visible={visibleShare?.visible}
        headerClassName="border-bottom text-center flex justify-content-center align-item-center pageHeader display-1"
        contentClassName="top-lr-content"
        className="w-100 md:w-4"
        onHide={() => {
          setVisibleShare({ ...visibleShare, visible: false });
          setTreeNodes([]);
          setSelectedNodeKey("");
          setSelectedNode([]);
          setExpandedKeys({ "0": true });
          setFilterValue("");
        }}
      >
        <div className="mx-1">
          <h6
            className="my-2"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            You are{" "}
            <span className="font-weight-bold">
              {visibleShare?.transferType === ShareTransferType
                ? visibleShare?.data?.isShared
                  ? "Private"
                  : "Sharing"
                : visibleShare?.transferType === MoveTransferType
                ? "Moving"
                : "Copying"}{" "}
            </span>
            this{" "}
            <span className="font-weight-bold">
              {visibleShare.data?.documentLink
                ? visibleShare.data?.isLink
                  ? "Link"
                  : "File"
                : "Folder"}
            </span>
            :{" "}
            <span
              className="font-weight-bold"
              style={{
                color: iconColor,
              }}
              title={visibleShare?.data?.name}
            >
              {visibleShare?.data?.name}
            </span>
          </h6>
          <div>
            <InputText
              value={filterValue}
              className="w-100"
              onChange={onFilterChange}
              placeholder="Search Folder"
            />
            {filteredNodes.length > 0 ? (
              <Tree
                onToggle={(e) => setExpandedKeys(e.value)}
                value={filteredNodes}
                expandedKeys={expandedKeys}
                className="w-full mt-2"
                selectionMode="single"
                onSelect={onSelect}
                nodeTemplate={nodeTemplate}
                selectionKeys={selectedNodeKey}
                style={{ maxHeight: "calc(80vh - 150px)", overflowY: "auto" }}
                onSelectionChange={(e) => {
                  setSelectedNodeKey(e.value);
                }}
              />
            ) : (
              <p
                className="card-body mt-2"
                style={{ borderRadius: "5px", border: "1px solid #ced4da" }}
              >
                No folder found!
              </p>
            )}
          </div>

          <div className="align-self-center flex align-items-center justify-content-between mx-1 mt-2">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisibleShare({ ...visibleShare, visible: false });
                setTreeNodes([]);
                setSelectedNodeKey("");
                setSelectedNode([]);
                setExpandedKeys({ "0": true });
                setFilterValue("");
              }}
              className="p-button p-component btn-navActive px-5 mx-auto d-block"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                saveFolderTransfer();
              }}
              disabled={!selectedNodeKey || transferDisabled}
              className="p-button p-component btn-navActive px-5 mx-auto d-block"
            >
              {visibleShare?.transferType === ShareTransferType
                ? visibleShare?.data?.isShared
                  ? "Private"
                  : "Share"
                : visibleShare?.transferType === MoveTransferType
                ? "Move"
                : "Copy"}
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={visibleDelete}
        className="w-100 md:w-3 DialogInvestment"
        onHide={() => {
          setVisibleDelete(false);
          setRemoveFileFolder({
            id: undefined,
            isFolder: false,
            isLink: false,
          });
        }}
      >
        <h4 className="pageHeader text-center mb-5 pt-5">
          Are you sure you want to delete ?
        </h4>
        <div className="align-self-center flex align-items-center justify-content-between mx-1">
          <Button
            onClick={onDeleteFolderFile}
            className="blueButtonShadow w-8rem d-block h-auto"
          >
            Yes
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setVisibleDelete(false);
              setRemoveFileFolder({
                id: undefined,
                isFolder: false,
                isLink: false,
              });
            }}
            className="blueButtonShadow w-8rem d-block h-auto"
          >
            No
          </Button>
        </div>
      </Dialog>
    </>
  );
}
