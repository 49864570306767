import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import Loader from "../../../../../components/loader/Loader";

import { getThunkSupplierDropDownList } from "../../../../../store/features/taxmanagement/1099/input-form-1099/supplier-dropdown/slice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { exportExcel } from "../../../../../utils/excel-dataformat";
import Form1099Div from "./Form1099Div";
import Form1099Int from "./Form1099Int";

export default function InputForm1099() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [loading, setloading] = useState<any>(false);
  const [formType, setFormType] = useState("int");
  const [openDailog, setOpenDailog] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [inputList, setInputList] = useState([]);
  const [excelHeader, setExcelHeader] = useState([]);

  useEffect(() => {
    setFormType("int");
    if (fundDropdown.fundid) {
      setloading(true);

      dispatch(
        getThunkSupplierDropDownList({ legalEntityId: fundDropdown.fundid })
      ).finally(() => {
        setloading(false);
      });
    }
  }, [fundDropdown.fundid]);

  const checkListforDisable = () => {
    return !!(
      inputList === undefined ||
      inputList?.length === 0 ||
      inputList === null
    );
  };

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 pb-1">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">
              1099: Input 1099 Forms from Suppliers
            </h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="remove-shadow mr-2"
              type="button"
              // icon="pi pi-file"
              onClick={() =>
                exportExcel(
                  excelHeader,
                  inputList,
                  "1099: Input 1099 Forms from Suppliers",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={checkListforDisable()}
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={checkListforDisable()}
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>

        <div className="d-flex flex-row  p-0">
          <div className="tabHeader-wrap p-0">
            <Button
              onClick={() => setFormType("int")}
              className={
                formType === "int" ? "tabHeadText Active" : "tabHeadText "
              }
            >
              1099-INT{" "}
            </Button>
          </div>

          <div className="tabHeader-wrap p-0">
            <Button
              onClick={() => setFormType("div")}
              className={
                formType === "div" ? "tabHeadText Active" : "tabHeadText "
              }
            >
              1099-DIV{" "}
            </Button>
          </div>
        </div>
        <div className="grid col-12 mt-1 card">
          {formType === "int" ? (
            <>
              <Form1099Int
                openDialog={openDailog}
                setOpenDialog={setOpenDailog}
                setExcelHeader={setExcelHeader}
                setInputList={setInputList}
              />{" "}
            </>
          ) : (
            <>
              <Form1099Div
                openDialog={openDailog}
                setOpenDialog={setOpenDailog}
                setExcelHeader={setExcelHeader}
                setInputList={setInputList}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
