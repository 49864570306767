import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { InputText } from "primereact/inputtext";
// import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
// import { Skeleton } from "primereact/skeleton";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
// import { RiSearchLine } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { CanceltransferThunk } from "../../../../store/dwolla/canceltransfer/slice";
import { getWirelessTransactionThunk } from "../../../../store/finance/transaction/getWirelessTransaction/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";

export default function ACHTransactionsReport() {
  const dispatch = useAppDispatch();
  const portState = useAppSelector((state) => state.transactionReport.loading);
  const reportModel = useAppSelector((state) => state.transactionReport.data);
  console.log("reportModel", reportModel);
  const [exitDate, setExitDate] = useState<any>();
  // const profileData = useAppSelector((state) => state.profile.data![0]);
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  const [showloader, setshowloader] = useState(false);
  const toast = useRef<Toast>(null);
  const [openDailog, setOpenDailog] = useState(false);
  // console.log("h", misssedExitDates);
  // const getExitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       transactionDate: new Date(d.transactionDate),
  //     };
  //     return modifiedExitDate;
  //   });
  // };

  const formatCurrency = (rowData: any) => {
    const finalValue = Number(rowData.tranAmount);
    const parseValue = currencyFormat(Number(finalValue));

    return `${parseValue}`;
  };
  const formatDate = (rowData: any) => {
    console.log("formatDate", rowData);
    const dateFormat = formatDateMMDDYYY(rowData);

    return dateFormat;
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const getSeverity = (value: any) => {
    switch (value) {
      case "processed":
        return "success";

      case "LOWSTOCK":
        return "warning";

      case "cancelled":
        return "danger";

      case "failed":
        return "danger";

      default:
        return "warning";
    }
  };

  const canceltransction = (rowData: any) => {
    if (rowData?.transactionStatus !== null) {
      return (
        <React.Fragment>
          <div className="card flex flex-wrap gap-2">
            {/* <Chip label={rowData.dwollaStatus} icon="pi pi-check-circle" /> */}
            <Tag
              value={rowData.transactionStatus}
              severity={getSeverity(rowData.transactionStatus)}
            ></Tag>
          </div>
        </React.Fragment>
      );
    }
  };
  const [cancelId, setcancelId] = useState("");
  const [cancelconfirmDialog, setcancelconfirmDialog] = useState(false);

  const onCancel = () => {
    setcancelconfirmDialog(false);
  };

  const confirmDelete = () => {
    setcancelconfirmDialog(false);
    const parameters = {
      Input: cancelId,
      LegalEntityId: dropdown.fundid,
    };
    setshowloader(true);
    dispatch(CanceltransferThunk(parameters)).then((res) => {
      setshowloader(false);
      console.log(res);
      if (res.payload.success === true) {
        toast.current?.show({
          severity: "success",
          // summary: "Success",
          // life: 5000,
          detail: "Transfer cancel successfully",
          // life: 3000,
        });
        const parametersget = {
          legalEntityID: dropdown.fundid,
          investorID: 0,
          partnerID: 0,
        };
        dispatch(getWirelessTransactionThunk(parametersget));
      } else {
        toast.current?.show({
          severity: "error",
          // summary: "Success",
          life: 5000,
          detail: res.payload.message,
          // life: 3000,
        });
      }
    });
  };

  const cancelTransction = (data: any) => {
    // confirmDialog({
    //   message: "Are you sure you want to cancel transfer?",
    //   header: "Confirmation",
    //   icon: "pi pi-exclamation-triangle",
    //   accept: () => confirmDelete(data.transactionId),
    // });
    setcancelId(data.transactionId);
    setcancelconfirmDialog(true);
    // setBeneficialOwnersId(data.dwollaOwnerId);
  };

  const actionBodycancelTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          className="mr-4 blueButtonShadow w-7rem"
          onClick={() => cancelTransction(rowData)}
          type="button"
          disabled={rowData?.transactionStatus !== "pending"}
        >
          Cancel
        </Button>
      </React.Fragment>
    );
  };

  const dataTableElements = [
    {
      field: "sourceUser",
      header: "Transfer From",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.sourceUser}>
          {rowData?.sourceUser}
        </div>
      ),
    },
    {
      field: "destinationUser",
      header: "Transfer To",
      style: { minWidth: "150px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.destinationUser}>
          {rowData?.destinationUser}
        </div>
      ),
    },
    {
      field: "transactionId",
      header: "Transaction Id",
      style: { minWidth: "150px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.transactionId}>
          {rowData?.transactionId}
        </div>
      ),
    },
    {
      field: "tranAmount",
      header: "Amount",
      body: formatCurrency,
      style: { minWidth: "150px" },
    },
    {
      field: "Fees",
      header: "Fees",
      style: { minWidth: "100px" },
    },

    {
      field: "transactionDate",
      header: "Transaction Date",
      dataType: "date",
      body: (rowData: any) => {
        return rowData.transactionDate
          ? formatDate(rowData.transactionDate)
          : rowData.transactionDate;
      },
      style: { minWidth: "200px" },
      filterElement: dateFilterTemplate,
    },
    {
      field: "transactionStatus",
      header: "Status",
      body: canceltransction,
      style: { minWidth: "100px" },
    },
    {
      field: "sourceBankAccountName",
      header: "Source Bank Account Name",
      style: { minWidth: "250px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.sourceBankAccountName}>
          {rowData?.sourceBankAccountName}
        </div>
      ),
    },
    {
      field: "destinationBankAccountName",
      header: "Destination Bank Account Name",
      style: { minWidth: "250px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.destinationBankAccountName}>
          {rowData?.destinationBankAccountName}
        </div>
      ),
    },
    {
      header: "Action",
      body: actionBodycancelTemplate,
      style: { minWidth: "200px" },
    },
  ];

  useEffect(() => {
    if (dropdown.fundid !== undefined && dropdown.fundid > 0) {
      const parameters = {
        legalEntityID: dropdown.fundid,
        investorID: 0,
        partnerID: 0,
      };
      dispatch(getWirelessTransactionThunk(parameters));
    }
  }, [dropdown.fundid]);
  useEffect(() => {
    setExitDate(getListDateConversion(reportModel, ["transactionDate"]));
  }, [reportModel]);

  useEffect(() => {
    if (portState === "loading") {
      setshowloader(true);
    } else {
      setshowloader(false);
    }
  }, [portState]);
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  // const rowDta = 10;
  return (
    <>
      <Toast ref={toast} className="themeToast" />
      {showloader === true ? <Loader /> : <></>}
      <Toast ref={toast} className="themeToast" />
      {/* <ConfirmDialog /> */}
      <ConfirmDailogbox
        openDialog={cancelconfirmDialog}
        setOpenDialog={setcancelconfirmDialog}
        onConfirm={confirmDelete}
        onCancel={onCancel}
        textContent="Are you sure you want to cancel transfer??"
      />
      <div className="col-12 flex">
        <div className="card col-11 p-0 pgHeaderWrap ">
          <h1 className="pageHeader fs-2">ACH Transactions Report</h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className="col-1 p-0 flex align-items-center justify-content-end">
          <Button
            className="btn-navActive"
            type="button"
            icon="pi pi-window-maximize"
            disabled={
              exitDate === undefined ||
              exitDate?.length === 0 ||
              exitDate === null
            }
            onClick={() => setOpenDailog(true)}
            title="View Full Screen"
          />
        </div>
      </div>
      {/* <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">ACH Transactions Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div> */}
      {/* <div className="grid">
        <div className="col-12"> */}
      <div className="card">
        {/* <Card> */}
        {/* <h4 className="cardHeader">ACH Transactions Report</h4> */}
        {/* <div className="row my-4 d-flex justify-content-between"> </div> */}
        <DataTableComponent
          valueData={exitDate}
          scrollable={true}
          openDailog={openDailog}
          setOpenDailog={setOpenDailog}
          fieldsElements={dataTableElements}
          isPaginationAllowed={true}
          className="gridcell deletebtn"
        />
        {/* </Card> */}
      </div>
      {/* </div>
      </div> */}
    </>
  );
}
