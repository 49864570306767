// import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getSearchInvestorEmployeeReport } from "../../../../store/features/reports/search-investor-employee/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { exportExcel } from "../../../../utils/excel-dataformat";
import { regexForEmail } from "../../../../utils/sponsor/constants";

// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
// import SearchInvestorEmployeeSkeleton from "./SearchInvestorEmployeeSkeleton";

export default function SearchInvestorEmployee() {
  const dispatch = useAppDispatch();
  // const portState = useAppSelector(
  //   (state) => state.SearchInvestorEmployee.loading
  // );
  const SearchInvestor = useAppSelector(
    (state) => state.SearchInvestorEmployee.data
  );
  // const [exitDate, setExitDate] = useState<any>();
  const [loading, setloading] = useState<any>(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const [stateName, setName] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      businessName: "",
    },
    validate: (data) => {
      const errors: any = {};
      if (data.email && !regexForEmail.test(data.email)) {
        errors.email = "Enter valid email";
      }
      return errors;
    },
    onSubmit: (data) => {
      console.log("in submit else");
      setloading(true);
      const email = formik.values.email ? formik.values.email.trim() : "";
      const hasSpecialCharacter = /[!#$%&()*+,./:;<>?@[\\\]^_{}~-]/.test(email);

      const encodedEmail = hasSpecialCharacter
        ? encodeURIComponent(email)
        : email;

      const paramter = {
        FirstName: formik.values.firstName ? formik.values.firstName : "",
        LastName: formik.values.lastName ? formik.values.lastName : "",
        Email: encodedEmail,
        BusinessName: data.businessName ? data.businessName : "",
        LegalEntityId: fundDropdown.fundid,
        UserTypeId: profileData.userTypeId,
      };

      dispatch(getSearchInvestorEmployeeReport(paramter))
        .then(() => {
          setName(false);

          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    },
  });
  useEffect(() => {
    setName(true);
    const paramter = {
      FirstName: "",
      LastName: "",
      Email: "",
      BusinessName: "",
      LegalEntityId: fundDropdown.fundid,
      UserTypeId: profileData.userTypeId,
    };
    setloading(true);
    dispatch(getSearchInvestorEmployeeReport(paramter))
      .then(() => {
        setName(false);
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });
    formik.resetForm();
  }, [fundDropdown?.fundid]);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);
  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const dataTableElements = [
    {
      field: "legalEntityName",
      header: "Legal Entity Name",
      style: { minWidth: "240px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.legalEntityName}>
          {rowData?.legalEntityName}
        </div>
      ),
    },
    {
      field: "firstName",
      header: "First Name",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.firstName}>
          {rowData?.firstName}
        </div>
      ),
    },
    {
      field: "lastName",
      header: "Last Name",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.lastName}>
          {rowData?.lastName}
        </div>
      ),
    },
    {
      field: "emailAddress",
      header: "Email",
      style: { minWidth: "260px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.emailAddress}>
          {rowData?.emailAddress}
        </div>
      ),
    },
    {
      field: "userType",
      header: "User Type",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.userType}>
          {rowData?.userType}
        </div>
      ),
      //   body: formatFilingDate,
      //   filterElement: dateFilterTemplate,
    },
    {
      field: "businessName",
      header: "Business Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.businessName}>
          {rowData?.businessName}
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   setExitDate(getExitDateConversion(SearchInvestor));
  // }, [SearchInvestor]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      {loading && <Loader />}{" "}
      <>
        <div className="grid col-12 p-0">
          <div className="col-12 md:col-9 pl-1 p-0">
            <h1 className="pageHeader fs-2">Search Investor Employee Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className=" col-12 md:col-3 flex align-items-center justify-content-end px-0">
            <Button
              className="remove-shadow mr-2"
              type="button"
              onClick={() =>
                exportExcel(
                  dataTableElements,
                  SearchInvestor || [],
                  "Search Investor Employee Report",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                SearchInvestor === undefined ||
                SearchInvestor?.length === 0 ||
                SearchInvestor === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center gap-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  SearchInvestor === undefined ||
                  SearchInvestor?.length === 0 ||
                  SearchInvestor === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>

        {/* <div className="col-12 md:col-6">
          <div></div>
        </div> */}
        {/* </div> */}
        {/* <div className="grid">
          <div className="col-12"> */}
        <div className="card  tabWithoutboxshadow">
          {/* <Card> */}
          {/* <h4 className="cardHeader">Search Investor Employee Report </h4> */}
          {/* <div className="row  d-flex justify-content-between"> */}
          <div className="row mm:col-6 wrap align-items-center col-12 ml-0">
            <div className="col-12 md:col-4 m-0 py-0 mb-md-0">
              <div className="">
                <label className="inputLabel">First Name</label>
                <InputText
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={(e) =>
                    formik.setFieldValue("firstName", e.target.value)
                  }
                  // onChange={(e) => setFirstName(e.target.value)}
                  // className="card-inputBox"
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("firstName"),
                    "card-inputBox": true,
                  })}
                  placeholder="First Name"
                />
              </div>
              {getFormErrorMessage("firstName")}
            </div>
            <div className="col-12 md:col-4 m-0 py-0 mb-md-0">
              <div className="">
                <label className="inputLabel">Last Name</label>
                <InputText
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={(e) =>
                    formik.setFieldValue("lastName", e.target.value)
                  }
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("lastName"),
                    "card-inputBox": true,
                  })}
                  placeholder="Last Name"
                />
              </div>
              {getFormErrorMessage("lastName")}
            </div>
            <div className="col-12 md:col-4 m-0 py-0 mb-md-0">
              <div className="">
                <label className="inputLabel">Email</label>
                <InputText
                  name="email"
                  value={formik.values.email}
                  onChange={(e) =>
                    formik.setFieldValue("email", e.target.value)
                  }
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("email"),
                    "card-inputBox": true,
                  })}
                  placeholder="Email"
                />
              </div>
              {getFormErrorMessage("email")}
            </div>
            <div className="col-12 md:col-4 m-0 py-0 mb-md-0">
              <div className="">
                <label className="inputLabel">Business Name</label>
                <InputText
                  name="Bussiness Name"
                  value={formik.values.businessName}
                  onChange={(e) =>
                    formik.setFieldValue("businessName", e.target.value)
                  }
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("businessName"),
                    "card-inputBox": true,
                  })}
                  placeholder="Business Name"
                />
              </div>
              {getFormErrorMessage("businessName")}
            </div>
            <div className="col-12 md:col-4 m-0 py-0 mb-md-0">
              <div className="d-flex align-items-center">
                <Button
                  className="btn-nav flex mr-3"
                  label="Reset"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.resetForm();
                    formik.handleSubmit();
                  }}
                >
                  {/* <span>Save</span> */}
                </Button>
                <Button
                  className="btn-nav flex "
                  label="Show"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    // setIsFormSubmitted(true);
                    console.log("clicked");
                    formik.handleSubmit();
                  }}
                >
                  {/* <span>Save</span> */}
                </Button>
              </div>
            </div>
          </div>
          {/* <div className="d-flex justify-content-center">
                    <Button
                      className="btn-nav flex align-items-center justify-content-center md:w-2"
                      label="Show"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        console.log("clicked");
                        formik.handleSubmit();
                      }}
                    ></Button>
                  </div> */}
          {/* </div> */}
          {SearchInvestor !== undefined && !stateName && (
            <DataTableComponent
              valueData={SearchInvestor || []}
              fieldsElements={dataTableElements}
              isPaginationAllowed={true}
              isDownload={true}
              fileName="Search Investor Employee Report"
              scrollable={true}
              columnResizeMode="expand"
              openDailog={openDailog}
              setOpenDailog={setOpenDailog}
              maxHeight={"calc(-330px + 100vh)"}
              className="gridcell"
            />
          )}
          {/* </Card> */}
        </div>
        {/* </div>
        </div> */}
      </>
    </>
  );
}
