import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  InputForm1099DivCUDService,
  InputForm1099DivDeleteService,
} from "../../../../../../../services/taxmanagement/tax";
import error from "../../../../../../../utils/error";

export interface IInputForm1099DivCUDState {
  success: boolean;
  message: string;
  loading: string;
  error: any;
  data: any;
}
const initialState: IInputForm1099DivCUDState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: undefined,
};

export const CUDThunkInputForm1099Div = createAsyncThunk(
  "inputform1099DIV/cud",
  async (data: any) => {
    try {
      const response = await InputForm1099DivCUDService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

export const deleteThunkInputForm1099Div = createAsyncThunk(
  "inputform1099DIV/delete",
  async (data: any) => {
    try {
      const response = await InputForm1099DivDeleteService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "inputform1099DIV",
  initialState,
  reducers: {
    onResetInput1099DIV: (state) => {
      state.data = [];
    },
  },
  extraReducers(builder): void {
    builder.addCase(CUDThunkInputForm1099Div.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(CUDThunkInputForm1099Div.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("set", action.payload);
      // state.data = action.payload._response.data;
    });
    builder.addCase(CUDThunkInputForm1099Div.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
    builder.addCase(deleteThunkInputForm1099Div.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(deleteThunkInputForm1099Div.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("set", action.payload);
      // state.data = action.payload._response.data;
    });
    builder.addCase(deleteThunkInputForm1099Div.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});
export const { onResetInput1099DIV } = slice.actions;
export default slice.reducer;
