import { useFormik } from "formik";
// import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import { RiAddFill } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../../components/loader/Loader";
import { goToTop } from "../../../../components/scroll-to/ScrollTo";
import { BlueSkyStateDropdownThunk } from "../../../../store/features/sponser/manage-bluesky-filings/bluesky-state-dropdown/slice";
import { CheckStateFilingFrequencyThunk } from "../../../../store/features/sponser/manage-bluesky-filings/checkstatefilingfrequency/slice";
import { DeleteBlueSkyFilingDataThunk } from "../../../../store/features/sponser/manage-bluesky-filings/deleteblueskyfilingdata/slice";
import { GetBlueSkyFillingDetailsThunk } from "../../../../store/features/sponser/manage-bluesky-filings/getblueskyfillingdetails/slice";
import { SaveBlueskyFilingDataThunk } from "../../../../store/features/sponser/manage-bluesky-filings/saveblueskyfilingdata/slice";
import { UpdateBlueskyFilingDataThunk } from "../../../../store/features/sponser/manage-bluesky-filings/updateblueskyfilingdata/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  // dateFormatter,
  convertLocalDateToUTC,
  getListDateConversion,
  formatDateMMDDYYY,
} from "../../../../utils/date-formatter";
import { formatYesNo } from "../../../../utils/helper";
import { successToastMessage } from "../../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function GetBlueSkyFillingDetails() {
  let param: any;
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [openDailog, setOpenDailog] = useState(false);

  const profileData = useAppSelector((state) => state.profile.data![0]);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const dropdownModel = useAppSelector((state) => state.legalEntityList.data);

  const GetBlueSkyFilling = useAppSelector(
    (state) => state.GetBlueSkyFillingDetails?.data
  );

  console.log("fundDropdown", fundDropdown);
  //   const getParentDropdown = useAppSelector(
  //     (state) => state.GetParentMenuList?.data
  //   );

  const GetBlueSkyFillingState = useAppSelector(
    (state) => state.BindBlueSkyStateDropdown?.data
  );
  console.log("GetBlueSkyFilling", GetBlueSkyFilling);

  //   const [leagalentity, setLeagalentity] = useState<any>();
  const [amount, setAmount] = useState<any>("");
  const [id, setId] = useState<any>("");
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);

  const [selectedValue, setSelectedValue] = useState<any>();

  const onChange = (event: any) => {
    setSelectedValue(event.value);
  };
  const [visible, setVisible] = useState(false);
  const [stateVisible, setStateVisible] = useState(false);

  const [editClicked, setEditClicked] = useState(false);

  const [loading, setloading] = useState<any>(false);

  // const options = [
  //   { label: "Option 1", value: 1 },
  //   { label: "Option 2", value: 2 },
  //   { label: "Option 3", value: 3 },
  // ];
  const legalEntityList: { code: number; name: string }[] = [];
  for (let i = 0; i < dropdownModel?.length; i++) {
    legalEntityList.push({
      code: dropdownModel![i].legalEntityID,
      name: dropdownModel![i].legalEntityName,
    });
  }

  // const handleChange = (e: any) => {
  //   return setLeagalentity(e.value);
  //   // Do something with the selected value

  // };
  const objFilingType = [
    { key: "State", value: "State" },
    { key: "EFD Initial Setup", value: "EFD Initial Setup" },
  ];

  const objStatusType = [
    { key: "Pending", value: "Pending" },
    { key: "Complete", value: "Complete" },
    { key: "Expired", value: "Expired" },
  ];

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling animation
    });
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const formatDate = (rowData: any) => {
    return rowData.filingDate
      ? formatDateMMDDYYY(rowData.filingDate)
      : rowData.filingDate;
  };

  const formik = useFormik({
    initialValues: {
      FilingType: "",
      State: "",
      Date: "",
      ExpirationDate: "",
      Status: "",
      invoiceDate: "",
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (!data.FilingType) {
        errors.FilingType = "Supplier type is required";
      }

      return errors;
    },
    onSubmit: async () => {
      console.log("id", id, edit);
      console.log("EditButton", edit);

      // let currentObject: any;
      // if (id !== 0) {
      //   currentObject = GetBlueSkyFilling?.find((item: any) => item.id === id);
      // }
      // console.log("Current Object", currentObject);

      // const fillingDate =
      //   currentObject.filingDate !== "" &&
      //   currentObject.filingDate !== null &&
      //   currentObject.filingDate !== undefined
      //     ? currentObject.filingDate?.split("T")[0] ===
      //       convertLocalDateToUTC(formik.values.Date).split("T")[0]
      //       ? currentObject.filingDate
      //       : convertLocalDateToUTC(formik.values.Date)
      //     : formik.values.Date === "" || formik.values.Date === null
      //     ? formik.values.Date
      //     : convertLocalDateToUTC(formik.values.Date!);
      // formik.values.Date !== "" && formik.values.Date !== null
      //   ? convertLocalDateToUTC(formik.values.Date)
      //   : formik.values.Date;
      const fillingDate = convertLocalDateToUTC(formik.values.Date!);
      const invDate = convertLocalDateToUTC(formik.values.invoiceDate!);
      // const invDate =
      //   currentObject.invDate !== "" &&
      //   currentObject.invDate !== null &&
      //   currentObject.invDate !== undefined
      //     ? currentObject.invDate?.split("T")[0] ===
      //       convertLocalDateToUTC(formik.values.invoiceDate).split("T")[0]
      //       ? currentObject.invDate
      //       : convertLocalDateToUTC(formik.values.invoiceDate)
      //     : formik.values.invoiceDate === "" ||
      //       formik.values.invoiceDate === null
      //     ? formik.values.invoiceDate
      //     : convertLocalDateToUTC(formik.values.invoiceDate!);
      // formik.values.invoiceDate !== null && formik.values.invoiceDate !== ""
      //   ? convertLocalDateToUTC(formik.values.invoiceDate)
      //   : formik.values.invoiceDate;

      const data = {
        leId: fundDropdown.fundid,
        state: formik.values.State,
        filingType: formik.values.FilingType,
        // eslint-disable-next-line unicorn/no-null
        filingDate: fillingDate || null,
        // eslint-disable-next-line unicorn/no-null
        fileExpDate: null,
        status: formik.values.Status,
        feeAmt: amount + ".0",
        // eslint-disable-next-line unicorn/no-null
        invDate: invDate || null,
        invPaid: selectedValue || false,
        createdby: profileData.userID,
      };
      const updateData = {
        fileId: id,
        leId: fundDropdown!.fundid,
        state: formik.values.State,
        // eslint-disable-next-line unicorn/no-null
        filingDate: fillingDate || null,
        status: formik.values.Status,
        feeAmt: amount + ".0",
        // eslint-disable-next-line unicorn/no-null
        invDate: invDate || null,
        invPaid: selectedValue || false,
      };
      param = {
        LegalEntityId: fundDropdown.fundid,
      };
      console.log(
        "SubmitButton",
        fillingDate,
        invDate,
        data,
        updateData,
        "Edit",
        edit
      );
      // save
      if (edit) {
        dispatch(UpdateBlueskyFilingDataThunk(updateData)).then(() => {
          console.log("update called");

          dispatch(GetBlueSkyFillingDetailsThunk(param));
        });
        setAdd(false);
        setEdit(false);
        setEditClicked(false);
        successToastMessage(toast, "Data updates successfully");
      } else {
        console.log("save called");
        dispatch(SaveBlueskyFilingDataThunk(data!)).then(() => {
          dispatch(GetBlueSkyFillingDetailsThunk(param));
        });
        setAdd(false);
        setEdit(false);
        setEditClicked(false);
        successToastMessage(toast, "Data saved successfully");
      }
    },
  });
  console.log("State............", formik.values.State);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = () => {
      formik.resetForm();
      console.log("editKarRahaHu", rowData);

      // const formattedDate = convertLocalDateToUTC(rowData.filingDate);
      // const formattedDate = new Date(rowData.filingDate);
      // const formattedInvoiceDate = new Date(rowData.invDate);
      console.log("rowData", rowData);
      formik.setFieldValue("FilingType", rowData.filingType);
      formik.setFieldValue("State", rowData.stateCode);
      formik.setFieldValue("Date", rowData.filingDate);
      formik.setFieldValue("ExpirationDate", rowData.expiryDate);
      formik.setFieldValue("Status", rowData.status);
      formik.setFieldValue("invoiceDate", rowData.invDate);

      setId(rowData.id);
      setAmount(rowData.fees);
      setSelectedValue(rowData.invPaid);
      // alert(`${rowData.stateCode}`);

      setEditClicked(true);
      //   setName(rowData.menuName);
      setAdd(false);
      setEdit(true);
      handleScrollToTop();
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={handleEdit}
          >
            <span className="text-white">EDIT</span>
          </Button>
        </div>
      </div>
    );
  };

  const deleteButton = (rowData: any) => {
    return (
      <div className="flex flex-column ">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={(e) => {
              e.preventDefault();
              setId(rowData.id);

              setVisible(true);
            }}
          >
            <span className="text-white">DELETE</span>
          </Button>
        </div>
      </div>
    );
  };

  const resetForm = () => {
    setAmount("");
    setSelectedValue("");
    formik.resetForm();
    setAdd(false);
    setEdit(false);
    // setName("");
  };

  const onAddClick = () => {
    resetForm();
    setAdd(true);
    setEdit(false);
    setEditClicked(false);
  };

  //   const onAddClick = () => {
  //     resetForm();
  //     setEdit(false);
  //   };

  const dataTableElements = [
    {
      field: "state",
      header: "State",
      style: { minWidth: "200px" },
    },
    {
      field: "filingType",
      header: "Filing Type",
      style: { minWidth: "200px" },
    },
    {
      field: "fees",
      header: "Fees",
      body: (rowData: any) => currencyFormat(rowData.fees),
      style: { minWidth: "200px" },
    },
    {
      field: "filingFrequency",
      header: "Filing Frequency",
      style: { minWidth: "200px" },
    },
    {
      field: "filingDate",
      header: "Filing Date",
      dataType: "date",
      body: formatDate,
      filterElement: dateFilterTemplate,
      style: { minWidth: "200px" },
    },
    {
      field: "expiryDate",
      header: "Expiry Date",
      body: (rowData: any) =>
        rowData.expiryDate !== null
          ? formatDateMMDDYYY(rowData.expiryDate)
          : rowData.expiryDate,
      dataType: "date",
      filterElement: dateFilterTemplate,
      style: { minWidth: "200px" },
    },
    {
      field: "status",
      header: "Status",
      style: { minWidth: "200px" },
    },
    {
      field: "invDate",
      header: "Invoice Date",
      dataType: "date",
      body: (rowData: any) =>
        rowData.invDate !== null
          ? formatDateMMDDYYY(rowData.invDate)
          : rowData.invDate,
      filterElement: dateFilterTemplate,
      style: { minWidth: "200px" },
    },
    {
      field: "invPaid",
      header: "Invoice Paid ",
      style: { minWidth: "200px" },
      body: (rowData: any) => formatYesNo(rowData.invPaid),
    },
    {
      header: "",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
      style: { minWidth: "170px" },
    },
    {
      header: "",
      body: (rowData: any) => deleteButton(rowData),
      style: { minWidth: "170px" },
    },
  ];
  const [tableData, setTableData] = useState<any>();

  useEffect(() => {
    if (GetBlueSkyFilling) {
      setTableData(
        getListDateConversion(GetBlueSkyFilling, [
          "filingDate",
          "invDate",
          "expiryDate",
        ])
      );
    }
  }, [GetBlueSkyFilling]);

  console.log("TableDataHere", tableData);

  useEffect(() => {
    if (
      fundDropdown.fundid !== undefined &&
      fundDropdown.fundid > 0 &&
      formik.values.FilingType
    ) {
      const reqBody1 = {
        LEID: fundDropdown.fundid,
      };
      dispatch(BlueSkyStateDropdownThunk(reqBody1));
    }
  }, [formik.values.FilingType]);

  useEffect(() => {
    setloading(true);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      param = {
        LegalEntityId: fundDropdown.fundid,
      };
      const reqBody = {
        LEID: fundDropdown.fundid,
      };

      dispatch(GetBlueSkyFillingDetailsThunk(param))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
      dispatch(BlueSkyStateDropdownThunk(reqBody))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    }
  }, [fundDropdown.fundid]);

  const onDelete = () => {
    const deleteParms = {
      FileId: id,
    };
    param = {
      LegalEntityId: fundDropdown.fundid,
    };
    setloading(true);
    dispatch(DeleteBlueSkyFilingDataThunk(deleteParms)).then(() => {
      dispatch(GetBlueSkyFillingDetailsThunk(param))
        .then(() => {
          setloading(false);
          successToastMessage(toast, "Deleted successfully");
        })
        .catch(() => {
          setloading(false);
        });
    });
    setVisible(false);
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  useEffect(() => {
    if (add || edit) {
      document.body.classList.remove("no-scroll");
    } else {
      goToTop();
      document.body.classList.add("no-scroll");
    }
  }, [add, edit]);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Manage Blue Sky Filings</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
      </div>
      <div className="grid">
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to delete ?
          </h4>
          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={onDelete}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <Dialog
          visible={stateVisible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setStateVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Blue sky filing record already exists for this state. This state
            requires only a one-time file. So additional records should not be
            added.{" "}
          </h4>
          <div className=" align-self-center flex align-items-center justify-content-center">
            <Button
              onClick={() => {
                resetForm();
                setStateVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Ok
            </Button>
          </div>
        </Dialog>

        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <div className="flex col-12 justify-content-between align-items-center">
              {!add ||
                (edit && <div className="tabHeader-wrap mb-3 col-2"></div>)}
              {!edit && !add && (
                <Button onClick={onAddClick} className="btn-navActive mr-0 m-2">
                  <span className="ml-auto mr-auto">
                    {" "}
                    Add{" "}
                    <span className="pl-2">
                      <RiAddFill />
                    </span>
                  </span>
                </Button>
              )}
              {add && (
                <div className="tabHeader-wrap col-2">
                  <Button className="tabHeadText  Active"> Add </Button>
                </div>
              )}
              {edit && (
                <div className="tabHeader-wrap col-2">
                  <Button className="tabHeadText  Active"> Edit </Button>
                </div>
              )}{" "}
              <div
                className={`flex align-items-center justify-content-end ${
                  edit ? "col-10" : "col-10"
                }`}
              >
                <Button
                  className="btn-navActive"
                  type="button"
                  icon="pi pi-window-maximize"
                  onClick={() => setOpenDailog(true)}
                  title="View Full Screen"
                />
              </div>
            </div>

            {add || edit ? (
              <>
                <>
                  {" "}
                  <div className="">
                    <div className="formgrid grid col">
                      <div className="d-block sm:d-flex sm:flex-column md:col-4 col-12 ">
                        <label className="inputLabel">Filing Type *</label>
                        <Dropdown
                          name="FilingType"
                          value={formik.values.FilingType}
                          onChange={(e) =>
                            formik.setFieldValue("FilingType", e.value)
                          }
                          style={{ width: "100%" }}
                          options={objFilingType}
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select.."
                          className={classNames({
                            "p-invalid": isFormFieldInvalid("FilingType"),
                            "card-inputBox": true,
                          })}
                          // filter
                        />
                        {getFormErrorMessage("FilingType")}
                      </div>
                      <div
                        className={
                          formik.values.FilingType === "EFD Initial Setup"
                            ? "d-block sm:d-flex sm:flex-column md:col-4 col-12  state-dropdown"
                            : "d-block sm:d-flex sm:flex-column md:col-4 col-12  "
                        }
                      >
                        <label className="inputLabel">State</label>
                        <Dropdown
                          name="State"
                          value={formik.values.State}
                          filter
                          // onChange={handleChange}
                          onChange={(e) => {
                            formik.setFieldValue("State", e.value);
                            const selectedV = {
                              LegalEntityID: fundDropdown.fundid,
                              State: e.value,
                            };
                            console.log("handle Change e", e.value);

                            formik.setFieldValue("State", e.value);

                            if (
                              formik.values.FilingType !== "EFD Initial Setup"
                            ) {
                              dispatch(
                                CheckStateFilingFrequencyThunk(selectedV)
                              ).then((res: any) => {
                                if (res.payload?.data !== "Add") {
                                  setStateVisible(true);
                                }
                              });
                            }
                          }}
                          style={{ width: "100%" }}
                          options={GetBlueSkyFillingState}
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select.."
                          disabled={
                            !formik.values.FilingType ||
                            formik.values.FilingType === "EFD Initial Setup"
                          }
                          // className={
                          //   type === "EFD Initial Setup" ? "dropdownn" : ""
                          // }
                          className={classNames({
                            "p-invalid ": isFormFieldInvalid("State"),
                            "card-inputBox": true,
                          })}
                          // filter
                        />
                        {getFormErrorMessage("State")}
                      </div>
                    </div>
                    <div className="formgrid grid col">
                      <div className="d-block sm:d-flex sm:flex-column md:col-4 col-12 ">
                        <label className="inputLabel">
                          Bluesky Filing Date
                        </label>
                        <div className="card flex justify-content-center">
                          <Calendar
                            name="Date"
                            value={
                              formik.values.Date !== null &&
                              formik.values.Date !== ""
                                ? new Date(formik.values.Date)
                                : undefined
                            }
                            // onChange={(e) => setFilingDate(e.value)}
                            onChange={(e) => {
                              formik.setFieldValue("Date", e.value);
                              console.log("FillingDate", e.value);
                            }}
                            // className="custom-calendar"
                            placeholder="mm/dd/yyyy"
                            // dateFormat="mm-dd-yy"
                            className={classNames({
                              "p-invalid ": isFormFieldInvalid("State"),
                              "card-inputBox": true,
                            })}
                          />
                          {getFormErrorMessage("Date")}
                        </div>
                      </div>
                      <div className="d-block sm:d-flex sm:flex-column md:col-4 col-12 ">
                        <label className="inputLabel">
                          Bluesky Filing Expiration Date
                        </label>
                        <InputText
                          name="ExpirationDate"
                          // onChange={(e) => setExpire(e.target.value)}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "ExpirationDate",
                              e.target.value
                            );
                          }}
                          value={formik.values.ExpirationDate}
                          placeholder="mm/dd/yyyy"
                          disabled
                          className={classNames({
                            "p-invalid ": isFormFieldInvalid("State"),
                            "card-inputBox": true,
                          })}
                        />
                        {getFormErrorMessage("ExpirationDate")}
                      </div>
                      <div className="d-block sm:d-flex sm:flex-column md:col-4 col-12 ">
                        <label className="inputLabel">Status</label>
                        <Dropdown
                          name="Status"
                          value={formik.values.Status}
                          // onChange={(e) => {
                          //   setStatus(e.value);
                          // }}
                          onChange={(e) => {
                            formik.setFieldValue("Status", e.value);
                          }}
                          style={{ width: "100%" }}
                          options={objStatusType}
                          optionLabel="value"
                          optionValue="key"
                          placeholder="Select.."
                          // filter
                        />
                        {getFormErrorMessage("Status")}
                      </div>
                    </div>{" "}
                    <div className="formgrid grid col">
                      <div className="d-block sm:d-flex sm:flex-column md:col-4 col-12 ">
                        <label className="inputLabel">Invoice Date</label>

                        <div className="card flex justify-content-center">
                          <Calendar
                            name="invoiceDate"
                            value={
                              formik.values.invoiceDate !== null &&
                              formik.values.invoiceDate !== ""
                                ? new Date(formik.values.invoiceDate)
                                : undefined
                            }
                            // onChange={(e) => {
                            //   console.log("e.value", e.value);
                            //   setInvoiceDate(e.value);
                            // }}
                            onChange={(e) => {
                              formik.setFieldValue("invoiceDate", e.value);
                            }}
                            className="custom-calendar"
                            placeholder="mm/dd/yyyy"
                            // dateFormat="mm-dd-yy"
                          />
                          {getFormErrorMessage("invoiceDate")}
                        </div>
                      </div>
                      <div className="d-block sm:d-flex sm:flex-column md:col-4 col-12 ">
                        <label className="inputLabel">Amount ($)</label>
                        <InputText
                          name="Amount"
                          className={classNames("card-inputBox")}
                          onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, "");
                            setAmount(value);
                          }}
                          value={amount}
                          // placeholder="MenuName"
                        />
                      </div>
                      <div className="d-flex col-4">
                        <div className="flex align-items-center pr-2">
                          <span>Invoice Paid</span>
                        </div>
                        <div className="flex align-items-center pr-4">
                          <RadioButton
                            inputId="ingredient1"
                            name="pizza"
                            value={true}
                            onChange={onChange}
                            checked={selectedValue === true}
                          />

                          <label htmlFor="ingredient1" className="ml-2 mb-0">
                            Yes
                          </label>
                        </div>
                        <div className="flex align-items-center pr-4">
                          <RadioButton
                            inputId="ingredient1"
                            name="pizza"
                            value={false}
                            onChange={onChange}
                            checked={selectedValue === false}
                          />

                          <label htmlFor="ingredient1" className="ml-2 mb-0">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                    <Button
                      className="mr-4 btn-nav w-7rem justify-content-center"
                      onClick={resetForm}
                    >
                      Cancel
                    </Button>

                    <Button
                      className="btn-nav w-7rem justify-content-center"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        console.log("clicked");
                        formik.handleSubmit();
                      }}
                    >
                      {editClicked ? "Update" : "Save"}
                    </Button>
                  </div>
                </>{" "}
              </>
            ) : (
              <></>
            )}

            <div className="">
              <DataTableComponent
                valueData={tableData!}
                fieldsElements={dataTableElements}
                isPaginationAllowed={true}
                scrollable={true}
                className="gridcell deletebtn"
                columnResizeMode="expand"
                maxHeight={"calc(-210px + 100vh)"}
                isFullView={true}
                openDailog={openDailog}
                setOpenDailog={setOpenDailog}
              />
            </div>
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
