// import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
// import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { GetAdminMenuUsersDetailsThunk } from "../../../../store/features/admin/getadminmenuusersdetails/slice";
import { getAzureLoginHistory } from "../../../../store/features/getazureloginhistory/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { convertToLocalTime } from "../../../../utils/date-formatter";
import { exportExcel } from "../../../../utils/excel-dataformat";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
// import InvestorLoginHistorySkeleton from "./InvestorLoginHistorySkeleton";

export default function InvestorLoginHistory() {
  const dispatch: any = useAppDispatch();
  // const portState = useAppSelector(
  //   (state) => state.InvestorLoginHistory.loading
  // );
  // const InvestorLoginHist = useAppSelector(
  //   (state) => state.InvestorLoginHistory.data
  // );
  const AzureLoginHistory = useAppSelector(
    (state) => state.getAzureLoginHistory.data
  );
  const profileData = useAppSelector((state) => state.profile.data![0]);

  console.log("AzureLoginHistory", AzureLoginHistory);

  const dropdownModel = useAppSelector((state) => state.legalEntityList.data);
  // const getUserList = useAppSelector((state) => state.ManageUserList?.data);

  const [selectedUser, setSelectedUser] = useState<any>(-1);
  // const [days, setDays] = useState<any>({ name: "", code: "" });
  const [days, setDays] = useState<any>("30");
  // const [stateName, setName] = useState<any>(false);
  const [loadingUserDetails, setLoadingUserDetails] = useState(false);
  const [loadingAzureHistory, setLoadingAzureHistory] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [openDailog, setOpenDailog] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  // const [userNameId, setUserNameId] = useState();

  const legalEntityList: { code: number; name: string }[] = [];
  for (let i = 0; i < dropdownModel?.length; i++) {
    legalEntityList.push({
      code: dropdownModel![i].legalEntityID,
      name: dropdownModel![i].legalEntityName,
    });
  }
  const Days = [
    { name: "Last 30 Days", code: "30" },
    { name: "Last 60 Days", code: "60" },
    { name: "Last 90 Days", code: "90" },
  ];

  const dataTableElements2 = [
    {
      field: "firstName",
      header: "First Name",
    },
    {
      field: "lastName",
      header: "Last Name",
      //   body: formatDate,
      //   filterElement: dateFilterTemplate,
    },
    {
      field: "emailAddress",
      header: "Email",
      //   body: formatDate,
      //   filterElement: dateFilterTemplate,
    },
    {
      field: "lastLogin",
      header: "Login History",
      body: (rowData: any) => convertToLocalTime(rowData.lastLogin),
    },
  ];
  const exportExcelData = [
    {
      field: "firstName",
      header: "First Name",
    },
    {
      field: "lastName",
      header: "Last Name",
      //   body: formatDate,
      //   filterElement: dateFilterTemplate,
    },
    {
      field: "emailAddress",
      header: "Email",
      //   body: formatDate,
      //   filterElement: dateFilterTemplate,
    },
    {
      field: "lastLogin",
      header: "Login History",
      dataType: "timestamp",
    },
  ];

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  // const parameters = {
  //   legalEntityId: leagalentity,
  //   UserID: selectedUser!,
  //   Days: days! ? days! : 0,
  // };

  // let parameter = {
  //   LegalEntityId: 3,
  // };
  // const userparameters = {
  //   LegalEntityId: fundDropdown.fundid,
  // };

  useEffect(() => {
    setLoadingUserDetails(true);
    setSelectedUser(-1);
    setDays("30");

    dispatch(
      GetAdminMenuUsersDetailsThunk({ LegalEntityId: fundDropdown.fundid })
    )
      .then((res: any) => {
        const data = res.payload.data.investorAccounts;
        const newArray: { key: any; value: string }[] = [];

        if (profileData.userTypeId === 1) {
          data.map((r: any) => {
            return newArray.push({
              key: r.userId,
              value: `${r.firstName}  ${r.lastName}  ( ${r.email} )`,
            });
          });

          setTableData([{ key: -1, value: "All Investors" }, ...newArray]);
        } else {
          data.map((r: any) => {
            return newArray.push({
              key: r.userId,
              value: `${r.firstName}  ${r.lastName}  ( ${r.email} )`,
            });
          });
          setTableData(newArray);
        }
      })
      .catch((error: any) => {
        console.error("Error fetching user details:", error);
      })
      .finally(() => {
        setLoadingUserDetails(false);
      });
  }, [fundDropdown.fundid]);

  console.log("firstName", tableData);

  useEffect(() => {
    console.log("days, selectedUser", days, selectedUser);

    const param = {
      LegalEntityId: fundDropdown.fundid,
      Days: days ? +days : 30,
    };

    setLoadingAzureHistory(true); // Ensure loader is set to true before the async call

    const fetchData = async () => {
      try {
        const r = await dispatch(getAzureLoginHistory(param));
        setFilteredData(r.payload.data);
      } catch (error) {
        console.error("Error fetching Azure login history:", error);
      } finally {
        setLoadingAzureHistory(false); // Ensure loader is set to false after the async call completes
      }
    };

    fetchData();
  }, [fundDropdown, days]);

  useEffect(() => {
    if (selectedUser !== -1) {
      const filtered = AzureLoginHistory?.filter(
        (data: any) => data.userId === selectedUser
      );
      console.log("useEffect fundDropdown.fundid", filteredData);

      setFilteredData(filtered);
    } else {
      setFilteredData(AzureLoginHistory);
      console.log(
        "useEffect fundDropdown.fundid--1",
        filteredData,
        AzureLoginHistory
      );
    }
  }, [days, selectedUser]);

  useEffect(() => {
    setSelectedUser(-1);
  }, [days]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);
  const isLoading = loadingUserDetails || loadingAzureHistory;

  return (
    <>
      {isLoading && <Loader />}
      <div className="grid col-12 p-0">
        <div className="col-12 md:col-9  pl-1 p-0">
          <h1 className="pageHeader fs-2">Investor Login History Report</h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className=" col-12 md:col-3 flex justify-content-end">
          <Button
            className="remove-shadow"
            type="button"
            onClick={() =>
              exportExcel(
                exportExcelData,
                AzureLoginHistory || [],
                "Search Investor Employee Report",
                true
              )
            }
            data-pr-tooltip="exportExcel"
            disabled={
              AzureLoginHistory === undefined ||
              AzureLoginHistory?.length === 0 ||
              AzureLoginHistory === null
            }
          >
            {" "}
            <span className="blueButtonShadowText">Download </span>
          </Button>
          <div className="flex align-items-center gap-2 ml-2 mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                AzureLoginHistory === undefined ||
                AzureLoginHistory?.length === 0 ||
                AzureLoginHistory === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>

      <div className="card  tabWithoutboxshadow">
        <div className="row mm:col-6 wrap align-items-center py-0 col-12 ml-0 px-0">
          {/* <div className="formgrid grid px-2"> */}
          <div className="col-12 md:col-6 m-0 mb-md-0 ">
            {/* <label className="inputLabel">Investor Name </label> */}
            <div className="d-flex align-items-center">
              <Dropdown
                name="Select your fund *"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.value)}
                style={{ width: "100%" }}
                resetFilterOnHide={true}
                showFilterClear={true}
                options={tableData!}
                optionLabel="value"
                optionValue="key"
                placeholder="Investor Name"
                // disabled={days}
                filter
              />
            </div>
          </div>
          <div className="col-12 md:col-6 m-0 mb-md-0 ">
            {/* <label className="inputLabel">Date Range</label> */}
            <div className="d-flex align-items-center">
              {" "}
              <Dropdown
                name="Select your fund *"
                value={days}
                //   value={}
                onChange={(e) => {
                  setDays(e.value);
                }}
                style={{ width: "100%" }}
                options={Days}
                optionLabel="name"
                optionValue="code"
                placeholder="Date Range"
                // disabled={selectedUser}
                // filter
              />
            </div>
          </div>

          {/* </div> */}
        </div>
      </div>

      <DataTableComponent
        valueData={filteredData}
        fieldsElements={dataTableElements2}
        isPaginationAllowed={true}
        isDownload={true}
        scrollable={true}
        openDailog={openDailog}
        excelFieldsElements={exportExcelData}
        setOpenDailog={setOpenDailog}
        fileName="Investor Login History Report"
        maxHeight={"calc(-240px + 100vh)"}
        className="gridcell"
      />
      {/* </Card>
            </div>
          </div>
        </div> */}
    </>
  );
}
