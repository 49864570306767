import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Dropdown } from "primereact/dropdown";
import { Row } from "primereact/row";
// import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { GetProdductListThunk } from "../../../store/features/taxmanagement/getpartnerprodatabyinvestment/getproductlist/slice";
import { GetRevisionListThunk } from "../../../store/features/taxmanagement/getrevisionliist/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
import currencyFormat from "../../../utils/currency-formatter";
import { exportExcel } from "../../../utils/excel-dataformat";
import { convertExcelHeaders } from "../../../utils/helper";
import { GetPartnerProDataByInvestmentThunk } from "./../../../store/features/taxmanagement/getpartnerprodatabyinvestment/slice";

export default function InvestorProrataShareInvestment() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const previousYear: any = new Date().getFullYear();
  const currentYear = previousYear - 1;
  // const portState = useAppSelector((state) => state.GetMenuList.loading);
  const getRevision = useAppSelector((state) => state.GetRevisionList.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const productList = useAppSelector((state) => state.GetProdductList?.data);

  const list = useAppSelector(
    (state) => state.GetPartnerProDataByInvestment?.data
  );
  const title = useAppSelector(
    (state) => state.GetPartnerProDataByInvestment?.data1
  );
  const list2 = useAppSelector(
    (state) => state.GetPartnerProDataByInvestment?.data2
  );

  const [dataCreateElemnts, setDataCreateElemnts] = useState<any>();
  const [dataTableElements, setdataTableElements] = useState<any>();
  const [exportExcelHeader, setExportExcelHeader] = useState<any>([]);
  const [stateName, setName] = useState<any>(false);

  const datatableArrayObj: any = [];
  const [columnSums, setColumnSums] = useState<{ [key: string]: any }>({});
  const [loading, setloading] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  // const newData: any[] = []; // Declare an array to hold the new data
  const titleDatas: any[] = [];
  const footer = list2 ? JSON.parse(list2!) : [];

  const addedFotter = [
    {
      ChildName: "Investor's Pro Rata % (Product Level)",
      Amount: "100%",
      IsDistributed: false,
    },
  ];

  const amountsArray: any = [];

  for (const item of addedFotter) {
    amountsArray!.push(item);
  }

  const footerList = [...footer, ...amountsArray];

  const footerIndex = footerList.findIndex(
    (item: any) => item.ChildName === "Investor's Pro Rata % (Product Level)"
  );
  // If PartnerID and PartnerName are found, move them to indexes 0 and 1 respectively
  if (footerIndex !== -1) {
    const footerield = footerList.splice(footerIndex, 1)[0];
    footerList.splice(0, 0, footerield);
  }

  const prepareData = (data: any) => {
    if (data !== undefined && data !== "") {
      // console.log("prepareData===", data);
      const values = list ? JSON.parse(list!) : [];
      console.log("values", values);
      const items = title ? title!.split(",") : [];
      const keyValueArray = items.map((item) => {
        // const [key, value] =
        //   item.split("_")[1] !== "" ? [item, ""] : item.split("_");
        const [key, value] = [item, ""];
        return { key, value };
      });

      keyValueArray!.splice(
        0,
        0,
        {
          key: "Partner ID",
          value: "",
        },
        {
          key: "Investor Account",
          value: "",
        },
        {
          key: "Investor's Pro Rata % (Product Level)",
          value: "",
        }
      );
      const updatedColumnSums: any = {};
      updatedColumnSums["Investor's Pro Rata % (Product Level)"] = 0;

      for (const item of values) {
        for (const key in item) {
          if (key !== "LPPartnerID" && key !== "PartnerName") {
            if (!updatedColumnSums[key]) {
              updatedColumnSums[key] = 0;
            }
            updatedColumnSums[key] += item[key];
          }
        }
      }

      updatedColumnSums["Investor's Pro Rata % (Product Level)"] =
        updatedColumnSums.Value;

      // Delete the old key
      delete updatedColumnSums.Value;

      const keyValue = Object.entries(updatedColumnSums);

      // Now you can set the keyValueArray in state
      setColumnSums(Object.fromEntries(keyValue));

      for (let i = 0; i < values!.length; i++) {
        let count = 0;
        const obj: any = {};

        for (const key in values![i]) {
          obj[keyValueArray[count]?.key] =
            key === "LPPartnerID" || key === "PartnerName" || key === "Value"
              ? values![i][key]
              : currencyFormat(values![i][key]);
          count++;
        }
        datatableArrayObj.push(obj);
      }

      for (let i = 0; i < titleDatas!.length; i++) {
        let count = 0;
        const obj: any = {}; // Define 'obj' within the loop scope
        for (const key in titleDatas![i]) {
          obj[titleDatas[count]?.value] = titleDatas[i][key];

          count++;
        }
        datatableArrayObj.push(obj); // Push the object to the array
      }
      console.log("testing", datatableArrayObj);
      return datatableArrayObj;
    } else {
      return [];
    }
  };
  const createcolumns = (data: any) => {
    if (data !== undefined && data !== "" && data.length > 0) {
      const headerData: any = Object.keys(datatableArrayObj[0]).map(
        (key, id) => {
          return {
            field: key,
            header: key.split("_")[1] !== "" ? key : key.split("_")[0],
            style: { minWidth: "280px" },
          };
        }
      );
      console.log("headerData", headerData);

      // Find the index of "PartnerID" and "PartnerName" in the headerData array
      const partnerIDIndex = headerData.findIndex(
        (item: any) => item.field === "Partner ID"
      );
      const partnerNameIndex = headerData.findIndex(
        (item: any) => item.field === "Investor Account"
      );
      const partnerProRataIndex = headerData.findIndex(
        (item: any) => item.field === "Investor's Pro Rata % (Product Level)"
      );
      // If PartnerID and PartnerName are found, move them to indexes 0 and 1 respectively
      if (partnerIDIndex !== -1) {
        const partnerIDField = headerData.splice(partnerIDIndex, 1)[0];
        headerData.splice(0, 0, partnerIDField);
      }

      if (partnerNameIndex !== -1) {
        const partnerNameField = headerData.splice(partnerNameIndex, 1)[0];
        headerData.splice(1, 0, partnerNameField);
      }
      if (partnerProRataIndex !== -1) {
        const partnerProRataField = headerData.splice(
          partnerProRataIndex,
          1
        )[0];
        headerData.splice(2, 0, partnerProRataField);
      }

      // console.log("headerData", headerData);

      // Function to determine if a header is numeric
      const isSingleNumericHeader = (header: any) => {
        return /^\d+$/.test(header?.trim());
      };

      // Create a new array with updated headers
      const updatedColumns = headerData.map((column: any) => {
        if (isSingleNumericHeader(column.header)) {
          return {
            ...column,
            header: `${column.header} `,
          };
        }
        return column;
      });
      setExportExcelHeader(
        convertExcelHeaders(updatedColumns, "stringCurrency", [
          "Partner ID",
          "Investor Account",
          "Investor's Pro Rata % (Product Level)",
        ])
      );

      return headerData.map((item: any) => {
        if (item.field === "Investor Account") {
          return {
            ...item,
            style: {
              ...item.style,
              minWidth: "280px",
            },
            body: (rowData: any) => (
              <span className="left-align" title={rowData[item.field]}>
                {rowData[item.field]}
              </span>
            ),
          };
        }
        return item;
      });
    } else {
      return [];
    }
  };

  const formik = useFormik({
    initialValues: {
      Date: currentYear.toString(),
      Remark: "",
      revision: "",
      inv: "",
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please select year";
      }
      if (!data.revision) {
        errors.revision = "Please select Revision";
      }
      if (!data.inv) {
        errors.inv = "please select equity investment";
      }
      return errors;
    },
    onSubmit: async (data) => {
      // console.log("profileData.partnerID", profileData.partnerID);
      setloading(true);

      const params = {
        LegalEntityId: fundDropdown.fundid,
        TaxYear: formik.values.Date,
        InvestmentId: +formik.values.inv,
        RevisionId: +formik.values.revision,
      };
      //  const pdata = prepareData(datatableArrayObj)

      dispatch(GetPartnerProDataByInvestmentThunk(params))
        .then(() => {
          setloading(false);

          setName(false);
        })
        .catch(() => {
          setloading(false);
        });
    },
  });

  useEffect(() => {
    if (list) {
      setdataTableElements(prepareData(datatableArrayObj));
      setDataCreateElemnts(createcolumns(datatableArrayObj));
    }
  }, [list, title, list2]);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const subtractedChildData = footerList.map((childItem: any) => {
    const key = childItem.ChildName;

    const amountFromObject = columnSums[key] || 0;
    let amount = 0;
    typeof childItem.Amount === "string"
      ? (amount = childItem.Amount.replace(/%$/, ""))
      : (amount = childItem.Amount);
    const subtractedAmount = amountFromObject.toFixed(2) - amount;
    return key === "Investor's Pro Rata % (Product Level)"
      ? {
          ChildName: key,
          SubtractAmount: subtractedAmount,
        }
      : {
          ChildName: key,
          SubtractAmount: currencyFormat(subtractedAmount),
        };
  });

  const columnTesting = Object.keys(columnSums).map((test: any) => {
    console.log("tetst", test);
    const obj =
      test === "Investor's Pro Rata % (Product Level)"
        ? {
            key: test,
            amount: columnSums[test],
          }
        : {
            key: test,
            amount: currencyFormat(columnSums[test]),
          };
    return obj;
  });

  console.log("Total Calculated", Object.keys(columnSums));
  console.log("Total Calculated columnSums", columnSums);
  console.log("footer 2 footerList", footerList);
  console.log("footer subtractedChildData", subtractedChildData);
  console.log("footer", footer, columnTesting);

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total Calculated"
          colSpan={2}
          // footerStyle={{ textAlign: "right" }}
        />
        {/* {Object.keys(columnSums).map((key, index) => (
          <Column
            key={index}
            footer={
              columnSums[key] !== undefined ? columnSums[key].toFixed(2) : ""
            }
          />
        ))} */}
        {columnTesting.map((i) => (
          <Column
            key={i.key}
            footer={i.amount !== undefined ? i.amount : ""}
          ></Column>
        ))}
      </Row>
      <Row>
        <Column
          footer="Total Reported from K-1 Form  "
          colSpan={2}
          // footerStyle={{ textAlign: "right" }}
        />
        {footerList.map((i: any) => {
          return (
            <Column
              key={i.ChildName}
              footer={
                i.ChildName === "Investor's Pro Rata % (Product Level)"
                  ? i.Amount
                  : currencyFormat(i.Amount)
              }
            />
          );
        })}
      </Row>
      <Row>
        <Column
          footer="Delta"
          colSpan={2}
          // footerStyle={{ textAlign: "right" }}
        />
        {subtractedChildData.map((i: any) =>
          i && typeof i.SubtractAmount !== "undefined" ? (
            <Column key={i.ChildName} footer={i.SubtractAmount!} />
          ) : (
            ""
          )
        )}
      </Row>
    </ColumnGroup>
  );

  useEffect(() => {
    if (fundDropdown.fundid) {
      const p = {
        LegalEntityId: fundDropdown.fundid,
      };
      dispatch(GetProdductListThunk(p));
    }
    formik.resetForm();
    setdataTableElements([]);
  }, [fundDropdown.fundid]);

  useEffect(() => {
    setName(true);
  }, [formik.values.Date, +formik.values.inv, +formik.values.revision]);

  useEffect(() => {
    setName(true);
    formik.setFieldValue("revision", "");
    formik.setFieldValue("inv", "");
  }, [formik.values.Date]);
  useEffect(() => {
    formik.setFieldValue("revision", "");
  }, [formik.values.inv]);
  useEffect(() => {
    const param = {
      LegalEntityId: fundDropdown.fundid,
      TaxYear: formik.values.Date,
      InvestmentId: +formik.values.inv,
    };
    // const p = {
    //   LegalEntityId: fundDropdown.fundid,
    // };
    // if (formik.values.Date) {
    //   dispatch(GetProdductListThunk(p));
    // }
    if (formik.values.inv && formik.values.Date) {
      dispatch(GetRevisionListThunk(param));
    }
  }, [formik.values.inv, formik.values.Date]);

  useEffect(() => {
    formik.values.revision && formik.submitForm();
  }, [formik.values.revision]);

  const handleChange = (name: string, value: any) => {
    setdataTableElements([]);
    formik.setFieldValue(name, value);
  };
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}
        <div className="flex justify-content-between col-12">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">
              K-1: Investor&apos;s pro-rata share by investment
            </h1>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  exportExcelHeader,
                  dataTableElements,
                  "K-1: Investor's pro-rata share by investment",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                dataTableElements === undefined ||
                dataTableElements?.length === 0 ||
                dataTableElements === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={dataTableElements?.length === 0}
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            <>
              {" "}
              <div className="formgrid grid col m-0 p-0">
                <div className="d-block sm:d-flex sm:flex-column mt-2 md:col-2 col-12">
                  <label className="inputLabel">Year *</label>
                  <div className="card flex justify-content-center">
                    <Calendar
                      name="Date"
                      value={
                        formik.values.Date === null || formik.values.Date === ""
                          ? undefined
                          : new Date(+formik.values.Date, 0, 1)
                      }
                      onChange={(e) => {
                        const selectedYear = new Date(
                          Number(e.value)
                        ).getFullYear();
                        // formik.setFieldValue("Date", selectedYear);
                        handleChange("Date", selectedYear);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("Date"),
                        "card-inputBox": true,
                      })}
                      placeholder="Select year"
                      view="year"
                      dateFormat="yy"
                    />
                    {getFormErrorMessage("Date")}
                  </div>
                </div>
                <div className="d-block sm:d-flex sm:flex-column mt-2 md:col-6 col-12">
                  <label className="inputLabel">Equity Investment *</label>
                  <Dropdown
                    name="inv"
                    style={{ width: "100%" }}
                    options={productList}
                    optionLabel="value"
                    optionValue="key"
                    placeholder="Select Equity Investment*"
                    onChange={(e) => handleChange("inv", e.value)}
                    value={formik.values.inv}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("inv"),
                      "card-inputBox": true,
                    })}
                  />
                  {getFormErrorMessage("inv")}
                </div>
                <div className="d-block sm:d-flex sm:flex-column mt-2 md:col-3 col-12">
                  <label className="inputLabel">Revision*</label>
                  <Dropdown
                    name="revision"
                    style={{ width: "100%" }}
                    options={getRevision}
                    optionLabel="value"
                    optionValue="key"
                    placeholder="Select Revision*"
                    onChange={(e) => handleChange("revision", e.value)}
                    value={formik.values.revision}
                    className={classNames({
                      "p-invalid": isFormFieldInvalid("revision"),
                      "card-inputBox": true,
                    })}
                  />
                  {getFormErrorMessage("revision")}
                </div>
                <div className="d-flex justify-content-center align-items-center mt-2 col-12 md:col-1">
                  <Button
                    className="btn-nav w-7rem justify-content-center"
                    onClick={(e) => {
                      e.preventDefault();
                      // setIsFormSubmitted(true);
                      // console.log("clicked");
                      formik.resetForm();
                      setdataTableElements([]);
                    }}
                  >
                    Reset
                  </Button>

                  {/* <Button
                    className="btn-nav w-7rem justify-content-center"
                    onClick={(e) => {
                      e.preventDefault();
                      // setIsFormSubmitted(true);
                      // console.log("clicked");
                      formik.handleSubmit();
                    }}
                  >
                    Show
                  </Button> */}
                </div>
              </div>
            </>{" "}
            <div className="">
              {dataTableElements && !stateName && (
                <DataTableComponent
                  valueData={dataTableElements}
                  fieldsElements={dataCreateElemnts}
                  footerElement={dataTableElements.length > 0 && footerGroup}
                  // isDownload={dataTableElements.length > 0 && true}
                  isPaginationAllowed={true}
                  fileName="PartnerLevelProRataByProduct"
                  scrollable={true}
                  className="gridcell"
                  columnResizeMode="expand"
                  maxHeight={"calc(-265px + 100vh)"}
                  isFullView={true}
                  setOpenDailog={setOpenDailog}
                  openDailog={openDailog}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
