// import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
// import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";
// import { RiCheckFill } from "react-icons/ri";
import { RiAddFill } from "react-icons/ri";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { goToTop } from "../../../components/scroll-to/ScrollTo";
import { GetMenuListThunk } from "../../../store/features/master/getmenulist/slice";
import { GetParentMenuListThunk } from "../../../store/features/master/getparentmenulist/slice";
import { SaveMenuDetailsThunk } from "../../../store/features/master/savemenudetails/slice";
import { UpdateMenuDetailsThunk } from "../../../store/features/master/updatemenudetails/slice";
import { DisableEnableMenuLinkThunk } from "../../../store/features/sponser/manage-help-videos/disableenablemenulink/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function ManageMenuMaster() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  // const profileData = useAppSelector((state) => state.profile.data![0]);

  const GetMenuList = useAppSelector((state) => state.GetMenuList?.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const getParentDropdown = useAppSelector(
    (state) => state.GetParentMenuList?.data
  );

  const [id, setId] = useState<any>("");
  const [add, setAdd] = useState(false);
  const [showTab, setShowTab] = useState(true);
  const [edit, setEdit] = useState(false);
  const [loading, setloading] = useState(false);

  const [visible, setVisible] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [activeFlag, setActiveFlag] = useState<any>(0);
  const [openDailog, setOpenDailog] = useState(false);

  const formik = useFormik({
    initialValues: {
      parentId: undefined,
      menuName: "",
      menuURL: "",
      redirectionURL: false,
    },
    validate: (data: any) => {
      const errors: any = {};
      if (!data.parentId) {
        errors.parentId = "Parent menu is required";
      }
      if (!data.menuName) {
        errors.menuName = "Menu name is required";
      }
      if (!data.menuURL) {
        errors.menuURL = "Menu URL is required";
      }
      return errors;
    },
    onSubmit: () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onSave();
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = () => {
      formik.resetForm();
      setEditClicked(true);
      formik.setFieldValue("parentId", rowData.parentId);
      formik.setFieldValue("menuName", rowData.menuName);
      formik.setFieldValue("menuURL", rowData.menuURL);
      formik.setFieldValue("redirectionURL", rowData.redirectionURL);
      setId(rowData.id);
      setAdd(false);
      setEdit(true);
      setShowTab(false);

      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: smooth scrolling animation
      });
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={handleEdit}
          >
            <span className="text-white">Edit</span>
          </Button>
        </div>
      </div>
    );
  };

  const deleteButton = (rowData: any) => {
    const handleEnable = () => {
      // setParentMenu(rowData.id);
      setId(rowData.id);
      if (!rowData.active) {
        setActiveFlag(false);
      } else {
        setActiveFlag(true);
      }
      setVisible(true);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={handleEnable}
          >
            {!rowData.active ? (
              <span className="text-white">Enable</span>
            ) : (
              <span className="text-white">Disable</span>
            )}
          </Button>
        </div>
      </div>
    );
  };

  const onDelete = () => {
    setVisible(false);
    setloading(true);
    const disableParam = {
      Id: id,
      Type: !activeFlag ? "E" : "D",
    };
    dispatch(DisableEnableMenuLinkThunk(disableParam))
      .then(() => {
        dispatch(GetMenuListThunk()).then(() => {
          successToastMessage(
            toast,
            "Your request has been submitted to the fund manager and they will reach out to you with next steps"
          );
        });
      })
      .finally(() => {
        setloading(false);
      });
  };

  const resetForm = () => {
    formik.resetForm();
    setEditClicked(false);
    setAdd(false);
    setEdit(false);
    setShowTab(true);
  };

  const onAddClick = () => {
    resetForm();
    setAdd(true);
    setEdit(false);
    setShowTab(true);
  };

  const dataTableElements = [
    {
      field: "menuName",
      header: "Menu Name",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.menuName}>
          {rowData?.menuName}
        </div>
      ),
    },
    {
      field: "menuURL",
      header: "Menu URL",
      style: { minWidth: "250px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.menuURL}>
          {rowData?.menuURL}
        </div>
      ),
    },

    {
      // field: "targetReturn",
      header: "",
      style: { maxWidth: "180px", minWidth: "180px" },
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
    },
    {
      // field: "",
      header: "",
      style: { maxWidth: "180px", minWidth: "180px" },
      body: (rowData: any) => deleteButton(rowData),
    },
  ];

  useEffect(() => {
    setloading(true);
    dispatch(GetParentMenuListThunk()).finally(() => {
      setloading(false);
    });
  }, []);

  useEffect(() => {
    if (fundDropdown?.fundid) {
      setloading(true);
      dispatch(GetMenuListThunk()).finally(() => {
        setloading(false);
      });
      formik.resetForm();
      setAdd(false);
      setEdit(false);
      setShowTab(true);
    }
  }, [fundDropdown?.fundid]);

  const onSave = () => {
    setloading(true);

    if (editClicked) {
      const params = {
        id,
        parentId: formik.values.parentId,
        menuName: formik.values.menuName,
        menuURL: formik.values.menuURL,
        redirectionURL: formik.values.redirectionURL ? 1 : 0,
        // divider: check ? 1 : 0,
      };
      dispatch(UpdateMenuDetailsThunk(params))
        .then((res: any) => {
          res.payload.data[0][0].return_Value === "Success"
            ? successToastMessage(toast, "Data has been saved successfully")
            : errorToastMessage(toast, "Data has been saved successfully");
          setAdd(false);
          setEdit(false);
          setShowTab(true);
          resetForm();
          setEditClicked(false);
          dispatch(GetMenuListThunk());
        })
        .finally(() => {
          setloading(false);
        });
      dispatch(GetParentMenuListThunk());
    } else {
      const parameters = {
        parentId: formik.values.parentId,
        menuName: formik.values.menuName,
        menuURL: formik.values.menuURL,
        redirectionURL: formik.values.redirectionURL ? 1 : 0,
        // divider: check ? 1 : 0,
      };

      dispatch(SaveMenuDetailsThunk(parameters))
        .then(() => {
          dispatch(GetMenuListThunk());
        })
        .finally(() => {
          setloading(false);
        });
      dispatch(GetParentMenuListThunk());
      dispatch(GetMenuListThunk());
      setAdd(false);
      setEdit(false);
      setShowTab(true);
      setEditClicked(false);
      resetForm();
    }
  };

  // useEffect(() => {
  //   setExitDate(getExitDateConversion(GetMenuList));
  // }, [GetMenuList]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  useEffect(() => {
    if (add || edit) {
      document.body.classList.remove("no-scroll");
    } else {
      goToTop();
      document.body.classList.add("no-scroll");
    }
  }, [add, edit]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };
  return (
    <>
      {loading && <Loader />}
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Manage Menu Master</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                GetMenuList === undefined ||
                GetMenuList?.length === 0 ||
                GetMenuList === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          {!activeFlag ? (
            <h4 className="pageHeader text-center mb-5 pt-5">Are you sure ?</h4>
          ) : (
            <h4 className="pageHeader text-center mb-5 pt-5">Are you sure ?</h4>
          )}

          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={onDelete}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <div className="grid p-1 justify-content-start">
              {" "}
              {!add ||
                (edit && <div className="tabHeader-wrap mb-3 col-6"></div>)}
              {!add && !edit && (
                <Button
                  onClick={onAddClick}
                  className={
                    showTab
                      ? "btn-navActive mr-0 m-2"
                      : "btn-navActive mr-0 m-2"
                  }
                >
                  <span className="ml-auto mr-auto ">
                    {" "}
                    Add{" "}
                    <span className="pl-2">
                      <RiAddFill />
                    </span>
                  </span>
                </Button>
              )}
              {add && (
                <div className="tabHeader-wrap col-6 pl-2 ml-1">
                  <Button className="tabHeadText  Active"> Add</Button>
                </div>
              )}
              {edit && (
                <div className="tabHeader-wrap col-6 pl-2 ml-1">
                  <Button className="tabHeadText  Active"> Edit</Button>
                </div>
              )}
            </div>
            {add || edit ? (
              <>
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-3">
                    <label className="inputLabel">Parent Menu *</label>
                    <Dropdown
                      name="parentId"
                      value={formik.values.parentId}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      style={{ width: "100%" }}
                      options={getParentDropdown}
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select Parent Menu"
                      // filter
                    />
                    {getFormErrorMessage("parentId")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-3">
                    <label className="inputLabel">Menu Name*</label>
                    <InputText
                      name="menuName"
                      className={classNames("card-inputBox")}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={formik.values.menuName}
                      placeholder="Menu Name"
                    />
                    {getFormErrorMessage("menuName")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-3">
                    <label className="inputLabel">Menu URL*</label>
                    <InputText
                      name="menuURL"
                      className={classNames("card-inputBox")}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={formik.values.menuURL}
                      placeholder="Menu URL"
                    />
                    {getFormErrorMessage("menuURL")}
                  </div>

                  <div className="formgrid grid px-2 mt-2">
                    <div className="d-flex">
                      <div className="card flex flex-row align-items-center">
                        <label className="container-checkbox ">
                          <input
                            type="checkbox"
                            name="redirectionURL"
                            checked={formik.values.redirectionURL}
                            onChange={() =>
                              formik.setFieldValue(
                                "redirectionURL",
                                !formik.values.redirectionURL
                              )
                            }
                          />
                          <span className="checkmark"></span>
                          <span className="mt-1"> Redirection URL</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-2 justify-content-center">
                  <Button
                    className="mr-4 btn-nav w-7rem justify-content-center"
                    onClick={() => {
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    className="btn-nav w-7rem justify-content-center"
                    onClick={() => formik.handleSubmit()}
                  >
                    {editClicked ? "Update" : "Save"}
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="mt-2">
              <DataTableComponent
                valueData={GetMenuList || []}
                fieldsElements={dataTableElements}
                isPaginationAllowed={true}
                className="gridcell deletebtn"
                columnResizeMode="expand"
                scrollable={true}
                isFullView={true}
                setOpenDailog={setOpenDailog}
                openDailog={openDailog}
                maxHeight={"calc(-215px + 100vh)"}
              />
            </div>
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
