import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";

import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import { useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  convertUTCToLocalDate,
  getListDateConversion,
} from "../../../../utils/date-formatter";

interface Iprops {
  pageName: string;
  openDailog: any;
  setOpenDailog: any;
}
const AllTransaction = (props: Iprops) => {
  console.log("pageName", props);
  const [allTransactionData, setAllTransactionData] = useState<any>();

  console.log(setAllTransactionData);
  console.log(allTransactionData);

  const reportData = useAppSelector(
    (state: any) => state.getManageTransaction.data
  );

  useEffect(() => {
    // if (reportData) {
    setAllTransactionData(
      getListDateConversion(reportData, ["transactionDate"])
    );
    // }
  }, [reportData]);

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const dataTableElement = [
    {
      field: "transactionId",
      header: "Transaction Id",
      style: { minWidth: "160px" },
    },
    {
      field: "investorAccount",
      header: "Investor Account",
      style: { minWidth: "260px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.investorAccount}>
          {rowData?.investorAccount}
        </div>
      ),
    },
    {
      field: "transactionDate",
      body: (rowData: any) => convertUTCToLocalDate(rowData.transactionDate),
      header: "Transaction Date",
      dataType: "date",
      style: { minWidth: "190px" },
      filterElement: dateFilterTemplate,
    },
    {
      field: "transactionType",
      header: "Transaction Type",
      style: { minWidth: "190px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.transactionType}>
          {rowData?.transactionType}
        </div>
      ),
    },
    {
      field: "distributionType",
      header: "Distribution Type",
      style: { minWidth: "190px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.distributionType}>
          {rowData?.distributionType}
        </div>
      ),
    },
    {
      field: "investmentName",
      header: "Investment Name",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.investmentName}>
          {rowData?.investmentName}
        </div>
      ),
    },
    {
      field: "creditAmount",
      header: "Credit Amount",
      body: (rowData: any) => currencyFormat(rowData.creditAmount),
      style: { minWidth: "190px" },
    },
    {
      field: "debitAmount",
      header: "Debit Amount",
      body: (rowData: any) => currencyFormat(rowData.debitAmount),
      style: { minWidth: "180px" },
    },
    {
      field: "notes",
      header: "Notes",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.notes}>
          {rowData?.notes}
        </div>
      ),
    },
  ];

  return (
    <div className="grid">
      <div className="col-12">
        {/* <Card> */}
        <DataTableComponent
          valueData={
            allTransactionData !== undefined && allTransactionData.length > 0
              ? allTransactionData
              : []
          }
          scrollable={true}
          maxHeight={"calc(-215px + 100vh)"}
          className="gridcell"
          fieldsElements={dataTableElement}
          isPaginationAllowed={true}
          columnResizeMode="expand"
          openDailog={props.openDailog}
          setOpenDailog={props.setOpenDailog}
          // isDownload={true}
          // scrollable={true}
        />
        {/* </Card> */}
      </div>
    </div>
  );
};

export default AllTransaction;
