// import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import RegisterDropdownComponent from "../../../../components/dashboard-dropdown/RegisterDropdown";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getCumulativeEarningReport } from "../../../../store/features/reports/cumulative-earning/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import { exportExcel } from "../../../../utils/excel-dataformat";

// import dateFormatter from "../../../../utils/date-formatter";
// import CumulativeEarningSkeleton from "./CumulativeEarningSkeleton";

export default function CumulativeEarning() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const portState = useAppSelector((state) => state.navbardropdown.loading);
  const cumulativeearning = useAppSelector(
    (state) => state.CumulativeEarning.data
  );
  const [openDailog, setOpenDailog] = useState(false);

  // const [exitDate, setExitDate] = useState<any>();

  // const getExitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       firstInvInStateFundingState: new Date(d.firstInvInStateFundingState),
  //       filingDate: d.filingDate ? new Date(d.filingDate) : d.filingDate,
  //       expiryDate: d.expiryDate ? new Date(d.expiryDate) : d.expiryDate,
  //       invDate: d.invDate ? new Date(d.invDate) : d.invDate,
  //     };
  //     return modifiedExitDate;
  //   });
  // };
  //   const formatDate = (rowData: any) => {
  //     return dateFormatter(rowData.firstInvInStateFundingState);
  //   };

  //   const formatFilingDate = (rowData: any) => {
  //     return dateFormatter(rowData.filingDate);
  //   };

  //   const formatExpiryDate = (rowData: any) => {
  //     return dateFormatter(rowData.expiryDate);
  //   };
  //   const formatInvDate = (rowData: any) => {
  //     return dateFormatter(rowData.invDate);
  //   };

  //   const dateFilterTemplate = (options: any) => {
  //     return (
  //       <Calendar
  //         value={options.value}
  //         onChange={(e) => options.filterCallback(e.value, options.index)}
  //         dateFormat="mm/dd/yy"
  //         placeholder="mm/dd/yyyy"
  //         mask="99/99/9999"
  //       />
  //     );
  //   };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const formatCurrency = (rowData: any) => {
    const finalValue = Number(rowData.cumulative);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const formatCurrency2 = (rowData: any) => {
    const finalValue = Number(rowData.previousMonth);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const excelTableHeaders = [
    {
      field: "productID",
      header: "Product ID",
    },
    {
      field: "supplierProductCode",
      header: "Lookup Code",
    },
    {
      field: "productName",
      header: "Investment Name",
    },
    {
      field: "productAddress",
      header: "Address",
    },
    {
      field: "cumulative",
      header: "Cumulative Earnings",
      dataType: "currency",
    },
    {
      field: "previousMonth",
      header: "Previous Month Earning",
      dataType: "currency",
    },
    {
      field: "investorName",
      header: "Investor Name",
      dataType: "string",
    },
  ];
  const dataTableElements = [
    {
      field: "supplierProductCode",
      header: "Lookup Code",
      style: { minWidth: "210px" },
    },
    {
      field: "productName",
      header: "Investment Name",
      style: { minWidth: "270px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productName}>
          {rowData?.productName}
        </div>
      ),

      //   body: formatDate,
      //   filterElement: dateFilterTemplate,
    },
    {
      field: "productAddress",
      header: "Address",
      style: { minWidth: "210px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.productAddress}>
          {rowData?.productAddress}
        </div>
      ),
    },
    {
      field: "cumulative",
      header: "Cumulative Earnings",
      body: formatCurrency,
      style: { minWidth: "210px" },
    },
    {
      field: "previousMonth",
      header: "Previous Month Earning",
      body: formatCurrency2,
      style: { minWidth: "210px" },

      //   filterElement: dateFilterTemplate,
    },
  ];

  const dropdown = useAppSelector((state) => state.navbardropdown.data);

  useEffect(() => {
    if (
      dropdown.fundid !== undefined &&
      dropdown.fundid > 0 &&
      dropdown.registerid !== undefined &&
      dropdown.registerid > 0
    ) {
      setLoading(true);
      const parameters = {
        legalEntityId: dropdown.fundid,
        LPPartnerID: dropdown.registerid,
      };
      dispatch(getCumulativeEarningReport(parameters)).finally(() => {
        setLoading(false);
      });
    }
  }, [dropdown.fundid, dropdown.registerid]);
  // useEffect(() => {
  //   setExitDate(getExitDateConversion(cumulativeearning));
  // }, [cumulativeearning]);

  return (
    <>
      {portState === "loading" || loading ? <Loader /> : <></>}
      <div className="flex justify-content-between col-12 ">
        <div className="card pgHeaderWrap">
          <h1 className="pageHeader fs-2">Cumulative Earning Report</h1>
          {/* <p className="pageSubHeader"> </p> */}
        </div>

        <div className=" col-12 md:col-3 flex gap-2 justify-content-end">
          <Button
            className="remove-shadow"
            type="button"
            // icon="pi pi-file"
            // rounded
            onClick={() =>
              exportExcel(
                excelTableHeaders,
                cumulativeearning || [],
                "Cumulative Earning Report",
                true
              )
            }
            data-pr-tooltip="exportExcel"
            disabled={
              cumulativeearning === undefined ||
              cumulativeearning?.length === 0 ||
              cumulativeearning === null
            }
          >
            {" "}
            <span className="blueButtonShadowText">Download </span>
          </Button>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                cumulativeearning === undefined ||
                cumulativeearning?.length === 0 ||
                cumulativeearning === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className=" card flex flex-column">
        <div className="">
          {/* <div> */}
          <RegisterDropdownComponent showRegisteredInvestorDropdown={false} />
          {/* </div> */}
        </div>
        <DataTableComponent
          valueData={cumulativeearning || []}
          fieldsElements={dataTableElements}
          isPaginationAllowed={true}
          // isDownload={true}
          fileName="Cumulative Earning Report"
          scrollable={true}
          className="gridcell"
          columnResizeMode="expand"
          maxHeight={"calc(-250px + 100vh)"}
          isFullView={true}
          setOpenDailog={setOpenDailog}
          openDailog={openDailog}
        />
      </div>
    </>
  );
}
