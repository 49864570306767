import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Investments.scss";

import InvestmentFilter from "../../../../components/investment-filter/InvestmentFilter";
import Loader from "../../../../components/loader/Loader";
import { resetInitialInvestmentState } from "../../../../store/features/investment/create/slice";
import { getThunkInvestment } from "../../../../store/features/investment/list/slice";
import { setPageName } from "../../../../store/features/investment/update/update-page/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import ViewInvestmentDetails from "../update/ViewInvestmentDetails";
import Deals from "./deals/Deals";

// let preventFirstTimeRunInvestmentPage = true;

export default function InvestmentsPage() {
  const [filterReset, setFilterReset] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log("id", id);
  console.log("Called multiple time");
  const stateInvestment = useAppSelector((state) => state.addInvestment);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const pageUpdateName = useAppSelector((state) => state.updatePage.data);
  const PreventFirstTimeRun = useRef(true);

  const [productFilterchips, setProductFilterchipsFilterchips] =
    useState<any>();
  // eslint-disable-next-line unicorn/no-null
  const [supplierchips, setsupplierchips] = useState<any>(null);
  // eslint-disable-next-line unicorn/no-null
  const [citychips, setcitychips] = useState<any>(null);
  // eslint-disable-next-line unicorn/no-null
  const [stateCodechips, setstateCodechips] = useState<any>(null);
  // eslint-disable-next-line unicorn/no-null
  const [lookUpCodechips, setlookUpCodechips] = useState<any>(null);
  // eslint-disable-next-line unicorn/no-null
  const [countryChips, setCountryChips] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [page, setpage] = useState(1);
  // eslint-disable-next-line unicorn/no-null
  const [supplierId, setsupplierId] = useState<any>(null);
  // eslint-disable-next-line unicorn/no-null
  const [city, setcity] = useState<any>(null);
  // eslint-disable-next-line unicorn/no-null
  const [stateCode, setstateCode] = useState<any>(null);
  // eslint-disable-next-line unicorn/no-null
  const [lookUpCode, setlookUpCode] = useState<any>(null);
  // eslint-disable-next-line unicorn/no-null
  const [productId, setproductId] = useState<any>(null);
  // eslint-disable-next-line unicorn/no-null
  const [countryId, setCountryId] = useState<any>(null);

  const updatePageNameClick = (name: any) => {
    // eslint-disable-next-line unicorn/no-null
    setproductId(null);
    // eslint-disable-next-line unicorn/no-null
    setProductFilterchipsFilterchips(null);

    // eslint-disable-next-line unicorn/no-null
    setsupplierId(null);
    // eslint-disable-next-line unicorn/no-null
    setsupplierchips(null);

    // eslint-disable-next-line unicorn/no-null
    setcity(null);
    // eslint-disable-next-line unicorn/no-null
    setcitychips(null);

    // eslint-disable-next-line unicorn/no-null
    setlookUpCode(null);
    // eslint-disable-next-line unicorn/no-null
    setlookUpCodechips(null);
    // eslint-disable-next-line unicorn/no-null
    setCountryChips(null);
    // eslint-disable-next-line unicorn/no-null
    setCountryId(null);
    // eslint-disable-next-line unicorn/no-null
    setstateCode(null);
    // eslint-disable-next-line unicorn/no-null
    setstateCodechips(null);

    dispatch(resetInitialInvestmentState());

    console.log("s", name);
    if (name === "SoftCommit" || name === "PreFunded") {
      // setFilter(false);
      console.log("inside");
      // formik.resetForm();
      setFilterReset(true);
    }
    if (
      pageUpdateName.subPageName === undefined ||
      pageUpdateName.subPageName === "List" ||
      pageUpdateName.subPageName === ""
    ) {
      console.log("hi", pageUpdateName);
      const data = {
        offering: name,
        isReloadCardPage: true,
      };
      dispatch(setPageName(data));
      navigate("/ManageOffering");
    } else {
      dispatch(
        setPageName({
          ...pageUpdateName,
          clickPageName: name,
          openDialog: true,
        })
      );
    }
  };

  const Serchfilters = () => {
    const parameter = {
      AvailablePageNo: 1,
      pageSize: 30,
      legalEntityId: fundDropdown.fundid,
      productState: pageUpdateName.offering,
      supplierId,
      city,
      stateCode,
      lookUpCode,
      productId,
      CountryId: countryId || undefined,
      IspooledFunds: false,
    };
    const updateBoday = {
      ...parameter,
      AvailablePageNo: page,
    };
    console.log("getThunkInvestment called");
    dispatch(getThunkInvestment(updateBoday));
  };

  useEffect(() => {
    if (PreventFirstTimeRun.current === false) {
      Serchfilters();
    }
    PreventFirstTimeRun.current = false;
  }, [supplierchips, citychips, lookUpCodechips, stateCodechips, countryChips]);

  useEffect(() => {
    console.log("stateInvestment", stateInvestment);
    if (
      stateInvestment.data?.productState !== null &&
      stateInvestment.data?.productState !== "" &&
      stateInvestment.data?.productState !== "Exited"
    ) {
      const data = {
        offering: stateInvestment.data?.productState!,
        pageName: "OfferingInfo",
      };
      dispatch(setPageName(data));
      // setOffering(stateInvestment.data?.productState!);
    } else {
      if (pageUpdateName.createPage === false) {
        const data =
          id !== undefined
            ? {
                offering: "SoftCommit",
                pageName: "OfferingInfo",
              }
            : {
                offering: "SoftCommit",
              };

        dispatch(setPageName(data));
      } else {
        dispatch(setPageName({ ...pageUpdateName, createPage: false }));
      }
    }
  }, [stateInvestment]);

  useEffect(() => {
    console.log("test------------------", id);
    if (id === undefined) {
      updatePageNameClick(
        pageUpdateName.offering ? pageUpdateName.offering : "SoftCommit"
      );
    }
  }, [fundDropdown]);
  useEffect(() => {
    if (
      fundDropdown.fundid !== undefined &&
      fundDropdown.fundid > 0 &&
      pageUpdateName.offering !== "" &&
      pageUpdateName.isReloadCardPage !== undefined
    ) {
      setLoading(true);
      const parameter = {
        AvailablePageNo: 1,
        pageSize: 30,
        legalEntityId: fundDropdown.fundid,
        productState: pageUpdateName.offering,
        supplierId,
        city,
        stateCode,
        lookUpCode,
        productId,
        CountryId: countryId || undefined,
        IspooledFunds: false,
      };
      const updateBoday = {
        ...parameter,
        AvailablePageNo: page,
      };

      dispatch(getThunkInvestment(updateBoday)).finally(() => {
        setLoading(false);
      });
    }
    // pageCall();
  }, [
    page,
    fundDropdown.fundid,
    pageUpdateName.offering,
    pageUpdateName.isReloadCardPage,
    productId,
  ]);

  useEffect(() => {
    setpage(1);
  }, [fundDropdown, pageUpdateName.offering]);

  return (
    <>
      {loading === true ? <Loader /> : <></>}
      <div className="grid">
        <div className="col-12 md:col-6">
          <div className="card pgHeaderWrap md:my-2">
            <h1 className="pageHeader">Manage Offerings </h1>
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div></div>
        </div>
      </div>
      <div>
        <div className="flex flex-row flex-wrap p-0 sm:flex-none col-12 pr-2">
          <div className="d-flex flex-column pr-1 p-0 mt-3 xl:col-1 md:col-1 sm:col-1 ">
            Show:
          </div>
          <div className="d-flex flex-column pl-0 p-2 xl:col-2 md:col-2 sm:col-2">
            <Button
              className={
                pageUpdateName.offering === "SoftCommit"
                  ? "btn-navActive"
                  : "btn-nav "
              }
              onClick={(e) => {
                e.preventDefault();
                updatePageNameClick("SoftCommit");
                // pageCall();
                // navigate("/investments");
              }}
            >
              <span className="ml-auto mr-auto ">Deals</span>
            </Button>
          </div>
          <div className="d-flex flex-column p-2 xl:col-2 md:col-3 sm:col-3">
            <Button
              className={
                pageUpdateName.offering === "PreFunded"
                  ? "btn-navActive  "
                  : "btn-nav "
              }
              onClick={(e) => {
                e.preventDefault();
                updatePageNameClick("PreFunded");
                // pageCall();
                // navigate("/investments");
              }}
            >
              <span className="ml-auto mr-auto ">Investments</span>
            </Button>
          </div>
          {id === undefined ? (
            <>
              <div className="d-flex flex-column pr-0 p-2 col-12 xl:col-7 md:col-6 sm:col-6  flex justify-content-center">
                <InvestmentFilter
                  listPage={true}
                  filterReset={filterReset}
                  setFilterReset={setFilterReset}
                  setsupplierId={setsupplierId}
                  Serchfilters={Serchfilters}
                  setcity={setcity}
                  setstateCode={setstateCode}
                  setlookUpCode={setlookUpCode}
                  setproductId={setproductId}
                  productState={""}
                  setProductFilterchipsFilterchips={
                    setProductFilterchipsFilterchips
                  }
                  productId={productId}
                  setsupplierchips={setsupplierchips}
                  setcitychips={setcitychips}
                  setstateCodechips={setstateCodechips}
                  setlookUpCodechips={setlookUpCodechips}
                  setCountryChips={setCountryChips}
                  supplierId={supplierId}
                  city={city}
                  stateCode={stateCode}
                  lookUpCode={lookUpCode}
                  countryId={countryId}
                  setCountryId={setCountryId}
                  pooledFund="Fixed"
                />
              </div>
              {/* <Chips value={Filterchips} /> */}
              <div className="d-flex flex-column pr-1 p-0 mt-4  xl:col-1 md:col-1 sm:col-1 ">
                Filters:
              </div>
              {productFilterchips !== null &&
              productFilterchips !== "" &&
              productFilterchips !== undefined ? (
                <Chip
                  label={
                    productFilterchips?.length > 22
                      ? `${productFilterchips.slice(0, 22)}...`
                      : productFilterchips
                  }
                  className="btn-nav ml-0 mt-3 m-2 mr-2"
                  removable
                  onRemove={(e) => {
                    console.log("RemoveFilter", e);
                    e.preventDefault();
                    // eslint-disable-next-line unicorn/no-null
                    setproductId(null);
                    // eslint-disable-next-line unicorn/no-null
                    setProductFilterchipsFilterchips(null);
                  }}
                />
              ) : (
                <></>
              )}
              {supplierchips !== null &&
              supplierchips !== "" &&
              supplierchips !== undefined ? (
                <Chip
                  label={supplierchips}
                  className="btn-nav mr-2 ml-0 mt-3 m-2"
                  removable
                  onRemove={(e) => {
                    // eslint-disable-next-line unicorn/no-null
                    setsupplierId(null);
                    // eslint-disable-next-line unicorn/no-null
                    setsupplierchips(null);
                  }}
                />
              ) : (
                <></>
              )}
              {citychips !== null &&
              citychips !== "" &&
              citychips !== undefined ? (
                <Chip
                  label={citychips}
                  className="btn-nav mr-2 ml-0 mt-3 m-2"
                  removable
                  onRemove={(e) => {
                    e.preventDefault();
                    // eslint-disable-next-line unicorn/no-null
                    setcity(null);
                    // eslint-disable-next-line unicorn/no-null
                    setcitychips(null);
                  }}
                />
              ) : (
                <></>
              )}
              {lookUpCodechips !== null &&
              lookUpCodechips !== "" &&
              lookUpCodechips !== undefined ? (
                <Chip
                  label={lookUpCodechips}
                  className="btn-nav ml-0 m-2 mt-3 mr-2"
                  removable
                  onRemove={(e) => {
                    e.preventDefault();
                    // eslint-disable-next-line unicorn/no-null
                    setlookUpCode(null);
                    // eslint-disable-next-line unicorn/no-null
                    setlookUpCodechips(null);
                  }}
                />
              ) : (
                <></>
              )}
              {stateCodechips !== null &&
              stateCodechips !== "" &&
              stateCodechips !== undefined ? (
                <Chip
                  label={stateCodechips}
                  className="btn-nav ml-0 m-2 mt-3 mr-2"
                  removable
                  onRemove={(e) => {
                    e.preventDefault();
                    // eslint-disable-next-line unicorn/no-null
                    setstateCode(null);
                    // eslint-disable-next-line unicorn/no-null
                    setstateCodechips(null);
                  }}
                />
              ) : (
                <></>
              )}

              {countryChips ? (
                <Chip
                  label={countryChips}
                  className="btn-nav ml-0 m-2 mt-3 mr-2"
                  removable
                  onRemove={(e) => {
                    e.preventDefault();
                    // eslint-disable-next-line unicorn/no-null
                    setCountryId(null);
                    // eslint-disable-next-line unicorn/no-null
                    setCountryChips(null);
                  }}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        {/* <div className="flex flex-row flex-wrap p-0 sm:flex-none col-12">
          {/* <div className="d-flex flex-column p-0 mt-3">Filter By:</div> s
          <div className="d-flex flex-column p-1 xl:col-12 md:col-11 sm:col-10 flex justify-content-center">
            <InvestmentFilter
              listPage={true}
              filterReset={filterReset}
              setFilterReset={setFilterReset}
            />
          </div>
        </div> */}
      </div>
      {id === undefined ? (
        <div>
          <Deals offering={""} page={page} setpage={setpage} />
        </div>
      ) : (
        <ViewInvestmentDetails id={id} setOffering={""} />
      )}
    </>
  );
}
