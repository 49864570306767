import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OrdinaryDividendsService } from "../../../../../services/Accounting/accounting";
import error from "../../../../../utils/error";
import { IOrdinaryDividendsCUDState } from ".";

const initialState: IOrdinaryDividendsCUDState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: undefined,
};
export const OrdinaryDividendsCUDThunk = createAsyncThunk(
  "ordinaryDividends",
  async (data: any) => {
    try {
      const response = await OrdinaryDividendsService(data);
      //   console.log("LegalEntityListres", response.data);
      return response.data;
    } catch (error_) {
      console.log(error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "ordinaryDividends",
  initialState,
  reducers: {},
  extraReducers(builder): void {
    // Investment
    builder.addCase(OrdinaryDividendsCUDThunk.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(OrdinaryDividendsCUDThunk.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.data = action.payload.data ? action.payload.data : [];
    });
    builder.addCase(OrdinaryDividendsCUDThunk.rejected, (state, action) => {
      state.loading = "failed";
      state.error = error(action.error);
      throw new Error(error(state.error));
    });
  },
});

export default slice.reducer;
