import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";

import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../../components/loader/Loader";
import { GetRoleListThunk } from "../../../../store/features/accountatatement/rolemanagement/managerole/getmanagerole/slice";
import { ManageUserListThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuser/getmanageuserlist/slice";
import { DeleteUserRoleAccessThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuseraccess/deleteroleaccess/slice";
import { GetUserListDetailsThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuseraccess/getuserdetails/slice";
import { SaveUserRoleMappingThunk } from "../../../../store/features/accountatatement/rolemanagement/manageuseraccess/saveroleaccess/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { successToastMessage } from "../../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function ManageUserAccess() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  const GetUserList = useAppSelector((state) => state.GetUserListDetails?.data);
  const getUserList = useAppSelector((state) => state.ManageUserList?.data);
  const getRoleList = useAppSelector((state) => state.GetRoleList.data);

  const [id, setId] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [openDailog, setOpenDailog] = useState(false);

  const param = {
    LegalEntityId: fundDropdown.fundid,
  };

  console.log("profileData", profileData);

  const formik = useFormik({
    initialValues: {
      user: "",
      role: "",
    },
    validate: (data: any) => {
      const errors: any = {};

      console.log(" validate", data);
      if (data.user === "") {
        errors.user = "Please select user";
      }
      if (!data.role) {
        errors.role = "Please select role";
      }

      return errors;
    },
    onSubmit: async (data: any) => {
      console.log(data);

      const reqParam = {
        legalEntityId: fundDropdown.fundid,
        userId: +formik.values.user,
        toRoleId: +formik.values.role,
        loginId: profileData.userID,
      };
      setloading(true);
      dispatch(SaveUserRoleMappingThunk(reqParam))
        .then(() => {
          setSuccessVisible(true);
          setloading(false);

          // toast.current?.show({
          //   severity: "success",
          //   summary: "success",
          //   detail: "Saved successfully ",
          //   life: 3000,
          // });
          dispatch(GetUserListDetailsThunk(param));
          formik.resetForm();
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    },
  });

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const deleteButton = (rowData: any) => {
    return (
      <div className="flex flex-column ">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              setId(rowData.userId);

              setVisible(true);
            }}
            disabled={profileData.emailID === rowData.username}
          >
            <span className="text-white">REMOVE ACCESS</span>
          </Button>
        </div>
      </div>
    );
  };
  const onDelete = () => {
    const deleteParms = {
      UserId: id,
    };
    dispatch(DeleteUserRoleAccessThunk(deleteParms)).then(() => {
      dispatch(GetUserListDetailsThunk(param));
      successToastMessage(toast, "Access removed successfully ");
    });
    setVisible(false);
  };

  const dataTableElements = [
    {
      field: "firstName",
      header: "First Name",
      style: { minWidth: "160px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.firstName}>
          {rowData?.firstName}
        </div>
      ),
    },
    {
      field: "lastName",
      header: "Last Name",
      style: { minWidth: "160px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.lastName}>
          {rowData?.lastName}
        </div>
      ),
    },
    {
      field: "username",
      header: "User Name",
      style: { minWidth: "240px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.username}>
          {rowData?.username}
        </div>
      ),
    },
    {
      field: "roleName",
      header: "Role",
      style: { minWidth: "160px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.roleName}>
          {rowData?.roleName}
        </div>
      ),
    },
    {
      field: "",
      header: "",
      style: { minWidth: "200px" },
      body: (rowData: any) => deleteButton(rowData),
    },
  ];

  //   const resetForm = () => {
  //     setName("");
  //   };

  useEffect(() => {
    formik.resetForm();
    if (fundDropdown.fundid) {
      setloading(true);
      dispatch(ManageUserListThunk(param));
      // const req ={
      //   UserId:+formik.values.user
      // }
      // dispatch(GetRoleListThunk(req));
      dispatch(GetUserListDetailsThunk(param)).finally(() => {
        setloading(false);
      });
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    const req = {
      LegalEntityId: fundDropdown.fundid,
      UserId: +formik.values.user,
    };
    dispatch(GetRoleListThunk(req));
  }, [+formik.values.user]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const userOptionTemplate = (option: any) => {
    return (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "inherit",
          cursor: "pointer",
        }}
        title={option.value}
      >
        {option.value}
      </div>
    );
  };

  return (
    <>
      {loading && <Loader />}
      <div className="grid">
        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Manage User Access</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                GetUserList === undefined ||
                GetUserList?.length === 0 ||
                GetUserList === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            User role is the same across all fund. Since this user has the same
            role in multiple funds, by removing this role, the user will lose
            this role across all funds.
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={onDelete}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <Dialog
          visible={successVisible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setSuccessVisible(false)}
        >
          <h4 className="pageHeader  mb-1 pt-5">
            User access is successfully granted.
          </h4>
          <h4 className="pageHeader text-justify mb-5 ">
            Note: User can only have one role. If the user belongs to more than
            one fund, the same role is applied to all the funds user has access
            to.
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-center">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setSuccessVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Ok
            </Button>
          </div>
        </Dialog>

        <div className="col-12">
          <div className="formgrid grid ">
            <div className="d-block sm:d-flex sm:flex-column md:col-5 col-12">
              {/* <label className="inputLabel">
                Select User <span className="text-red-500"> *</span>
              </label> */}
              <Dropdown
                name="user"
                style={{ width: "100%" }}
                options={getUserList}
                optionLabel="value"
                filter
                itemTemplate={userOptionTemplate}
                optionValue="key"
                placeholder="Select User*"
                onChange={(e) => formik.setFieldValue("user", e.value)}
                value={formik.values.user}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("user"),
                  "card-inputBox": true,
                })}
              />
              {getFormErrorMessage("user")}
            </div>
            <div className="d-block sm:d-flex sm:flex-column md:col-5 col-12">
              {/* <label className="inputLabel">
                Select Role <span className="text-red-500"> *</span>
              </label> */}
              <Dropdown
                name="role"
                style={{ width: "100%" }}
                options={getRoleList}
                optionLabel="value"
                optionValue="key"
                placeholder="Select Role*"
                filter
                onChange={(e) => formik.setFieldValue("role", e.value)}
                value={formik.values.role}
                disabled={!+formik.values.user}
                className={classNames({
                  "p-invalid": isFormFieldInvalid("role"),
                  "card-inputBox": true,
                })}
              />
              {getFormErrorMessage("role")}
            </div>
            <div className="d-flex justify-content-center col-12 md:col-2 mb-2 md:mb-0">
              <Button
                className="mr-2 btn-nav w-5rem justify-content-center"
                onClick={(e) => {
                  e.preventDefault();
                  // setIsFormSubmitted(true);
                  console.log("clicked");
                  formik.resetForm();
                }}
              >
                Cancel
              </Button>

              <Button
                className="btn-nav w-5rem justify-content-center"
                onClick={(e) => {
                  e.preventDefault();
                  // setIsFormSubmitted(true);
                  console.log("clicked");
                  formik.handleSubmit();
                }}
              >
                Save
              </Button>
            </div>
          </div>

          {/* <div className="card tabWithoutboxshadow"> */}
          {/* <Card> */}
          <DataTableComponent
            valueData={GetUserList!}
            fieldsElements={dataTableElements}
            isPaginationAllowed={true}
            className="gridcell deletebtn"
            columnResizeMode="expand"
            scrollable={true}
            maxHeight={"calc(-215px + 100vh)"}
            isFullView={true}
            setOpenDailog={setOpenDailog}
            openDailog={openDailog}
          />
          {/* </Card> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
