// import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import React, { useEffect } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getThunkInvestorAssets } from "../../../../store/features/reports/investor-assets/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
// import InvestorAssetsSkeleton from "./InvestorAssetsSkeleton";

export default function InvestorAssets() {
  const dispatch = useAppDispatch();
  const investorAssetData = useAppSelector(
    (state) => state.investorAssets.data
  );
  const loadingState = useAppSelector((state) => state.investorAssets.loading);
  console.log("investorAssetData", investorAssetData);
  const dropdown = useAppSelector((state) => state.navbardropdown.data);

  useEffect(() => {
    if (dropdown.fundid !== undefined && dropdown.fundid > 0) {
      const parameters = {
        legalEntityId: dropdown.fundid,
      };
      dispatch(getThunkInvestorAssets(parameters));
    }
  }, [dropdown.fundid]);
  const formatCurrencyAnyData = (rowData: any) => {
    const finalValue = Number(rowData);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const formatcommDebtValueCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.commDebtValue);
  };

  const formatrequityValueCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.equityValue);
  };
  const formatanyepercentage = (rowData: any) => {
    return `${Number(rowData).toFixed(2)}%`;
  };
  const formatValuePercentage = (rowData: any) => {
    return formatanyepercentage(rowData.commDebtValueP);
  };

  const formatresDebtValuePPercentage = (rowData: any) => {
    return formatanyepercentage(rowData.resDebtValueP);
  };
  const formatreequityValuePPPercentage = (rowData: any) => {
    return formatanyepercentage(rowData.equityValueP);
  };
  const formatresDebtValueValueCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.resDebtValue);
  };
  const formatcashBalanceCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.cashBalance);
  };
  const formatcashbalancePercentagePercentage = (rowData: any) => {
    return formatanyepercentage(rowData.cashBalanceP);
  };
  const formattotalAccountCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.totalValue);
  };

  const formatcashWithdrawalsCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.cashWithdrawals);
  };

  const formatbadDebtExpNCapLossCurrency = (rowData: any) => {
    return formatCurrencyAnyData(rowData.badDebtExpNCapLoss);
  };

  function isNumeric(n: any) {
    return !Number.isNaN(Number.parseFloat(n)) && Number.isFinite(n);
  }
  const computeSupplierSumPercentage = (
    SumFildname: string,
    totalFieldName: string
  ) => {
    if (investorAssetData !== undefined) {
      const { length } = investorAssetData;

      let totalcount = 0;
      for (let i = 0; i < length; i += 1) {
        totalcount += Number(
          typeof investorAssetData[i][totalFieldName] === "object"
            ? investorAssetData[i][totalFieldName]
            : investorAssetData[i][totalFieldName] || 0
        );
      }
      let fildcount = 0;
      for (let i = 0; i < length; i += 1) {
        fildcount += Number(
          typeof investorAssetData[i][SumFildname] === "object"
            ? investorAssetData[i][SumFildname]
            : investorAssetData[i][SumFildname] || 0
        );
      }

      const returnValue = (fildcount / totalcount) * 100;
      if (isNumeric(returnValue)) {
        return formatanyepercentage(returnValue);
      }
      return formatanyepercentage(0);
    }
  };
  const computeSupplierSum = (totalFieldName: string) => {
    if (investorAssetData !== undefined) {
      const { length } = investorAssetData;

      let count = 0;
      for (let i = 0; i < length; i += 1) {
        count += Number(
          typeof investorAssetData[i][totalFieldName] === "object"
            ? investorAssetData[i][totalFieldName]
            : investorAssetData[i][totalFieldName] || 0
        );
      }
      return totalFieldName === "commDebtValue" ||
        totalFieldName === "resDebtValue" ||
        totalFieldName === "equityValue" ||
        totalFieldName === "cashBalance" ||
        totalFieldName === "badDebtExpNCapLoss" ||
        totalFieldName === "cashWithdrawals" ||
        totalFieldName === "totalValue"
        ? formatCurrencyAnyData(count)
        : totalFieldName === "totalDeals"
        ? count
        : formatanyepercentage(count);
    }
  };

  const footerSupplierGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total:"

          // footerStyle={{ textAlign: "right" }}
        />
        <Column footer={computeSupplierSum("commDebtValue")} />
        <Column
          footer={computeSupplierSumPercentage("commDebtValue", "totalValue")}
        />
        <Column footer={computeSupplierSum("resDebtValue")} />
        <Column
          footer={computeSupplierSumPercentage("resDebtValue", "totalValue")}
        />

        <Column footer={computeSupplierSum("equityValue")} />
        <Column
          footer={computeSupplierSumPercentage("equityValue", "totalValue")}
        />
        <Column footer={computeSupplierSum("cashBalance")} />

        <Column
          footer={computeSupplierSumPercentage("cashBalance", "totalValue")}
        />
        <Column footer={computeSupplierSum("badDebtExpNCapLoss")} />
        <Column footer={computeSupplierSum("cashWithdrawals")} />
        <Column footer={computeSupplierSum("totalValue")} />
        <Column footer={computeSupplierSum("totalDeals")} />
      </Row>
    </ColumnGroup>
  );
  // const [shouldFreeze, setShouldFreeze] = useState(window.innerWidth >= 768);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setShouldFreeze(window.innerWidth >= 768);
  //   };

  //   // Attach the event listener
  //   window.addEventListener("resize", handleResize);

  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener("resize", handleResipze);
  //   };
  // }, []);
  const dataTableElements = [
    {
      field: "investorName",
      header: "Investor Account",
      // frozen: shouldFreeze && true,
      style: { width: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.investorName}>
          {rowData?.investorName}
        </div>
      ),
    },
    {
      field: "commDebtValue",
      header: "Commerical Debt Value",
      body: formatcommDebtValueCurrency,
      style: { minWidth: "200px" },
    },
    {
      field: "commDebtValueP",
      header: "Commerical Debt %",
      body: formatValuePercentage,
      style: { minWidth: "200px" },
    },
    {
      field: "resDebtValue",
      header: "Residential Debt Value",
      body: formatresDebtValueValueCurrency,
      style: { width: "200px" },
    },
    {
      field: "resDebtValueP",
      header: "Residential Debt %",
      body: formatresDebtValuePPercentage,
      style: { width: "200px" },
    },
    {
      field: "equityValue",
      header: "Equity Value",
      body: formatrequityValueCurrency,
      style: { width: "200px" },
    },
    {
      field: "equityValueP",
      header: "Equity Value %",
      body: formatreequityValuePPPercentage,
      style: { width: "200px" },
    },
    {
      field: "cashBalance",
      header: "Cash Balance",
      body: formatcashBalanceCurrency,
      style: { width: "200px" },
    },
    {
      field: "cashBalanceP",
      header: "Cash Balance %",
      body: formatcashbalancePercentagePercentage,
      style: { width: "200px" },
    },
    {
      field: "badDebtExpNCapLoss",
      header: "Bad Debt Expense",
      body: formatbadDebtExpNCapLossCurrency,
      style: { width: "200px" },
    },
    {
      field: "cashWithdrawals",
      header: "Cash Withdrawal",
      body: formatcashWithdrawalsCurrency,
      style: { width: "200px" },
    },
    {
      field: "totalValue",
      header: "Total Account Value",
      body: formattotalAccountCurrency,
      style: { width: "200px" },
    },
    {
      field: "totalDeals",
      header: "Total Deals",
      style: { width: "200px" },
    },
  ];
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);
  const exportExcelHeader = [
    {
      field: "investorId",
      header: "Investor Id",
    },
    {
      field: "investorName",
      header: "Investor Account",
    },
    {
      field: "commDebtValue",
      header: "Commerical Debt Value",
      dataType: "currency",
    },
    {
      field: "commDebtValueP",
      header: "Commerical Debt %",
      dataType: "percentage",
    },
    {
      field: "resDebtValue",
      header: "Residential Debt Value",
      dataType: "currency",
    },
    {
      field: "resDebtValueP",
      header: "Residential Debt %",
      dataType: "percentage",
    },
    {
      field: "equityValue",
      header: "Equity Value",
      dataType: "currency",
    },
    {
      field: "equityValueP",
      header: "Equity Value %",
      dataType: "percentage",
    },
    {
      field: "cashBalance",
      header: "Cash Balance",
      dataType: "currency",
    },
    {
      field: "cashBalanceP",
      header: "Cash Balance %",
      dataType: "percentage",
    },
    {
      field: "badDebtExpNCapLoss",
      header: "Bad Debt Expense",
      dataType: "currency",
    },
    {
      field: "cashWithdrawals",
      header: "Cash Withdrawal",
      dataType: "currency",
    },
    {
      field: "totalValue",
      header: "Total Account Value",
      dataType: "currency",
    },
    {
      field: "totalDeals",
      header: "Total Deals",
    },
  ];
  function formatToTwoDecimalPlaces(value: any) {
    if (Number.isNaN(value) || value === undefined || value === null) {
      return 0; // or return the original value or any default value
    }
    return Number(value).toFixed(2);
  }

  function formatDataArray(dataArray: any[]) {
    return dataArray.map((item: any) => {
      return {
        ...item,
        commDebtValueP: formatToTwoDecimalPlaces(item.commDebtValueP),
        resDebtValueP: formatToTwoDecimalPlaces(item.resDebtValueP),
        cashBalanceP: formatToTwoDecimalPlaces(item.cashBalanceP),
        equityValueP: formatToTwoDecimalPlaces(item.equityValueP),
      };
    });
  }
  return (
    <>
      {loadingState === "loading" ? <Loader /> : <></>}
      {/* <div className="grid">
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Investor Assets Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div> */}
      {/* <div className="grid">
        <div className="col-12">
          <div className="card my-3 tabWithoutboxshadow"> */}
      {/* <Card> */}
      {/* <h4 className="cardHeader">Investor Assets Report</h4>
            <div className="row my-4 d-flex justify-content-between">
              {" "} */}
      {/* <div className="col-sm-4 mb-2">
                <RiSearchLine className="mr-2" />
                <InputText
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                  placeholder="Search..."
                />
              </div> */}
      {/* </div> */}
      <DataTableComponent
        valueData={investorAssetData || []}
        fieldsElements={dataTableElements}
        isPaginationAllowed={true}
        excelFieldsElements={exportExcelHeader}
        excelData={formatDataArray(investorAssetData)}
        isDownload={true}
        scrollable={true}
        isFullView={true}
        HeadingName="Investor Assets Report"
        maxHeight="calc(-133px + 100vh)"
        footerElement={footerSupplierGroup}
        fileName="Investor Assets Report"
        className="gridcell"
      />
      {/* </Card> */}
      {/* </div>
        </div>
      </div> */}
    </>
  );
}
