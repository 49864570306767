// import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
// import { InputText } from "primereact/inputtext";
// import { Skeleton } from "primereact/skeleton";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
// import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import { NavLink } from "react-router-dom";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { GetMenuListThunk } from "../../../../store/features/master/getmenulist/slice";
import { GetParentMenuListThunk } from "../../../../store/features/master/getparentmenulist/slice";
import { GetVideoLinkMenuInfoThunk } from "../../../../store/features/master/getvideolinkmenuinfo/slice";
import { AddHelpVideoLinkThunk } from "../../../../store/features/sponser/manage-help-videos/addhelpvideolink/slice";
// import { DisableEnableMenuLinkThunk } from "../../../../store/features/sponser/manage-help-videos/disableenablemenulink/slice";
import { DeleteHelpVideoLinkThunk } from "../../../../store/features/sponser/manage-help-videos/delete-help-video/slice";
import { HelpVedioSubMenuDropdownThunk } from "../../../../store/features/sponser/manage-help-videos/helpvediosubmenudropdown/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  errorToastMessage,
  infoToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function ManageHelpVideos() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  // const portState = useAppSelector((state) => state.GetMenuList.loading);
  //   const Viewapproveregistor = useAppSelector(
  //     (state) => state.GetInvestorList.data
  //   );
  // const profileData = useAppSelector((state) => state.profile.data![0]);

  const GetParentMenuList = useAppSelector(
    (state) => state.GetParentMenuList.data
  );
  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  const GetMenuList = useAppSelector((state) => state.GetMenuList?.data);
  console.log("Hello", GetParentMenuList);
  //   const getParentDropdown = useAppSelector(
  //     (state) => state.GetParentMenuList?.data
  //   );

  //   const [leagalentity, setLeagalentity] = useState<any>();
  // const [parentId, setParentId] = useState<any>("");
  const [subMenu, setSubMenu] = useState<any>("");
  const [subMenuList, setSubMenuList] = useState<any>("");

  const [subMenu2, setSubMenu2] = useState<any>("");
  const [subMenu2List, setSubMenu2List] = useState<any>("");

  //   const [name, setName] = useState<any>("");
  const [parentMenu, setParentMenu] = useState<any>("");
  const [videoLink, setVideoLink] = useState<any>("");
  // const [id, setId] = useState<any>();
  const fileRef = useRef<any>(null);
  const [topmenuerror, setTopMenuError] = useState<any>(false);
  const [submenuerror, setSubMenuError] = useState<any>(false);
  const [videoerror, setVideoError] = useState<any>(false);
  const [docLink, setDocLink] = useState("");
  const [videoFile, setVideoFile] = useState<any>();
  const [DocName, setDocName] = useState("");
  const [visibleViewUpload, setVisibleViewUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [openDailog, setOpenDailog] = useState(false);

  // const [activeFlag, setActiveFlag] = useState<any>();

  // const [error, setError] = useState(false);
  // const [visible, setVisible] = useState(false);

  // const editButton = (rowData: any) => {
  //   // Pass rowData as a parameter
  //   const handleEdit = () => {
  //     // setParentMenu(rowData.id);
  //     setId(rowData.id);
  //     console.log("rowData", rowData.active);

  //     if (rowData.active === true) {
  //       console.log("inside if", rowData);

  //       setActiveFlag(true);
  //     } else {
  //       console.log("inside else", rowData);

  //       setActiveFlag(false);
  //     }
  //     setVisible(true);
  //     console.log("activeFlag", activeFlag);
  //   };

  //   return (
  //     <div className="flex flex-column">
  //       <div className="flex align-items-center justify-content-center">
  //         <Button
  //           className="btn-dialog align-items-center justify-content-center"
  //           onClick={handleEdit}
  //         >
  //           {rowData.active === true ? (
  //             <span className="text-white">Disable</span>
  //           ) : (
  //             <span className="text-white">Enable</span>
  //           )}
  //         </Button>
  //       </div>
  //     </div>
  //   );
  // };
  const deleteButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleDelete = (ele: any) => {
      console.log("ele", ele);
      console.log("Delete Button", rowData);
      setDeleteId(rowData.id);
      setVideoUrl(rowData.videoLink);
      // setInvestorDocumentId(rowData.id);
      // formik.setFieldValue("partnerId", rowData.lpPartnerId);
      setOpenDialog(true);
      // setShowTab(true);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={() => handleDelete(rowData.Id)}
          >
            <span className="text-white">Delete</span>
          </Button>
        </div>
      </div>
    );
  };
  const deleteOn = () => {
    setLoading(true);
    const deleteParms = {
      id: deleteId,
      action: "delete",
      videoUrl,
    };
    dispatch(DeleteHelpVideoLinkThunk(deleteParms))
      .then((response) => {
        // call the list
        if (response.meta.requestStatus === "rejected") {
          errorToastMessage(toast);
        } else if (response.meta.requestStatus === "fulfilled") {
          if (response.payload.success === true) {
            dispatch(GetMenuListThunk());
            successToastMessage(toast, "Data is deleted successfully");
            setDeleteId("");
            setVideoLink("");
          } else {
            errorToastMessage(toast, "Data is not deleted");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    setOpenDialog(false);
  };
  const onCancel = () => {
    setDeleteId("");
    setVideoLink("");
    // setInvestorDocumentId(0);
    setOpenDialog(false);
  };

  const resetForm = () => {
    setParentMenu("");
    setSubMenu("");
    setVideoLink("");
    setVideoFile("");
    setDocName("");
    fileRef?.current.clear();
  };

  //   const onAddClick = () => {
  //     resetForm();
  //     setEdit(false);
  //   };

  const dataTableElements = [
    {
      field: "menuName",
      header: "Menu Name",
      style: { minWidth: "250px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.menuName}>
          {rowData?.menuName}
        </div>
      ),
    },
    {
      field: "videoLink",
      header: "Video Link",
      style: { minWidth: "250px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.videoLink}>
          {rowData?.videoLink}
        </div>
      ),
    },
    {
      header: "",
      body: deleteButton,
      style: { minWidth: "200px" },
    },
  ];

  useEffect(() => {
    setLoading(true);
    dispatch(GetMenuListThunk());
    dispatch(GetParentMenuListThunk()).then(() => {
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    const param = {
      ParentId: parentMenu,
    };
    if (parentMenu) {
      dispatch(HelpVedioSubMenuDropdownThunk(param)).then((res: any) => {
        const sub = res.payload ? res.payload.data! : [];
        setSubMenuList(sub);
        if (res.payload.data === null) {
          setSubMenu(0);
        }
      });
    }
  }, [parentMenu]);
  useEffect(() => {
    if (dropdown.fundid !== undefined && dropdown.fundid > 0) {
      resetForm();
    }
  }, [dropdown.fundid]);
  const handleChange = async (e: any) => {
    const selectedV = {
      ParentId: e.value,
    };
    setSubMenu(e.value);
    // e.value ? setSubMenuError(false) : setSubMenuError(true);
    if (e.value) {
      dispatch(HelpVedioSubMenuDropdownThunk(selectedV)).then((res: any) => {
        setSubMenu2List(res.payload.data);
      });
    }
  };
  console.log("topmeny", parentMenu, "submenu", subMenu);

  const uploadFile = async (e: any) => {
    const file = e.files[0];
    const regex =
      /(.*?).(.mp4|.m4v|.mkv|.mov|.flv|.wmv|.webm|.mpg|.mpeg|.m2v|.ts|.3gp|.3g2|.vob|.ogg|.ogv|.qt|.asf|.amv|.rm|.rmvb|.m2ts|.divx|.xvid|.mpv|.mp2|.mpe|.m2p|.f4v|.f4p|.f4a|.f4b|.swf|.mk3d|.gxf|.roq|.nsv|.nut|.h264|.h265|.yuv|.svi|.mts|.mtv|.mxf|.m1v|.mod|.tod|.vro)$/;

    if (!regex.test(file.name.toLowerCase())) {
      errorToastMessage(toast, "Upload failed. Please Use Video format only  ");
      fileRef?.current.clear();
      return;
    }

    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 210) {
      errorToastMessage(
        toast,
        "Upload failed. Please ensure the file size does not exceed 200mb"
      );
      fileRef?.current.clear();
      return;
    }
    setDocName(file.name);
    setVideoFile(file);
    infoToastMessage(toast, "Please save the selected file");
    setDocLink(URL.createObjectURL(file));
    console.log("File", videoFile, "DockLink", docLink, file);
    // setContentType(file.type);
    fileRef?.current.clear();
  };
  console.log("VideoFile", videoFile, videoLink, videoerror);
  const openDoc = async (e: any) => {
    e.preventDefault();
    // let key = "";
    // setLoading(true);
    // key = await generateSASKey(fundDropdown.fundid.toString());
    // setsasKey(key);
    setVisibleViewUpload(true);
  };

  const onSave = () => {
    console.log("VideoFile Console", videoFile, parentMenu, subMenu);
    if (videoFile === undefined || videoFile === null || videoFile === "") {
      errorToastMessage(toast, "Please Select File");
      return;
    }
    if (subMenu === "") {
      setSubMenuError(true);
    } else {
      setSubMenu(false);
    }
    // const addParam = {
    //   id: +subMenu,
    //   parentId: parentMenu,
    //   videoLink,
    // };
    const data = new FormData();
    if (subMenu2 !== "" && subMenu2 !== undefined && subMenu2 !== null) {
      data.append("id", subMenu2.toString());
      data.append("parentId", subMenu.toString());
    } else {
      if (subMenu === 0) {
        data.append("id", parentMenu.toString());
        data.append("parentId", subMenu.toString());
      } else {
        data.append("id", subMenu.toString());
        data.append("parentId", parentMenu.toString());
      }
    }
    data.append("videoFile", videoFile);
    data.append("action", "create");

    if (parentMenu && subMenu !== "") {
      setLoading(true);
      dispatch(AddHelpVideoLinkThunk(data))
        .then((response) => {
          // console.log("Abhishek Response", response);
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (response.meta.requestStatus === "fulfilled") {
            if (response.payload.success === true) {
              successToastMessage(toast, "Menu Video Successfully Saved.");
            } else {
              errorToastMessage(toast, "Menu Video Not Uploaded.");
            }
          }
        })
        .finally(() => {
          dispatch(GetMenuListThunk());
          setTopMenuError(false);
          setSubMenuError(false);
          setVideoError(false);
          setParentMenu("");
          setSubMenu("");
          setVideoLink("");
          setDocName("");
          fileRef?.current.clear();
          setLoading(false);
        });
    } else {
      setTopMenuError(true);
      setSubMenuError(true);
      setVideoError(true);
    }

    //
  };
  // const onDelete = () => {
  //   const disableParam = {
  //     Id: id,
  //     Type: activeFlag ? "D" : "E",
  //   };
  //   dispatch(DisableEnableMenuLinkThunk(disableParam)).then(() => {
  //     dispatch(GetMenuListThunk());
  //   });
  //   setVisible(false);
  // };

  useEffect(() => {
    const req = {
      MenuId: subMenu,
      ParentId: parentMenu,
    };
    if (subMenu) {
      dispatch(GetVideoLinkMenuInfoThunk(req)).then((res: any) => {
        setVideoLink(res.payload.data[0].videoLink);
      });
    }
  }, [subMenu]);

  useEffect(() => {
    setVideoLink("");
  }, [parentMenu]);
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);
  return (
    <>
      {loading ? <Loader /> : <></>}
      <Dialog
        header=""
        maximizable
        visible={visibleViewUpload}
        style={{ width: "50vw" }}
        onHide={() => {
          setVisibleViewUpload(false);
        }}
        closable={true}
      >
        <div className="m-0">
          <iframe
            src={docLink}
            style={{ width: "100%", height: "80vh" }}
          ></iframe>
        </div>
      </Dialog>
      <div className="grid">
        {/* <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          {!activeFlag ? (
            <h4 className="pageHeader text-center mb-5 pt-5">
              Are you sure you want to Enable
            </h4>
          ) : (
            <h4 className="pageHeader text-center mb-5 pt-5">
              Are you sure you want to Disable
            </h4>
          )}

          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={onDelete}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog> */}
        <Toast ref={toast} className="themeToast" />
        <ConfirmDailogbox
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          onConfirm={deleteOn}
          onCancel={onCancel}
          textContent="Are you sure you want to delete ?"
        />
        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Manage Help Videos</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                GetMenuList === undefined ||
                GetMenuList?.length === 0 ||
                GetMenuList === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <>
              {" "}
              <div className="formgrid grid p-2">
                <div className="d-block sm:d-flex sm:flex-column md:col-4 col-4 ">
                  {/* <label className="inputLabel">Top Menu Name *</label> */}
                  <Dropdown
                    name="Select..."
                    value={parentMenu}
                    onChange={(e) => {
                      setParentMenu(e.value);
                      e.value ? setTopMenuError(false) : setTopMenuError(true);
                    }}
                    style={{ width: "100%" }}
                    options={GetParentMenuList}
                    optionLabel="value"
                    optionValue="key"
                    placeholder="Select Top Menu Name*"
                    // filter
                  />
                  {topmenuerror && (
                    <>
                      <span className="text-xs text-red-600">
                        Please selete menu{" "}
                      </span>
                    </>
                  )}
                </div>
                <div className="d-block sm:d-flex sm:flex-column md:col-4 col-4 ">
                  {/* <label className="inputLabel">Sub Menu 1 *</label> */}
                  <Dropdown
                    name="Select..."
                    value={subMenu}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                    options={subMenuList}
                    optionLabel="value"
                    optionValue="key"
                    placeholder="Select Sub Menu 1*"

                    // filter
                  />
                  {submenuerror && (
                    <>
                      <span className="text-xs text-red-600">
                        Please enter sub menu
                      </span>
                    </>
                  )}
                </div>
                <div className="d-block sm:d-flex sm:flex-column  md:col-4 col-4 ">
                  {/* <label className="inputLabel">Sub Menu 2</label> */}
                  <Dropdown
                    name="Select..."
                    value={subMenu2}
                    onChange={(e) => {
                      setSubMenu2(e.value);
                    }}
                    style={{ width: "100%" }}
                    options={subMenu ? subMenu2List : []}
                    optionLabel="value"
                    optionValue="key"
                    placeholder="Select Sub Menu 1*"
                    // filter
                  />
                  {/* {error && (
                        <>
                          <span className="text-xs text-red-600">
                            Supplier Type is required
                          </span>
                        </>
                      )} */}
                </div>
              </div>
              <div className="d-flex">
                <div className="d-flex col-4 flex-column md:col-4">
                  {/* <label>&nbsp;</label> */}
                  <FileUpload
                    mode="basic"
                    name="demo"
                    ref={fileRef}
                    auto={true}
                    // maxFileSize={10_000_000}
                    customUpload={true}
                    chooseLabel="Upload Help Video"
                    className="btn-dialog border-round-md w-100 UploadAccreBtn"
                    uploadHandler={uploadFile}
                  />
                </div>
                <div className="d-flex col-6 justify-content-start">
                  <Button
                    className="mr-4 btn-nav w-7rem justify-content-center"
                    onClick={resetForm}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="btn-nav w-7rem justify-content-center"
                    onClick={onSave}
                  >
                    Save
                  </Button>
                </div>
              </div>
              <div className="d-flex flex-column col-4">
                {DocName === "" || DocName === undefined ? (
                  <></>
                ) : (
                  <label
                    className="inputLabel"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                      height: "20px",
                    }}
                  >
                    File Name:{" "}
                    <NavLink
                      className="document-link"
                      onClick={(e) => openDoc(e)}
                      to={""}
                      title={DocName}
                    >
                      {DocName}
                    </NavLink>
                  </label>
                )}
              </div>
            </>

            <DataTableComponent
              valueData={GetMenuList!}
              fieldsElements={dataTableElements}
              isPaginationAllowed={true}
              className="gridcell deletebtn"
              columnResizeMode="expand"
              // isFullView={true}
              setOpenDailog={setOpenDailog}
              openDailog={openDailog}
              scrollable={true}
              maxHeight={"calc(-290px + 100vh)"}
            />
          </div>
        </div>
      </div>
    </>
  );
}
