import { IGetDailyAccountValueparameters } from "../../store/features/accounting/dailyamu/getdailyaccountvalue";
import { IGetStartDateparameters } from "../../store/features/accounting/dailyamu/getstartdate";
import { IProcessDailyAccountparameters } from "../../store/features/accounting/dailyamu/processdailyaccount";
import { IAddExpenseReserveCUDParameter } from "../../store/features/accounting/debitcreditinvestoraccounts/AddExpenseReserveCUD";
import { IManagerFeePayoutCUDparameters } from "../../store/features/accounting/debitcreditinvestoraccounts/ManagerFeePayoutCUD";
import { IGetLPPartnersByInvestmentIdParameter } from "../../store/features/accounting/debitcreditinvestoraccounts/lppartnerbyinvestment";
import { IDistributeFeeparameters } from "../../store/features/accounting/distributeexpensesinvestors/distributefee";
import { ICalculateFeeDetailparameters } from "../../store/features/accounting/distributemanagementees/calculatefeedetails";
import { IGetFeeDetailsparameters } from "../../store/features/accounting/distributemanagementees/getfeedetails";
import { ISaveNotesparameters } from "../../store/features/accounting/distributemanagementees/savenotes";
import { IAddExpensesparameters } from "../../store/features/accounting/fundlevelexpences/addexpensex";
import { IDeleteExpenseparameters } from "../../store/features/accounting/fundlevelexpences/deleteexpenses";
import { IGetTotalYearMonthExpensesparameters } from "../../store/features/accounting/fundlevelexpences/gettotalmonthexpence";
import { IGetYearMonthExpensesListparameters } from "../../store/features/accounting/fundlevelexpences/getyearmonthexpenselist";
import { IManageFeeCUDparameters } from "../../store/features/accounting/managefeecud";
import { IDailyAccountProcessListparameters } from "../../store/features/accounting/reversedailyaum/dailyaccountprocessList";
import { IReverseDailyAccountProcessparameters } from "../../store/features/accounting/reversedailyaum/reversedailyaccountprocess";
import { ISaveReconciliationStatusparameters } from "../../store/features/accounting/savereconciliationstatus";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";
import { ISavePartnerAccountTransactionparameters } from "./../../store/features/accounting/debitcreditinvestoraccounts/savepartneraccounttransaction/index";
import { IGetReconciliationListparameters } from "./../../store/features/accounting/getreconciliationlist/index";
import { IGetReconciliationPopupparameters } from "./../../store/features/accounting/getreconciliationpopup/index";

export function GetTransactionTypeService() {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_ACCOUNTING}/Accounting/GetTransactionType
    `
  );
}

export function SavePartnerAccountTransactionService(
  data: ISavePartnerAccountTransactionparameters
) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_ACCOUNTING
    }/Accounting/SavePartnerAccountTransaction

    `,
    data
  );
}
export function SavePAddExpenseReserveCUDService(
  data: IAddExpenseReserveCUDParameter
) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_ACCOUNTING}/Accounting/AddExpenseReserveCUD`,
    data
  );
}

export function GetLPPartnersByInvestmentIdService(
  data: IGetLPPartnersByInvestmentIdParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ACCOUNTING
    }/Accounting/GetLPPartnersByInvestmentId?InvestmentId=${data.InvestmentId}`
  );
}

export function GetYearMonthExpensesListService(
  data: IGetYearMonthExpensesListparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Expenses/GetYearMonthExpensesList?LegalEntityId=${
      data.LegalEntityId
    }&YearMonth=${data.YearMonth}
    `
  );
}

export function GetTotalYearMonthExpensesService(
  data: IGetTotalYearMonthExpensesparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Expenses/GetTotalYearMonthExpenses?LegalEntityId=${
      data.LegalEntityId
    }&YearMonth=${data.YearMonth}
    `
  );
}

export function AddExpensesService(data: IAddExpensesparameters) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_OPERATION_URL}/Expenses/AddUpdateExpenses`,
    data
  );
}

export function DeleteExpenseService(data: IDeleteExpenseparameters) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Expenses/DeleteExpense?ExpenseId=${data.ExpenseId}&LegalEntityId=${
      data.LegalEntityId
    }&YearMonth=${data.YearMonth}
    `
  );
}

export function GetStartDateService(data: IGetStartDateparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/AUM/GetStartDate?LegalEntityId=${data.LegalEntityId}
    `
  );
}

export function GetDailyAccountValueService(
  data: IGetDailyAccountValueparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/AUM/GetDailyAccountValue?LegalEntityId=${data.LegalEntityId}&PartnerId=${
      data.PartnerId
    }`
  );
}
export function ProcessDailyAccountService(
  data: IProcessDailyAccountparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/AUM/ProcessDailyAccount?LegalEntityId=${data.LegalEntityId}&StartDate=${
      data.StartDate
    }&EndDate=${data.EndDate}

    `
  );
}

export function DailyAccountProcessListService(
  data: IDailyAccountProcessListparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/AUM/DailyAccountProcessList?LegalEntityId=${data.LegalEntityId}

    `
  );
}

export function ReverseDailyAccountProcessService(
  data: IReverseDailyAccountProcessparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/AUM/ReverseDailyAccountProcess?ProcessId=${
      data.ProcessId
    }&LegalEntityId=${data.LegalEntityId}

    `
  );
}

export function GetFeeDetailsService(data: IGetFeeDetailsparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Fees/GetFeeDetails?LegalEntityId=${data.LegalEntityId}&PartnerId=${
      data.PartnerId
    }&Year=${data.Year}

    `
  );
}
export function CalculateFeeDetailsService(
  data: ICalculateFeeDetailparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_OPERATION_URL
    }/Fees/CalculateFeeDetails?PartnerId=${data.PartnerId}&Year=${
      data.Year
    }&Month=${data.Month}`
  );
}

export function SaveNotesService(data: ISaveNotesparameters) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_OPERATION_URL}/Fees/SaveNotes`,
    data
  );
}

export function DistributeFeeService(data: IDistributeFeeparameters) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_OPERATION_URL}/Fees/DistributeFee`,
    data
  );
}

export function GetReconciliationListService(
  data: IGetReconciliationListparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ACCOUNTING
    }/Accounting/GetReconciliationList?LEID=${data.LEID}&Year=${data.Year}`
  );
}

export function GetReconciliationPopupService(
  data: IGetReconciliationPopupparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_ACCOUNTING
    }/Accounting/GetReconciliationPopupDetails?LegalEntityId=${
      data.LegalEntityId
    }&YearMonth=${data.YearMonth}&Type=${data.Type}`
  );
}

export function SaveReconciliationStatusService(
  data: ISaveReconciliationStatusparameters
) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_ACCOUNTING
    }/Accounting/SaveReconciliationStatus`,
    data
  );
}

export function ManageFeeCudService(data: IManageFeeCUDparameters) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_ACCOUNTING}/Accounting/ManageFeeCUD
    `,
    data
  );
}
export function ManagerFeePayoutCUDService(
  data: IManagerFeePayoutCUDparameters
) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_ACCOUNTING}/Accounting/ManagerFeePayoutCUD
    `,
    data
  );
}

export function OrdinaryDividendsService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_ACCOUNTING}/Accounting/SaveOrdinaryDividents
    `,
    data
  );
}
