export const config = {
  dev: {
    REACT_APP_HELP_CENTER_URL: "https://sponsor-avestordev.bolddesk.com",

    REACT_APP_API_SPONSOR_BASEURL:
      "https://portal-sponsor-dev.azurewebsites.net/",

    REACT_APP_API_Tax_URL:
      "https://api-taxmanagement-dev.azurewebsites.net/api",
    REACT_APP_API_SPONSOR_URL: "https://api-sponsor-dev.azurewebsites.net/api",
    REACT_APP_API_REPORTS_URL: "https://api-reports-dev.azurewebsites.net/api",
    REACT_APP_API_ACCOUNTING:
      "https://api-accounting-dev.azurewebsites.net/api",
    REACT_APP_API_FUND_MANAGEMENT_URL:
      "https://api-fundmanagement-dev.azurewebsites.net/api",
    REACT_APP_API_INVESTMENT_URL:
      "https://api-investment-dev.azurewebsites.net/api",
    REACT_APP_API_FileManager_URL: "https://fundassets.azurewebsites.net/api",
    REACT_APP_API_OPERATION_URL:
      "https://api-operations-dev.azurewebsites.net/api",
    REACT_APP_API_INVOICE_URL: "https://api-invoice-dev.azurewebsites.net/api",
    REACT_APP_API_ACCREDITATION_URL:
      "https://api-accreditation-dev.azurewebsites.net/api",
    REACT_APP_API_ESIGN_URL: "https://api-esign-dev.azurewebsites.net/api",
    REACT_APP_API_KYC_PERSONA_URL: "https://api-kyc-dev.azurewebsites.net/api",
    REACT_APP_API_ADMIN_MANAGEMENT_URL:
      "https://api-adminmanagement-dev.azurewebsites.net/api",
    REACT_APP_API_ENTITLEMENT_URL:
      "https://api-entitlement-dev.azurewebsites.net/api",
    REACT_APP_API_NOTIFICATION_MANAGEMENT_URL:
      "https://api-notificationmanagement-dev.azurewebsites.net/api",

    // REACT_APP_API_ASSETS_SPNG_URL:
    //   "https://api-fundassets-dev.azurewebsites.net/api",

    REACT_APP_API_ASSETS_SPNG_URL: "https://fundassets.azurewebsites.net/api",
    REACT_APP_API_INVESTOR_SPNG_URL:
      "https://api-investor-dev.azurewebsites.net/api",
    REACT_APP_API_FINANCE_URL: "https://api-finance-dev.azurewebsites.net/api",

    REACT_APP_LOGO:
      "https://strgaccsponsorb2cflowdev.blob.core.windows.net/b2c/common/avestor-logo.png",
    REACT_APP_DOMAIN_NAME: "https://sponsordev.b2clogin.com/",
    REACT_APP_DEFAULT_FUND_NAME: "https://www.avestorinc.com/",
    REACT_APP_TENANT_NAME: "sponsordev.onmicrosoft.com/",
    // REACT_APP_SIGN_IN: "b2c_1_login",
    REACT_APP_SIGN_IN: "B2C_1A_SIGNIN",
    REACT_APP_SIGN_UP: "B2C_1A_SIGNUP",
    REACT_APP_CHANGE_PASSWORD: "B2C_1A_PASSWORDCHANGE",
    REACT_APP_FORGOT_PASSWORD: "B2C_1A_FORGOTPASSWORD",
    REACT_APP_DWOLLA_CONFIGURE: "sandbox",
    // REACT_APP_FORGOT_PASSWORD: "b2c_1a_passwordchange",
    APP_EDIT_PROFILE: "b2c_1_investor-editprofile",
    REACT_APP_EDIT_PROFILE: "b2c_1_investor-editprofile",
    REACT_APP_AUTHORITY_DOMAIN: "sponsordev.b2clogin.com",
    REACT_APP_CLIENT_ID: "89f80080-5aee-4f62-b9d8-bd6f52588bf5",
    REACT_APP_TEMPLET_ID: "itmpl_qdm1ZSBsUNorA5UYRiZGfZUy",
    REACT_APP_PERSONA_ENV: "sandbox",
    REACT_APP_IDLE_TIME: 36_000 * 100,
    REACT_APP_IDLE_Prompt_Before_Idle: 6000 * 30,
    REACT_APP_FAQs: "https://sponsordev.bolddesk.com/kb",
    REACT_APP_Boldesk: "https://sponsordev.bolddesk.com/support/tickets",
    REACT_APP_Community: "https://avestor.slack.com/",
    REACT_APP_Chat_Bot:
      "https://web.powerva.microsoft.com/environments/Default-4e1b9b16-429c-41e3-9ded-d8f13e4cd73a/bots/cr0d5_avestorSupport/webchat?__version__=2",
  },
  demo: {
    REACT_APP_HELP_CENTER_URL: "https://sponsordemo.bolddesk.com",

    REACT_APP_API_SPONSOR_BASEURL:
      "https://portal-sponsor-demo.azurewebsites.net/",

    REACT_APP_API_Tax_URL:
      "https://api-taxmanagement-demo.azurewebsites.net/api",
    REACT_APP_API_SPONSOR_URL: "https://api-sponsor-demo.azurewebsites.net/api",
    REACT_APP_API_REPORTS_URL: "https://api-reports-demo.azurewebsites.net/api",
    REACT_APP_API_ACCOUNTING:
      "https://api-accounting-demo.azurewebsites.net/api",
    REACT_APP_API_FUND_MANAGEMENT_URL:
      "https://api-fundmanagement-demo.azurewebsites.net/api",
    REACT_APP_API_INVESTMENT_URL:
      "https://api-investment-demo.azurewebsites.net/api",
    REACT_APP_API_OPERATION_URL:
      "https://api-operations-demo.azurewebsites.net/api",
    REACT_APP_API_INVOICE_URL: "https://api-invoice-demo.azurewebsites.net/api",
    REACT_APP_API_ACCREDITATION_URL:
      "https://api-accreditation-demo.azurewebsites.net/api",
    REACT_APP_API_ESIGN_URL: "https://api-esign-demo.azurewebsites.net/api",
    REACT_APP_API_KYC_PERSONA_URL: "https://api-kyc-demo.azurewebsites.net/api",
    REACT_APP_API_ADMIN_MANAGEMENT_URL:
      "https://api-adminmanagement-demo.azurewebsites.net/api",
    REACT_APP_API_ENTITLEMENT_URL:
      "https://api-entitlement-demo.azurewebsites.net/api",
    REACT_APP_API_NOTIFICATION_MANAGEMENT_URL:
      "https://api-notificationmanagement-demo.azurewebsites.net/api",
    REACT_APP_API_FileManager_URL:
      "https://api-fundassets-demo.azurewebsites.net/api",
    REACT_APP_API_ASSETS_SPNG_URL:
      "https://api-fundassets-demo.azurewebsites.net/api",
    REACT_APP_API_INVESTOR_SPNG_URL:
      "https://api-investor-demo.azurewebsites.net/api",
    REACT_APP_API_FINANCE_URL: "https://api-finance-demo.azurewebsites.net/api",

    REACT_APP_LOGO:
      "https://strgaccsponsorb2cdemo.blob.core.windows.net/b2c/common/avestor-logo.png",
    REACT_APP_DOMAIN_NAME: "https://sponsordemo.b2clogin.com/",
    REACT_APP_DEFAULT_FUND_NAME: "https://www.avestorinc.com/",
    REACT_APP_TENANT_NAME: "sponsordemo.onmicrosoft.com/",
    // REACT_APP_SIGN_IN: "b2c_1_login",
    REACT_APP_SIGN_IN: "B2C_1A_SIGNIN",
    REACT_APP_SIGN_UP: "B2C_1A_SIGNUP",
    REACT_APP_CHANGE_PASSWORD: "B2C_1A_PASSWORDCHANGE",
    REACT_APP_FORGOT_PASSWORD: "B2C_1A_FORGOTPASSWORD",
    REACT_APP_DWOLLA_CONFIGURE: "sandbox",
    // REACT_APP_FORGOT_PASSWORD: "b2c_1a_passwordchange",
    APP_EDIT_PROFILE: "b2c_1_investor-editprofile",
    REACT_APP_EDIT_PROFILE: "b2c_1_investor-editprofile",
    REACT_APP_AUTHORITY_DOMAIN: "sponsordemo.b2clogin.com",
    REACT_APP_CLIENT_ID: "9faa45e9-714e-4af4-a8af-98a82b6bf068",
    REACT_APP_TEMPLET_ID: "itmpl_qdm1ZSBsUNorA5UYRiZGfZUy",
    REACT_APP_PERSONA_ENV: "sandbox",
    REACT_APP_IDLE_TIME: 36_000 * 100,
    REACT_APP_IDLE_Prompt_Before_Idle: 6000 * 30,
    REACT_APP_FAQs: "https://sponsordemo.bolddesk.com/kb",
    REACT_APP_Boldesk: "https://sponsordemo.bolddesk.com/support/tickets",
    REACT_APP_Community: "https://avestor.slack.com/",
    REACT_APP_Chat_Bot:
      "https://web.powerva.microsoft.com/environments/Default-4e1b9b16-429c-41e3-9ded-d8f13e4cd73a/bots/cr0d5_avestorSupport/webchat?__version__=2",
  },
  qa: {
    REACT_APP_HELP_CENTER_URL: "https://sponsorqa.bolddesk.com",

    REACT_APP_API_SPONSOR_BASEURL:
      "https://portal-sponsor-qa.azurewebsites.net/",

    REACT_APP_API_Tax_URL: "https://api-taxmanagement-qa.azurewebsites.net/api",
    REACT_APP_API_SPONSOR_URL: "https://api-sponsor-qa.azurewebsites.net/api",
    REACT_APP_API_REPORTS_URL: "https://api-reports-qa.azurewebsites.net/api",
    REACT_APP_API_ACCOUNTING: "https://api-accounting-qa.azurewebsites.net/api",
    REACT_APP_API_FUND_MANAGEMENT_URL:
      "https://api-fundmanagement-qa.azurewebsites.net/api",
    REACT_APP_API_INVESTMENT_URL:
      "https://api-investment-qa.azurewebsites.net/api",
    REACT_APP_API_OPERATION_URL:
      "https://api-operations-qa.azurewebsites.net/api",
    REACT_APP_API_INVOICE_URL: "https://api-invoice-qa.azurewebsites.net/api",
    REACT_APP_API_ACCREDITATION_URL:
      "https://api-accreditation-qa.azurewebsites.net/api",
    REACT_APP_API_ESIGN_URL: "https://api-esign-qa.azurewebsites.net/api",
    REACT_APP_API_KYC_PERSONA_URL: "https://api-kyc-qa.azurewebsites.net/api",
    REACT_APP_API_ADMIN_MANAGEMENT_URL:
      "https://api-adminmanagement-qa.azurewebsites.net/api",
    REACT_APP_API_ENTITLEMENT_URL:
      "https://api-entitlement-qa.azurewebsites.net/api",
    REACT_APP_API_NOTIFICATION_MANAGEMENT_URL:
      "https://api-notificationmanagement-qa.azurewebsites.net/api",

    REACT_APP_API_FileManager_URL:
      "https://api-fundassets-qa.azurewebsites.net/api",
    REACT_APP_API_ASSETS_SPNG_URL:
      "https://api-fundassets-qa.azurewebsites.net/api",
    REACT_APP_API_INVESTOR_SPNG_URL:
      "https://api-investor-qa.azurewebsites.net/api",
    REACT_APP_API_FINANCE_URL: "https://api-finance-qa.azurewebsites.net/api",

    REACT_APP_LOGO:
      "https://strgaccsponsorb2cqa.blob.core.windows.net/b2c/common/avestor-logo.png",
    REACT_APP_DOMAIN_NAME: "https://sponsorqa.b2clogin.com/",
    REACT_APP_DEFAULT_FUND_NAME: "https://www.avestorinc.com/",
    REACT_APP_TENANT_NAME: "sponsorqa.onmicrosoft.com/",
    // REACT_APP_SIGN_IN: "b2c_1_login",
    REACT_APP_SIGN_IN: "B2C_1A_SIGNIN",
    REACT_APP_SIGN_UP: "B2C_1A_SIGNUP",
    REACT_APP_CHANGE_PASSWORD: "B2C_1A_PASSWORDCHANGE",
    REACT_APP_FORGOT_PASSWORD: "B2C_1A_FORGOTPASSWORD",
    REACT_APP_DWOLLA_CONFIGURE: "sandbox",
    // REACT_APP_FORGOT_PASSWORD: "b2c_1a_passwordchange",
    APP_EDIT_PROFILE: "b2c_1_investor-editprofile",
    REACT_APP_EDIT_PROFILE: "b2c_1_investor-editprofile",
    REACT_APP_AUTHORITY_DOMAIN: "sponsorqa.b2clogin.com",
    REACT_APP_CLIENT_ID: "088a5d3f-f71a-4482-97a6-58bf6d3b8cc4",
    REACT_APP_TEMPLET_ID: "itmpl_qdm1ZSBsUNorA5UYRiZGfZUy",
    REACT_APP_PERSONA_ENV: "sandbox",
    REACT_APP_IDLE_TIME: 36_000 * 100,
    REACT_APP_IDLE_Prompt_Before_Idle: 6000 * 30,
    REACT_APP_FAQs: "https://sponsorqa.bolddesk.com/kb",
    REACT_APP_Boldesk: "https://sponsorqa.bolddesk.com/support/tickets",
    REACT_APP_Community: "https://avestor.slack.com/",
    REACT_APP_Chat_Bot:
      "https://web.powerva.microsoft.com/environments/Default-4e1b9b16-429c-41e3-9ded-d8f13e4cd73a/bots/cr0d5_avestorSupport/webchat?__version__=2",
  },
  uat: {
    REACT_APP_HELP_CENTER_URL: "https://sponsoruat.bolddesk.com",

    REACT_APP_API_SPONSOR_BASEURL:
      "https://portal-sponsor-uat.azurewebsites.net/",

    REACT_APP_API_Tax_URL:
      "https://api-taxmanagement-uat.azurewebsites.net/api",
    REACT_APP_API_SPONSOR_URL: "https://api-sponsor-uat.azurewebsites.net/api",
    REACT_APP_API_REPORTS_URL: "https://api-reports-uat.azurewebsites.net/api",
    REACT_APP_API_ACCOUNTING:
      "https://api-accounting-uat.azurewebsites.net/api",
    REACT_APP_API_FUND_MANAGEMENT_URL:
      "https://api-fundmanagement-uat.azurewebsites.net/api",
    REACT_APP_API_INVESTMENT_URL:
      "https://api-investment-uat.azurewebsites.net/api",
    REACT_APP_API_OPERATION_URL:
      "https://api-operations-uat.azurewebsites.net/api",
    REACT_APP_API_INVOICE_URL: "https://api-invoice-uat.azurewebsites.net/api",
    REACT_APP_API_ACCREDITATION_URL:
      "https://api-accreditation-uat.azurewebsites.net/api",
    REACT_APP_API_ESIGN_URL: "https://api-esign-uat.azurewebsites.net/api",
    REACT_APP_API_KYC_PERSONA_URL: "https://api-ekyc-uat.azurewebsites.net/api",
    REACT_APP_API_ADMIN_MANAGEMENT_URL:
      "https://api-admin-uat.azurewebsites.net/api",
    REACT_APP_API_ENTITLEMENT_URL:
      "https://api-entitlement-uat.azurewebsites.net/api",
    REACT_APP_API_NOTIFICATION_MANAGEMENT_URL:
      "https://api-notificationmanagement-uat.azurewebsites.net/api",
    REACT_APP_API_FileManager_URL:
      "https://api-fundassets-uat.azurewebsites.net/api",
    REACT_APP_API_ASSETS_SPNG_URL:
      "https://api-fundassets-uat.azurewebsites.net/api",
    REACT_APP_API_INVESTOR_SPNG_URL:
      "https://api-investor-uat.azurewebsites.net/api",
    REACT_APP_API_FINANCE_URL: "https://api-finance-uat.azurewebsites.net/api",

    REACT_APP_LOGO:
      "https://strgaccsponsorb2cflowuat.blob.core.windows.net/b2c/common/avestor-logo.png",
    REACT_APP_DOMAIN_NAME: "https://sponsoruat.b2clogin.com/",
    REACT_APP_DEFAULT_FUND_NAME: "https://www.avestorinc.com/",
    REACT_APP_TENANT_NAME: "sponsoruat.onmicrosoft.com/",
    // REACT_APP_SIGN_IN: "b2c_1_login",
    REACT_APP_SIGN_IN: "B2C_1A_SIGNIN",
    REACT_APP_SIGN_UP: "B2C_1A_SIGNUP",
    REACT_APP_CHANGE_PASSWORD: "B2C_1A_PASSWORDCHANGE",
    REACT_APP_FORGOT_PASSWORD: "B2C_1A_FORGOTPASSWORD",
    REACT_APP_DWOLLA_CONFIGURE: "sandbox",
    // REACT_APP_FORGOT_PASSWORD: "b2c_1a_passwordchange",
    APP_EDIT_PROFILE: "b2c_1_investor-editprofile",
    REACT_APP_EDIT_PROFILE: "b2c_1_investor-editprofile",
    REACT_APP_AUTHORITY_DOMAIN: "sponsoruat.b2clogin.com",
    REACT_APP_CLIENT_ID: "8123cd27-eff9-403b-bd2e-ba7bcbbe5792",
    REACT_APP_TEMPLET_ID: "itmpl_qdm1ZSBsUNorA5UYRiZGfZUy",
    REACT_APP_PERSONA_ENV: "sandbox",
    REACT_APP_IDLE_TIME: 36_000 * 100,
    REACT_APP_IDLE_Prompt_Before_Idle: 6000 * 30,
    REACT_APP_FAQs: "https://sponsor-uat.bolddesk.com/kb",
    REACT_APP_Boldesk: "https://sponsor-uat.bolddesk.com/support/tickets",
    REACT_APP_Community: "https://avestor.slack.com/",
    REACT_APP_Chat_Bot:
      "https://web.powerva.microsoft.com/environments/Default-4e1b9b16-429c-41e3-9ded-d8f13e4cd73a/bots/cr0d5_avestorSupport/webchat?__version__=2",
  },
  prod: {
    REACT_APP_HELP_CENTER_URL: "https://support-sponsor.avestorinc.com",
    REACT_APP_API_SPONSOR_BASEURL: "https://sponsor.avestorinc.com/",

    REACT_APP_API_Tax_URL: "https://api-taxmanagement.azurewebsites.net/api",
    REACT_APP_API_SPONSOR_URL: "https://api-sponsor.azurewebsites.net/api",
    REACT_APP_API_REPORTS_URL: "https://api-reports.azurewebsites.net/api",
    REACT_APP_API_ACCOUNTING: "https://api-accounting.azurewebsites.net/api",
    REACT_APP_API_FUND_MANAGEMENT_URL:
      "https://api-fundmanagement.azurewebsites.net/api",
    REACT_APP_API_INVESTMENT_URL:
      "https://api-investment.azurewebsites.net/api",
    REACT_APP_API_OPERATION_URL:
      "https://api-operationsav.azurewebsites.net/api",
    REACT_APP_API_INVOICE_URL: "https://api-invoiceav.azurewebsites.net/api",
    REACT_APP_API_ACCREDITATION_URL:
      "https://api-accreditation.azurewebsites.net/api",
    REACT_APP_API_ESIGN_URL: "https://api-esignav.azurewebsites.net/api",
    REACT_APP_API_KYC_PERSONA_URL: "https://api-kycav.azurewebsites.net/api",
    REACT_APP_API_ADMIN_MANAGEMENT_URL:
      "https://api-adminmanagement.azurewebsites.net/api",
    REACT_APP_API_ENTITLEMENT_URL:
      "https://api-entitlement.azurewebsites.net/api",
    REACT_APP_API_NOTIFICATION_MANAGEMENT_URL:
      "https://api-notificationmanagement.azurewebsites.net/api",
    REACT_APP_API_FileManager_URL:
      "https://api-fundassets.azurewebsites.net/api",
    REACT_APP_API_ASSETS_SPNG_URL:
      "https://api-fundassets.azurewebsites.net/api",
    REACT_APP_API_INVESTOR_SPNG_URL:
      "https://api-investor.azurewebsites.net/api",
    REACT_APP_API_FINANCE_URL: "https://api-financeav.azurewebsites.net/api",

    REACT_APP_LOGO:
      "https://strgaccsponsorb2cflow.blob.core.windows.net/b2c/common/avestor-logo.png",
    REACT_APP_DOMAIN_NAME: "https://login-sponsor.avestorinc.com/",
    REACT_APP_DEFAULT_FUND_NAME: "https://www.avestorinc.com/",
    REACT_APP_TENANT_NAME: "sponsorav.onmicrosoft.com/",
    // REACT_APP_SIGN_IN: "b2c_1_login",
    REACT_APP_SIGN_IN: "B2C_1A_SIGNIN",
    REACT_APP_SIGN_UP: "B2C_1A_SIGNUP",
    REACT_APP_CHANGE_PASSWORD: "B2C_1A_PASSWORDCHANGE",
    REACT_APP_FORGOT_PASSWORD: "B2C_1A_FORGOTPASSWORD",
    REACT_APP_DWOLLA_CONFIGURE: "production",
    // REACT_APP_FORGOT_PASSWORD: "b2c_1a_passwordchange",
    APP_EDIT_PROFILE: "b2c_1_investor-editprofile",
    REACT_APP_EDIT_PROFILE: "b2c_1_investor-editprofile",
    REACT_APP_AUTHORITY_DOMAIN: "login-sponsor.avestorinc.com",
    REACT_APP_CLIENT_ID: "0a26b3f6-3b4e-4b3b-b88f-36eb85728f6e",
    REACT_APP_TEMPLET_ID: "itmpl_qdm1ZSBsUNorA5UYRiZGfZUy",
    REACT_APP_PERSONA_ENV: "production",
    REACT_APP_IDLE_TIME: 36_000 * 100,
    REACT_APP_IDLE_Prompt_Before_Idle: 6000 * 30,
    REACT_APP_FAQs: "https://support-sponsor.avestorinc.com/kb",
    REACT_APP_Boldesk: "https://support-sponsor.avestorinc.com/support/tickets",
    REACT_APP_Community: "https://avestor.slack.com/",
    REACT_APP_Chat_Bot:
      "https://web.powerva.microsoft.com/environments/Default-4e1b9b16-429c-41e3-9ded-d8f13e4cd73a/bots/cr0d5_avestorSupport/webchat?__version__=2",
  },
};

export const getConfig = (env: string) => {
  env = env.toLocaleLowerCase();
  // console.log("lower case", env);
  switch (env) {
    case "local": {
      // return config.uat;
      return config.uat;
    }
    case "dev": {
      return config.dev;
      // return config.demo;
    }
    case "demo": {
      return config.demo;
    }
    case "qa": {
      return config.qa;
    }
    case "uat": {
      return config.uat;
    }
    case "prod": {
      return config.prod;
    }
    // No default
  }
};
