import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";

// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";
import DataTableComponent from "../../../components/data-table/DataTableComponent";

import Loader from "../../../components/loader/Loader";
import { AddExpensesThunk } from "../../../store/features/accounting/fundlevelexpences/addexpensex/slice";
import { DeleteExpenseThunk } from "../../../store/features/accounting/fundlevelexpences/deleteexpenses/slice";
import { GetTotalYearMonthExpensesThunk } from "../../../store/features/accounting/fundlevelexpences/gettotalmonthexpence/slice";
import { GetYearMonthExpensesListThunk } from "../../../store/features/accounting/fundlevelexpences/getyearmonthexpenselist/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import { regexForNumberGreaterThanZero } from "../../../utils/sponsor/constants";
import {
  infoToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function AddFundLevelExpenses() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const [openDailog, setOpenDailog] = useState(false);

  const GetUserList = useAppSelector(
    (state) => state.GetYearMonthExpensesList?.data
  );

  //   const GetTotalExpense = useAppSelector(
  //     (state) => state.GetTotalYearMonthExpenses?.data
  //   );
  const [id, setId] = useState<any>();
  const [Dataa, setData] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loading, setloading] = useState<any>(false);
  const [edit, setEdit] = useState<any>(false);

  const profileData = useAppSelector((state) => state.profile.data![0]);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const months = [
    {
      key: "01",
      value: "01",
    },
    {
      key: "02",
      value: "02",
    },
    {
      key: "03",
      value: "03",
    },
    {
      key: "04",
      value: "04",
    },
    {
      key: "05",
      value: "05",
    },
    {
      key: "06",
      value: "06",
    },
    {
      key: "07",
      value: "07",
    },
    {
      key: "08",
      value: "08",
    },
    {
      key: "09",
      value: "09",
    },
    {
      key: "10",
      value: "10",
    },
    {
      key: "11",
      value: "11",
    },
    {
      key: "12",
      value: "12",
    },
  ];

  const formik = useFormik({
    initialValues: {
      Year: "",
      Month: "",
      Monthly: 0,
      Annual: "",
    },
    validate: (data: any) => {
      const errors: any = {};

      console.log(" validate", data);
      if (!data.Year) {
        errors.Year = "Please enter year";
      }
      if (!data.Month) {
        errors.Month = "Please select month";
      }
      if (!data.Monthly) {
        errors.Monthly = "Please enter monthly expenses";
      }
      if (data.Monthly && !regexForNumberGreaterThanZero.test(data.Monthly)) {
        errors.Monthly = "Number should be greater than 0";
      }
      if (data.Monthly && data.Monthly.toString().length >= 9) {
        errors.Monthly = "Number should be 8 digit";
      }

      return errors;
    },
    onSubmit: async (data: any) => {
      //
      setloading(true);

      const saveParam = {
        legalEntityId: fundDropdown.fundid,
        year: formik.values.Year.toString(),
        month: formik.values.Month,
        expenses: +formik.values.Monthly,
        createdBy: profileData.userID,
        Action: edit ? "Update" : "Create",
      };
      dispatch(AddExpensesThunk(saveParam))
        .then((res: any) => {
          const p = {
            LegalEntityId: fundDropdown.fundid,
            YearMonth: +formik.values.Year,
          };

          if (res.payload.data.ifExist) {
            setAlert(true);
          } else {
            if (edit) {
              successToastMessage(toast, "Expenses updated successfully");
            } else {
              successToastMessage(toast, "Expenses added successfully");
            }
            formik.resetForm();
          }
          dispatch(GetYearMonthExpensesListThunk(p));
          edit && setEdit(false);
        })
        .finally(() => {
          setloading(false);
        });
    },
  });
  console.log("test", +formik.values.Monthly);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const deleteButton = (rowData: any) => {
    console.log("delete...-", rowData);
    const handleDeleteClick = () => {
      setId(rowData.id);
      setData(rowData);
      setEdit(false);
      formik.setFieldValue("Monthly", "");
      formik.setFieldValue("Month", "");
      setVisible(true);
    };
    return (
      <div className="flex flex-column ">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              handleDeleteClick();
            }}
          >
            <span className="text-white">Delete</span>
          </Button>
        </div>
      </div>
    );
  };

  const handleYearChange = (e: any) => {
    const selectedYear = new Date(Number(e.target.value)).getFullYear();
    formik.setFieldValue("Year", selectedYear);

    const param = {
      LegalEntityId: fundDropdown.fundid,
      YearMonth: +selectedYear,
    };

    console.log("tetst---------------------", formik.values);
    setloading(true);
    dispatch(GetYearMonthExpensesListThunk(param))
      .then(() => {
        setloading(false);
      })
      .catch(() => {
        setloading(false);
      });
    dispatch(GetTotalYearMonthExpensesThunk(param)).then((res: any) => {
      setloading(false);
      console.log("GetTotalExpense", res.payload.data[0].totalMonthlyExpenses);

      formik.setFieldValue("Annual", res.payload.data[0].totalMonthlyExpenses);
    });
  };
  const editButton = (rowData: any) => {
    const yearParts = rowData.yearMonth.split("-");
    const year = yearParts[0];
    const month = yearParts[1];

    console.log("delete...-", year);

    const handelOnClick = () => {
      setEdit(true);
      formik.setFieldValue("Year", year);
      formik.setFieldValue("Month", month);
      formik.setFieldValue("Monthly", rowData.monthlyExpenses);
      const param = {
        LegalEntityId: fundDropdown.fundid,
        YearMonth: +formik.values.Year,
      };
      dispatch(GetTotalYearMonthExpensesThunk(param)).then((res: any) => {
        setloading(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Optional: adds smooth scrolling animation
        });
        console.log(
          "GetTotalExpense",
          res.payload.data[0].totalMonthlyExpenses
        );

        formik.setFieldValue(
          "Annual",
          res.payload.data[0].totalMonthlyExpenses
        );
      });
    };
    return (
      <div className="flex flex-column ">
        <div className="flex align-items-center justify-content-center">
          <Button
            className="btn-dialog align-items-center justify-content-center"
            onClick={handelOnClick}
          >
            <span className="text-white">Edit</span>
          </Button>
        </div>
      </div>
    );
  };
  const onDelete = () => {
    setloading(true);
    const deleteParms = {
      ExpenseId: id,
      LegalEntityId: fundDropdown.fundid,
      YearMonth: formik.values.Year.toString(),
    };
    dispatch(DeleteExpenseThunk(deleteParms))
      .then(() => {
        const param = {
          LegalEntityId: fundDropdown.fundid,
          YearMonth: +formik.values.Year,
        };
        dispatch(GetYearMonthExpensesListThunk(param));
        formik.setFieldValue("Annual", "");
        if (Dataa.isAllocated) {
          infoToastMessage(toast, "Can not delete expenses after distribution");
        } else {
          successToastMessage(toast, "Deleted successfully");
        }
      })
      .finally(() => {
        setloading(false);
      });
  };
  const formatCurrency = (rowData: any) => {
    const finalValue = Number(rowData.monthlyExpenses);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const dataTableElements = [
    {
      field: "legalEntityName",
      header: "Fund",
      style: { minWidth: "180px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.legalEntityName}>
          {rowData?.legalEntityName}
        </div>
      ),
    },
    {
      field: "monthlyExpenses",
      header: "Monthly Expenses",
      body: formatCurrency,
    },
    {
      field: "yearMonth",
      header: "Year Month",
      dataType: "date",
    },

    {
      field: "",
      header: "",
      body: (rowData: any) => deleteButton(rowData),
    },
    {
      field: "",
      header: "",
      body: (rowData: any) => editButton(rowData),
    },
  ];
  useEffect(() => {
    console.log("tetst---------------------");
    formik.setFieldValue("Annual", "");

    formik.resetForm();
  }, [fundDropdown?.fundid]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <div className="grid">
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Add Fund level Expenses</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                GetUserList === undefined ||
                GetUserList?.length === 0 ||
                GetUserList === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to delete ?
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
                onDelete();
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              No
            </Button>
          </div>
        </Dialog>
        <Dialog
          visible={alert}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setAlert(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            {` Expenses for ${formik.values.Month}-${formik.values.Year} have already been entered`}
          </h4>

          <div className=" align-self-center flex align-items-center justify-content-center">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setAlert(false);
                formik.resetForm();
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto
"
            >
              Ok
            </Button>
          </div>
        </Dialog>
        <div className="col-12">
          <Card>
            <div className="formgrid grid px-2">
              <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-3">
                <label className="inputLabel">Year *</label>

                <Calendar
                  name="Year"
                  value={
                    formik.values.Year === null || formik.values.Year === ""
                      ? undefined
                      : new Date(+formik.values.Year, 0, 1)
                  }
                  onChange={(e) => {
                    // const selectedYear = new Date(
                    //   Number(e.target.value)
                    // ).getFullYear();
                    // formik.setFieldValue("Year", selectedYear);
                    handleYearChange(e);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Year"),
                    "card-inputBox": true,
                  })}
                  view="year"
                  dateFormat="yy"
                  disabled={edit}
                  placeholder="Year"
                />
                {getFormErrorMessage("Year")}
              </div>
              <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-3">
                <label className="inputLabel">Month *</label>
                <Dropdown
                  name="Month"
                  style={{ width: "100%" }}
                  options={months}
                  optionLabel="value"
                  optionValue="key"
                  placeholder="Select Month"
                  onChange={(e) => formik.setFieldValue("Month", e.value)}
                  value={formik.values.Month}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Month"),
                    "card-inputBox": true,
                  })}
                  disabled={edit}
                />
                {getFormErrorMessage("Month")}
              </div>
              <div className="d-block sm:d-flex sm:flex-column  col-12 sm:col-3">
                <label className="inputLabel">Total Monthly Expenses *</label>
                {/* <InputText
                  name="Monthly"
                  value={formik.values.Monthly}
                  onChange={(e) => {
                    // Remove non-digit characters from the input
                    const sanitizedValue = e.target.value.replace(
                      /[^\d.]/g,
                      ""
                    );

                    formik.setFieldValue("Monthly", sanitizedValue);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Monthly"),
                    "card-inputBox": true,
                  })}

                  // placeholder="First Name"
                /> */}

                <InputNumber
                  inputId="currency-us"
                  name="Monthly"
                  value={
                    formik.values.Monthly ? formik.values.Monthly : undefined
                  }
                  onValueChange={(e: any) =>
                    formik.setFieldValue("Monthly", e.target.value)
                  }
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  min={0}
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Monthly"),
                    "w-100": true,
                  })}
                  placeholder="Total Monthly Expenses"
                />
                {getFormErrorMessage("Monthly")}
              </div>
              <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-3 annualClass">
                <label className="inputLabel">Total Annual Expenses</label>
                <InputText
                  name="Annual"
                  value={formik.values.Annual}
                  onChange={(e) =>
                    formik.setFieldValue("Annual", e.target.value)
                  }
                  className={classNames({
                    "p-invalid": isFormFieldInvalid("Annual"),
                    "card-inputBox": true,
                  })}
                  disabled
                  placeholder="Total Annual Expenses"
                />
                {getFormErrorMessage("Annual")}
              </div>
            </div>
            <div className="formgrid grid px-2 flex-wrap flex justify-content-center">
              <div className="d-flex flex-column col-12 sm:col-4 ">
                <label className="inputLabel"></label>
                <Button
                  className=" btn-nav flex justify-content-center"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    // setIsFormSubmitted(true);
                    console.log("clicked");
                    formik.handleSubmit();
                  }}
                >
                  {edit ? "Update Expenses" : "Add Expenses"}
                </Button>
              </div>
              <div className="d-flex flex-column col-12 sm:col-4 ">
                <label className="inputLabel"></label>
                <Button
                  className=" btn-nav flex justify-content-center"
                  onClick={() => {
                    formik.resetForm();
                    edit && setEdit(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>

          {formik.values.Year && (
            // <div className="card my-3 tabWithoutboxshadow">
            <Card>
              <DataTableComponent
                valueData={GetUserList!}
                fieldsElements={dataTableElements}
                isPaginationAllowed={true}
                className="gridcell deletebtn"
                columnResizeMode="expand"
                maxHeight={"calc(-370px + 100vh)"}
                // isFullView={true}
                setOpenDailog={setOpenDailog}
                openDailog={openDailog}
                resizableColumns={true}
                scrollable={true}
              />
            </Card>
            // </div>
          )}
        </div>
      </div>
    </>
  );
}
