import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  // dateFormatter,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";

interface IProps {
  // p: {
  //   pageName: string;
  //   productID: number;
  //   productName: string;
  //   city: string;
  //   state: string;
  //   productPrimaryImage: string;
  // };
  products: any;
  layout: string;
  stateOffering: string;
}
const ListComponent: React.FC<IProps> = (props) => {
  // const ID: string = props.p.productID.toString();
  const [tableData, setTableData] = useState<any>();
  const navigate = useNavigate();
  const formatDate = (rowData: any) => {
    // const dateFormat = dateFormatter(rowData);
    const dateFormat = formatDateMMDDYYY(rowData);

    return dateFormat;
  };
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const actionBodyViewTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          // icon="pi pi-pencil"
          className=" btn-nav p-3"
          onClick={() =>
            rowData.pooledfunds === "Continuous"
              ? navigate(`/Continuous/ManageOffering/${rowData.productID}`)
              : navigate(`/ManageOffering/${rowData.productID}`)
          }
          type="button"
        >
          <span className="ml-auto mr-auto ">View Details</span>
        </Button>
      </React.Fragment>
    );
  };
  const formatCurrencyAnyData = (rowData: any) => {
    console.log("amount", rowData);
    const finalValue = Number(rowData);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };

  const dataElement = [
    {
      field: "supplierProductCode",
      header: "Lookup Code",
      style: { width: "17%" },
    },
    {
      field: "productName",
      header: "Deal Name",
      bodyClassName: "font-bold",
      // style: { width: "17%" },
    },
    {
      field: "assetClass",
      header: "Asset Class",
    },
    {
      field: "targetRaiseAmt",
      header: "Target Raise Amount",
      body: (rowData: any) => formatCurrencyAnyData(rowData.targetRaiseAmt),
    },
    {
      field: "targetCloseDate",
      header: "Target Close Date",
      body: (rowData: any) => formatDate(rowData.targetCloseDate),
      dataType: "date",
      filterElement: dateFilterTemplate,
    },
    {
      // field: "",
      header: "",
      body: actionBodyViewTemplate,
      style: { width: "13%" },
    },
  ];

  const dataElementInvestment = [
    {
      field: "supplierProductCode",
      header: "Lookup Code",
      style: { width: "17%" },
    },
    {
      field: "productName",
      header: "Investment Name",
      bodyClassName: "font-bold",
      style: { width: "17%" },
    },
    {
      field: "assetClass",
      header: "Asset Class",
    },
    {
      field: "listPrice",
      header: "Funding Amount",
      body: (rowData: any) => formatCurrencyAnyData(rowData.listPrice),
      // headerClassName: "tableheaderInvestment",
    },
    {
      field: "fundingDate",
      header: "Funding Date",
      body: (rowData: any) => formatDate(rowData.fundingDate),
      dataType: "date",
      filterElement: dateFilterTemplate,
    },
    {
      // field: "",
      header: "",
      body: actionBodyViewTemplate,
      // style: { width: "17%" },
    },
  ];
  useEffect(() => {
    if (props.products) {
      setTableData(
        getListDateConversion(props.products, [
          "targetCloseDate",
          "fundingDate",
        ])
      );
    }
  }, [props.products]);

  return (
    <>
      <div className="col-12 col-sm-11 col-md-12  col-xl-12 p-0 ">
        <div className="card ProdCard">
          <Card>
            <DataTableComponent
              valueData={tableData !== null ? tableData : []}
              fieldsElements={
                props.stateOffering === "SoftCommit"
                  ? dataElement
                  : dataElementInvestment
              }
              isPaginationAllowed={false}
              noGridLines={true}
              className="tableheaderInvestment"
            />
            {/* <div className="prodimg-wrap">
              <Image src={img} alt="Image" imageClassName="ProdImg" />
            </div> */}
            {/* <div className="propDetail-wrap  flex  col-12">
              <div className=" propDetailTitle-wrap col-4">
                <div className="propName flex justify-content-start align-content-start">
                  {props.p.productName}
                </div>
              </div>
              <div className="propAddr  col-4 flex justify-content-center">
                {props.p.city}, {props.p.state}
              </div>

              <div className="button flex justify-content-end text-center  col-4">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    // localStorage.setItem("navigationPage", "Opportunities");
                   
                  }}
                  className="btn-dialog "
                >
                  View Details
                </Button>
                {/* </Link> 
              </div>
            </div> */}
          </Card>
        </div>
      </div>
    </>
  );
};
export default ListComponent;
