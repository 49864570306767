import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

// import { Skeleton } from "primereact/skeleton";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { GetLPOwnershipDataThunk } from "../../../store/features/taxmanagement/ownershipchangereport/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import {
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../utils/date-formatter";
import { exportExcel } from "../../../utils/excel-dataformat";
// import dateFormatter from "../../../utils/date-formatter";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
export default function OwnershipChangeReport() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const previousYear: any = new Date().getFullYear();
  const currentYear = previousYear - 1;
  const getRevision = useAppSelector((state) => state.GetRevisionList.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const productList = useAppSelector((state) => state.GetProdductList?.data);
  const portState = useAppSelector((state) => state.GetLPOwnershipData.loading);
  console.log("getRevision", getRevision);
  console.log("productList", productList);

  const GetMenuList = useAppSelector((state) => state.GetLPOwnershipData?.data);
  const [lpOwnerShipData, setLPOwnerShipData] = useState<any>([]);

  const [stateName, setName] = useState<any>(false);
  const [loading, setloading] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const formatDate = (rowData: any) => {
    const dateFormat = formatDateMMDDYYY(rowData);
    return dateFormat;
  };

  const formik = useFormik({
    initialValues: {
      Date: currentYear.toString(),
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter the year";
      }

      return errors;
    },
    onSubmit: async () => {
      setloading(true);
      const params = {
        LegalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      dispatch(GetLPOwnershipDataThunk(params))
        .then((res: any) => {
          setName(false);
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    },
  });
  useEffect(() => {
    if (formik.values.Date.toString() === currentYear.toString()) {
      setloading(true);
      const params = {
        LegalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      dispatch(GetLPOwnershipDataThunk(params))
        .then((res: any) => {
          setName(false);
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    } else {
      setName(true);
    }
  }, [formik.values.Date]);
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const highlightRow = (rowData: any, fn: any, shouldToolTip: any = false) => {
    if (!rowData?.partnerId) {
      return (
        <span
          className="dt-cell-highlight left-align"
          title={shouldToolTip || undefined}
        >
          {fn()}
        </span>
      );
    }
    return (
      <span className="left-align" title={shouldToolTip || undefined}>
        {fn()}
      </span>
    );
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const dataTableElements = [
    {
      field: "partnerId",
      header: "Partner Id",
      body: (rowData: any) => highlightRow(rowData, () => rowData.partnerId),
      style: { minWidth: "250px" },
    },
    {
      field: "invName",
      header: "Investor Account",
      body: (rowData: any) =>
        highlightRow(rowData, () => rowData.invName, rowData.invName),
      style: { minWidth: "250px" },
    },
    {
      field: "legalEntityName",
      header: "Fund Name",
      body: (rowData: any) =>
        highlightRow(
          rowData,
          () => rowData.legalEntityName,
          rowData.legalEntityName
        ),
      style: { minWidth: "250px" },
    },
    {
      field: "partnerType",
      header: "Partner Type",
      body: (rowData: any) => highlightRow(rowData, () => rowData.partnerType),
      style: { minWidth: "250px" },
    },
    {
      field: "cumulativeAmt",
      header: "Capital Prior To Change",
      body: (rowData: any) =>
        highlightRow(rowData, () => currencyFormat(rowData.cumulativeAmt)),
      style: { minWidth: "250px" },
    },
    {
      field: "transactionDate",
      header: "Transaction Date",
      dataType: "date",
      body: (rowData: any) =>
        highlightRow(rowData, () => formatDate(rowData.transactionDate)),
      filterElement: dateFilterTemplate,
      style: { minWidth: "250px" },
    },
    {
      field: "cumulativeAmtAfter",
      header: "Capital After Change",
      body: (rowData: any) =>
        highlightRow(rowData, () => currencyFormat(rowData.cumulativeAmtAfter)),
      style: { minWidth: "250px" },
    },

    {
      field: "cumulativeAmtChange",
      header: "Capital Change",
      body: (rowData: any) =>
        highlightRow(rowData, () =>
          currencyFormat(rowData.cumulativeAmtChange)
        ),
      style: { minWidth: "250px" },
    },
    {
      field: "cumulativeAmtPer",
      header: "% Held Prior To Change",
      body: (rowData: any) =>
        highlightRow(rowData, () => rowData.cumulativeAmtPer),
      style: { minWidth: "250px" },
    },
    {
      field: "cumulativeAmtAfterPer",
      header: "% Held After Change",
      body: (rowData: any) =>
        highlightRow(rowData, () => rowData.cumulativeAmtAfterPer),
      style: { minWidth: "250px" },
    },
    {
      field: "cumulativeAmtChangePer",
      header: "Change In % On Date",
      body: (rowData: any) =>
        highlightRow(rowData, () => rowData.cumulativeAmtChangePer),
      style: { minWidth: "250px" },
    },
  ];

  const exportExcelHeader = [
    {
      field: "partnerId",
      header: "Partner Id",
    },
    {
      field: "invName",
      header: "Investor Account",
    },
    {
      field: "legalEntityName",
      header: "Fund Name",
    },
    {
      field: "partnerType",
      header: "Partner Type",
    },
    {
      field: "cumulativeAmt",
      header: "Capital Prior To Change",
      dataType: "currency",
    },
    {
      field: "transactionDate",
      header: "Transaction Date",
      dataType: "date",
    },
    {
      field: "cumulativeAmtAfter",
      header: "Capital After Change",
      dataType: "currency",
    },
    {
      field: "cumulativeAmtChange",
      header: "Capital Change",
      dataType: "currency",
    },
    {
      field: "cumulativeAmtPer",
      header: "% Held Prior To Change",
      dataType: "percent",
    },
    {
      field: "cumulativeAmtAfterPer",
      header: "% Held After Change",
      dataType: "percent",
    },
    {
      field: "cumulativeAmtChangePer",
      header: "Change In % On Date",
      dataType: "percent",
    },
  ];

  useEffect(() => {
    formik.resetForm();
    // dispatch(resetGetLPOwnershipData());
    const params = {
      LegalEntityId: fundDropdown.fundid,
      Year: formik.values.Date,
    };
    dispatch(GetLPOwnershipDataThunk(params))
      .then((res: any) => {
        setName(false);
        setloading(false);
      })
      .catch(() => {
        setloading(false);
      })
      .finally(() => {
        setloading(false);
      });
  }, [fundDropdown.fundid]);

  useEffect(() => {
    if (GetMenuList !== undefined && GetMenuList.length > 0) {
      setLPOwnerShipData(
        getListDateConversion(GetMenuList, ["transactionDate"])
      );
    } else {
      setLPOwnerShipData([]);
    }
  }, [GetMenuList]);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Fund Ownership Change Report</h1>
            <p className="pageSubHeader"> </p>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  exportExcelHeader,
                  lpOwnerShipData,
                  "Fund Ownership Change Report",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                lpOwnerShipData === undefined ||
                lpOwnerShipData?.length === 0 ||
                lpOwnerShipData === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  lpOwnerShipData === undefined ||
                  lpOwnerShipData?.length === 0 ||
                  lpOwnerShipData === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <>
              <div>
                <div className="formgrid grid px-2 col">
                  <div className="d-block sm:d-flex sm:flex-column mr-3 md:col-6 col-12">
                    <div className="card flex justify-content-center">
                      <Calendar
                        name="Date"
                        value={
                          formik.values.Date === null ||
                          formik.values.Date === ""
                            ? undefined
                            : new Date(+formik.values.Date, 0, 1)
                        }
                        onChange={(e) => {
                          const selectedYear = new Date(
                            Number(e.value)
                          ).getFullYear();
                          formik.setFieldValue("Date", selectedYear);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Date"),
                          "card-inputBox": true,
                        })}
                        placeholder="Select year"
                        view="year"
                        dateFormat="yy"
                      />
                      {getFormErrorMessage("Date")}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="mr-4 btn-nav w-7rem justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      className="btn-nav w-7rem justify-content-center"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        console.log("clicked");
                        formik.handleSubmit();
                      }}
                    >
                      Show
                    </Button>
                  </div>
                </div>
              </div>
            </>{" "}
            {GetMenuList && !stateName && (
              <div>
                {portState === "loading" ? (
                  <Skeleton />
                ) : (
                  <DataTableComponent
                    valueData={lpOwnerShipData || []}
                    fieldsElements={dataTableElements}
                    isPaginationAllowed={true}
                    // isDownload={true}
                    fileName="LPOwnership Change Report"
                    scrollable={true}
                    className="gridcell"
                    columnResizeMode="expand"
                    maxHeight={"calc(-235px + 100vh)"}
                    isFullView={true}
                    setOpenDailog={setOpenDailog}
                    openDailog={openDailog}
                  />
                )}
              </div>
            )}
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
