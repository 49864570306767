import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

// import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getThunkBlueSkyFilingReport } from "../../../../store/features/reports/blue-sky-filing/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  // dateFormatter,
  formatDateMMDDYYY,
  getListDateConversion,
} from "../../../../utils/date-formatter";
import { exportExcel } from "../../../../utils/excel-dataformat";
import { formatYesNo } from "../../../../utils/helper";
// import BlueSkyFilingSkeleton from "./BlueSkyFilingSkeleton";

export default function BlueSkyFiling() {
  const dispatch = useAppDispatch();
  const portState = useAppSelector((state) => state.BlueSkyFiling.loading);
  const blueSkyFiling = useAppSelector((state) => state.BlueSkyFiling.data);
  const [exitDate, setExitDate] = useState<any>();
  const [openDailog, setOpenDailog] = useState(false);

  // const getExitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       firstInvInStateFundingState: new Date(d.firstInvInStateFundingState),
  //       filingDate: d.filingDate ? new Date(d.filingDate) : d.filingDate,
  //       expiryDate: d.expiryDate ? new Date(d.expiryDate) : d.expiryDate,
  //       invDate: d.invDate ? new Date(d.invDate) : d.invDate,
  //     };
  //     return modifiedExitDate;
  //   });
  // };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const formatDate = (rowData: any) => {
    const date = new Date(rowData.firstInvInStateFundingDate);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${month}/${day}/${year}`;
  };
  // Formatted date

  const formatFilingDate = (rowData: any) => {
    return formatDateMMDDYYY(rowData.filingDate);
  };

  const formatExpiryDate = (rowData: any) => {
    return formatDateMMDDYYY(rowData.expiryDate);
  };

  const invoiceDate = (rowData: any) => {
    return formatDateMMDDYYY(rowData.invoiceDate);
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const formatCurrency = (rowData: any) => {
    const finalValue = Number(rowData.fees);
    const parseValue = currencyFormat(Number(finalValue));
    return `${parseValue}`;
  };
  const excelTableHeaders = [
    {
      field: "state",
      header: "State",
    },
    {
      field: "firstInvInStateFundingDate",
      header: "1st Investor Funding Date",
      dataType: "date",
    },
    {
      field: "filingFrequency",
      header: "Filing Frequency",
    },
    {
      field: "status",
      header: "Filed or Not Filed",
    },
    {
      field: "filingDate",
      header: "Last Filing Date",
      dataType: "date",
    },
    {
      field: "fees",
      header: "Amount Paid",
      dataType: "currency",
    },
    {
      field: "expiryDate",
      header: "Next Filing Date",
      dataType: "date",
    },
    {
      field: "invoiceDate",
      header: "Invoice Date",
      dataType: "date",
    },
    {
      field: "invoicePaid",
      header: "Invoice Paid",
      dataType: "yesno",
    },
    {
      field: "legalEntityName",
      header: "Legal Entity Name",
      dataType: "string",
    },
  ];
  const dataTableElements = [
    {
      field: "state",
      header: "State",
      style: { minWidth: "210px" },
    },
    {
      field: "firstInvInStateFundingDate",
      header: "1st Investor Funding Date",
      dataType: "date",
      body: formatDate,
      filterElement: dateFilterTemplate,
      style: { minWidth: "210px" },
    },
    {
      field: "filingFrequency",
      header: "Filing Frequency",
      style: { minWidth: "210px" },
    },
    {
      field: "status",
      header: "Filed or Not Filed",
      style: { minWidth: "210px" },
    },
    {
      field: "filingDate",
      header: "Last Filing Date",
      dataType: "date",
      body: formatFilingDate,
      filterElement: dateFilterTemplate,
      style: { minWidth: "210px" },
    },
    {
      field: "fees",
      header: "Amount Paid",
      body: formatCurrency,
      style: { minWidth: "210px" },
    },
    {
      field: "expiryDate",
      header: "Next Filing Date",
      dataType: "date",
      body: formatExpiryDate,
      filterElement: dateFilterTemplate,
      style: { minWidth: "210px" },
    },
    {
      field: "invoiceDate",
      header: "Invoice Date",
      dataType: "date",
      body: invoiceDate,
      filterElement: dateFilterTemplate,
      style: { minWidth: "210px" },
    },
    {
      field: "invoicePaid",
      header: "Invoice Paid",
      style: { minWidth: "210px" },
      body: (rowData: any) => formatYesNo(rowData.invoicePaid),
    },
    // {
    //   field: "id",
    //   header: "Id",
    // },
    // {
    //   field: "legalEntityId",
    //   header: "Legal Entity Id",
    // },
    // {
    //   field: "stateCode",
    //   header: "State Code",
    // },
    // {
    //   field: "filingType",
    //   header: "Filing Type",
    // },
  ];

  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  useEffect(() => {
    if (dropdown.fundid !== undefined && dropdown.fundid > 0) {
      const parameters = {
        legalEntityId: dropdown.fundid,
      };
      dispatch(getThunkBlueSkyFilingReport(parameters));
    }
  }, [dropdown.fundid]);
  useEffect(() => {
    setExitDate(
      getListDateConversion(blueSkyFiling, [
        "firstInvInStateFundingDate",
        "filingDate",
        "expiryDate",
        "invoiceDate",
      ])
    );
  }, [blueSkyFiling]);

  // let content;
  // switch (portState) {
  //   case "loading":
  //     content = <BlueSkyFilingSkeleton />;
  //     break;
  //   case "succeeded":
  //     content = (

  //     );
  //     break;
  // }
  return (
    <>
      {portState === "loading" ? <Loader /> : <></>}
      <div className="flex justify-content-between col-12 ">
        <div className="card pgHeaderWrap">
          <h1 className="pageHeader fs-2">Blue Sky Filing Report</h1>
          <p className="pageSubHeader"> </p>
        </div>
        <div className=" col-12 md:col-3 flex  gap-2 justify-content-end">
          <Button
            className="remove-shadow"
            type="button"
            // icon="pi pi-file"
            onClick={() =>
              exportExcel(
                excelTableHeaders,
                exitDate,
                "Blue Sky Filing Report",
                true
              )
            }
            data-pr-tooltip="exportExcel"
            disabled={
              exitDate === undefined ||
              exitDate?.length === 0 ||
              exitDate === null
            }
          >
            {" "}
            <span className="blueButtonShadowText">Download </span>
          </Button>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                exitDate === undefined ||
                exitDate?.length === 0 ||
                exitDate === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      {/* <div className="grid">
        <div className="col-12">
          <div className="card my-3"> */}
      {/* <Card> */}
      {/* <h4 className="cardHeader">Blue Sky Filling Report</h4> */}
      {/* <div className="row my-4 d-flex justify-content-between"></div> */}
      <DataTableComponent
        valueData={exitDate}
        fieldsElements={dataTableElements}
        isPaginationAllowed={true}
        // isDownload={true}
        fileName="Blue Sky Filing Report"
        scrollable={true}
        className="gridcell"
        // HeadingName="Blue Sky Filing Report"
        columnResizeMode="expand"
        isFullView={true}
        setOpenDailog={setOpenDailog}
        openDailog={openDailog}
        maxHeight={"calc(-160px + 100vh)"}
      />
      {/* </Card> */}
      {/* </div>
        </div> */}
      {/* </div> */}
    </>
  );
}
