import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetInputForm1099DivListService } from "../../../../../../../services/taxmanagement/tax";
import error from "../../../../../../../utils/error";
import { IInputForm1099DivState } from ".";

const initialState: IInputForm1099DivState = {
  success: false,
  message: "",
  loading: "loading",
  error: "",
  data: [],
};
export const getThunkInputForm1099DivList = createAsyncThunk(
  "inputform1099DIV/getlist",
  async (data: any) => {
    try {
      const response = await GetInputForm1099DivListService(data);
      return response.data;
    } catch (error_) {
      console.log("er", error_);
      throw new Error(error(error_));
    }
  }
);

const slice = createSlice({
  name: "inputform1099",
  initialState,
  reducers: {
    onResetInput1099DIV: (state) => {
      state.data = [];
    },
  },
  extraReducers(builder): void {
    builder.addCase(getThunkInputForm1099DivList.pending, (state) => {
      state.loading = "loading";
      //   state.error = undefined;
    });
    builder.addCase(getThunkInputForm1099DivList.fulfilled, (state, action) => {
      state.loading = "succeeded";
      console.log("set", action.payload);
      state.data = action.payload._response.data
        ? action.payload._response.data
        : [];
      state.success = action.payload._response.success;
    });
    builder.addCase(getThunkInputForm1099DivList.rejected, (state, action) => {
      state.loading = "failed";
      console.log("errorslice", action.error);
      //   // action.payload contains error information
      // state.error = error(action.payload);
      state.error = error(action.error);
    });
  },
});
export const { onResetInput1099DIV } = slice.actions;
export default slice.reducer;
