import { useFormik } from "formik";
import { Button } from "primereact/button";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
// import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";

import { RiAddFill } from "react-icons/ri";
import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { goToTop } from "../../../components/scroll-to/ScrollTo";
import { addUpdateFundThunk } from "../../../store/features/investment/addupdatesupplier/slice";
import { DeleteSupplierThunk } from "../../../store/features/investment/deletesupplier/slice";
import { getSupplierListThunk } from "../../../store/features/investment/getsupplierlist/slice";
import { getSupplierTypesThunk } from "../../../store/features/investment/getsuppliertypes/slice";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getListDateConversion } from "../../../utils/date-formatter";
import { isValidUrlRegexNew } from "../../../utils/investing-approval/multi-investing-account/validation";
import {
  errorToastMessage,
  infoToastMessage,
  successToastMessage,
} from "../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function ManageFundSupplier() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);

  //   const Viewapproveregistor = useAppSelector(
  //     (state) => state.GetInvestorList.data
  //   );

  const dropdownModel = useAppSelector((state) => state.legalEntityList.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [openDailog, setOpenDailog] = useState(false);

  const GetSupplierType = useAppSelector(
    (state) => state.GetSupplierTypes?.data
  );

  console.log("==GetSupplierType", GetSupplierType);

  const GetSupplierList = useAppSelector(
    (state) => state.GetSupplierList?.data
  );
  // console.log("entityList", entityList);

  const [exitDate, setExitDate] = useState<any>();
  //   const [leagalentity, setLeagalentity] = useState<any>();
  // const [supplierTypes, setSupplierTypes] = useState<any>("");
  // const [name, setName] = useState<any>("");
  const [phone, setPhone] = useState<any>("");
  // const [website, setWebsite] = useState<any>("");
  const [supplierId, setSupplierId] = useState<any>(0);
  const [loading, setloading] = useState<any>(false);

  // const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [add, setAdd] = useState(false);
  const [showTab, setShowTab] = useState(true);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);

  const legalEntityList: { code: number; name: string }[] = [];
  for (let i = 0; i < dropdownModel?.length; i++) {
    legalEntityList.push({
      code: dropdownModel![i].legalEntityID,
      name: dropdownModel![i].legalEntityName,
    });
  }
  // const handleChange = (e: any) => {
  //   return setLeagalentity(e.value);
  //   // Do something with the selected value
  // };
  const param = {
    LegalEntityId: fundDropdown?.fundid,
  };
  const formik = useFormik({
    initialValues: {
      supplierType: "",
      name: "",
      website: "",
    },
    validate: (data: any) => {
      //
      const errors: any = {};

      if (!data.supplierType) {
        errors.supplierType = " Supplier type is required ";
      }
      if (!data.name) {
        errors.name = " Supplier name is required ";
      }
      if (data.website && !isValidUrlRegexNew(data.website)) {
        errors.website = "Incorrect url format";
      }
      return errors;
    },
    onSubmit: async (data: any) => {
      console.log(data);
      const parameters = {
        supplierID: edit ? +supplierId : 0,
        legalEntityId: fundDropdown?.fundid,
        supplierName: data.name,
        supplierTypeID: data.supplierType,
        supplierPhone: phone ? "1-" + phone : "",
        supplierWebsite: data.website,
      };

      console.log("!error && phone.replace(/D/g", phone.length);

      if (!error && phone.length === 12) {
        setloading(true);
        setError(false);

        if (data.supplierType) {
          dispatch(addUpdateFundThunk(parameters))
            .then((res: any) => {
              res.payload.data[0][0].return_Value === "Success"
                ? successToastMessage(toast, "Data has been saved successfully")
                : errorToastMessage(toast, "Data has been saved successfully");

              setAdd(false);
              setEdit(false);
              setShowTab(true);
              dispatch(getSupplierListThunk(param));
            })
            .finally(() => {
              setloading(false);
            });
          dispatch(getSupplierTypesThunk());

          if (edit) {
            setEdit(false);
          }
        }
      } else {
        //
        setError(true);
      }
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (e: any) =>
    !!(formikTouched[e] && formikErrors[e]);

  const getFormErrorMessage = (e: any) => {
    return isFormFieldInvalid(e) ? (
      <small className="p-error">{formikErrors[e]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling animation
    });
  };

  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = () => {
      console.log("rowDataeditButton", rowData);
      console.log("Row data:", rowData.supplierTypeID);
      formik.setFieldValue("name", rowData.supplierName);
      formik.setFieldValue("supplierType", "" + rowData.supplierTypeID);
      formik.setFieldValue("website", rowData.supplierWebsite);

      // setPhone(rowData.supplierPhone);
      setPhone(
        rowData.supplierPhone.startsWith("1-")
          ? rowData.supplierPhone.slice(2)
          : rowData.supplierPhone
      );
      // setWebsite(rowData.supplierWebsite);
      setSupplierId(rowData.supplierID);
      setAdd(false);
      setEdit(true);
      setShowTab(false);
      handleScrollToTop();
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={handleEdit}
          >
            <span className="text-white">EDIT</span>
          </Button>
        </div>
      </div>
    );
  };

  const deleteButton = (rowData: any) => {
    return (
      <div className="flex flex-column ">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={(e) => {
              e.preventDefault();
              setSupplierId(rowData.supplierID);

              setVisible(true);
            }}
          >
            <span className="text-white">DELETE</span>
          </Button>
        </div>
      </div>
    );
  };

  const dataTableElements = [
    {
      field: "supplierName",
      header: "Supplier Name",
      style: { width: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.supplierName}>
          {rowData?.supplierName}
        </div>
      ),
      // style: { display: "flex", justifyContent: "flex-start" },
    },
    {
      field: "supplierType",
      header: "Supplier Type",
    },
    {
      field: "supplierPhone",
      header: "Supplier Phone",
    },
    {
      field: "supplierWebsite",
      header: "Website",
      style: { width: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.supplierWebsite}>
          {rowData?.supplierWebsite}
        </div>
      ),
      // style: { display: "flex", justifyContent: "flex-start" },
    },
    {
      // field: "targetReturn",
      header: "",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
    },
    {
      // field: "estExitDate",
      header: "",
      body: (rowData: any) => deleteButton(rowData),
    },
  ];

  const onDelete = () => {
    setVisible(false);
    setloading(true);
    console.log("supplier", supplierId);
    const deleteParms = {
      SupplierID: supplierId,
      LegalEntityId: fundDropdown?.fundid,
    };
    dispatch(DeleteSupplierThunk(deleteParms))
      .then((res: any) => {
        if (res.payload.message === "Supplier is already in use.") {
          infoToastMessage(toast, res.payload.message);
          formik.resetForm();
        } else {
          successToastMessage(toast, "Deleted Successfully");
          formik.resetForm();
        }
        dispatch(getSupplierListThunk(param));
      })
      .finally(() => {
        setloading(false);
        formik.resetForm();
        setPhone("");
      });
  };
  const resetForm = () => {
    setPhone("");
    // setWebsite("");
    setSupplierId("");
    setAdd(false);
    setEdit(false);
    setError(false);
    formik.resetForm();
  };

  useEffect(() => {}, [supplierId]);

  useEffect(() => {
    dispatch(getSupplierTypesThunk());
    // profileData?.legalEntityID && dispatch(getSupplierListThunk(param));
  }, []);

  useEffect(() => {
    if (fundDropdown?.fundid) {
      resetForm();
      setloading(true);
      dispatch(getSupplierListThunk(param))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    }
  }, [fundDropdown?.fundid]);

  const formatPhoneNumber = (value: string) => {
    // Remove non-digit characters
    const phoneNumberOnly = value ? value!.replace(/\D/g, "") : value;

    let maskedPhoneNumber = "";

    if (phoneNumberOnly) {
      if (phoneNumberOnly.length <= 3) {
        maskedPhoneNumber = phoneNumberOnly;
      } else if (phoneNumberOnly.length <= 6) {
        maskedPhoneNumber =
          phoneNumberOnly.slice(0, 3) + "-" + phoneNumberOnly.slice(3);
      } else {
        maskedPhoneNumber =
          phoneNumberOnly.slice(0, 3) +
          "-" +
          phoneNumberOnly.slice(3, 6) +
          "-" +
          phoneNumberOnly.slice(6, 10);
      }
    }

    return maskedPhoneNumber;
  };

  const onAddClick = () => {
    resetForm();
    setAdd(true);
    setEdit(false);
    setShowTab(true);
  };

  // useEffect(() => {
  //   supplierTypes && setError(false);
  // }, [supplierTypes, editButton]);

  useEffect(() => {
    setExitDate(
      getListDateConversion(GetSupplierList, [
        "firstInvInStateFundingState",
        "filingDate",
        "expiryDate",
        "invDate",
      ])
    );
  }, [GetSupplierList]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  useEffect(() => {
    if (add || edit) {
      document.body.classList.remove("no-scroll");
    } else {
      goToTop();
      document.body.classList.add("no-scroll");
    }
  }, [add, edit]);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Manage Fund Suppliers</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                exitDate === undefined ||
                exitDate?.length === 0 ||
                exitDate === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
      </div>
      <div className="grid">
        <Dialog
          visible={visible}
          className="w-75 md:w-3 DialogInvestment"
          onHide={() => setVisible(false)}
        >
          <h4 className="pageHeader text-center mb-5 pt-5">
            Are you sure you want to delete ?
          </h4>
          <div className=" align-self-center flex align-items-center justify-content-evenly mx-3">
            <Button
              onClick={onDelete}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto"
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setVisible(false);
              }}
              className="blueButtonShadow w-6rem mx-1 d-block h-auto"
            >
              No
            </Button>
          </div>
        </Dialog>

        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <div className="grid justify-content-start">
              {" "}
              {!add || (edit && <div className="tabHeader-wrap col-6"></div>)}
              {/* {showTab && ( */}
              {/* <div className="btn-navActive mr-0 m-2"> */}
              {!add && !edit && (
                <Button
                  onClick={onAddClick}
                  className={
                    showTab
                      ? "btn-navActive  mt-3 ml-3 mb-2"
                      : "btn-navActive mt-3 ml-3 mb-2 "
                  }
                >
                  <span className="ml-auto mr-auto ">
                    {" "}
                    Add Supplier{" "}
                    <span className="pl-2">
                      <RiAddFill />
                    </span>
                  </span>
                </Button>
              )}
              {/* </div> */}
              {/* )} */}
              {add && !edit && (
                <div className="tabHeader-wrap col-6 p-3">
                  <span className="tabHeadText Active"> Add Supplier</span>
                </div>
              )}
              {edit && (
                <div className="tabHeader-wrap col-6 p-3">
                  <span className="tabHeadText Active"> Edit Supplier</span>
                </div>
              )}
              {/* <div className="d-flex justify-content-end  col-6  ">
                  <Button
                                 className="btn-nav w-7rem justify-content-center"

                    onClick={onAddClick}
                  >
                    Add
                  </Button>
                </div> */}
            </div>
            {add || edit ? (
              <>
                {" "}
                <div className="mb-4">
                  <div className="formgrid grid px-2 mb-1">
                    <div className="d-block sm:d-flex sm:flex-column col-4 sm:col-6">
                      <label className="inputLabel">Supplier Name *</label>
                      <InputText
                        name="name"
                        value={formik.values.name}
                        onChange={(e) =>
                          formik.setFieldValue("name", e.target.value)
                        }
                        placeholder="Supplier Name"
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("name"),
                          "card-inputBox": true,
                        })}
                        // filter
                      />
                      {getFormErrorMessage("name")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column col-4 sm:col-6">
                      <label className="inputLabel">Supplier Type *</label>
                      <Dropdown
                        name="supplierType"
                        value={formik.values.supplierType}
                        onChange={(e) =>
                          formik.setFieldValue("supplierType", e.target.value)
                        }
                        style={{ width: "100%" }}
                        options={GetSupplierType}
                        optionLabel="value"
                        optionValue="key"
                        placeholder="Supplier Type"
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("supplierType"),
                          "card-inputBox": true,
                        })}
                        // filter
                      />
                      {getFormErrorMessage("supplierType")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column col-4 sm:col-6">
                      <label className="inputLabel">Supplier Website </label>
                      <InputText
                        name="lastName"
                        // onChange={(e) => setWebsite(e.target.value)}
                        onChange={(e) =>
                          formik.setFieldValue("website", e.target.value)
                        }
                        value={formik.values.website}
                        // className={classNames("card-inputBox")}
                        placeholder="Supplier Website"
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("website"),
                          "card-inputBox": true,
                        })}
                      />
                      {getFormErrorMessage("website")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2">
                    <div className="d-flex flex-column col-4 sm:col-6 phoneInput">
                      <label htmlFor="phone" className="inputLabel">
                        Supplier Phone{" "}
                      </label>

                      <div className="p-inputgroup flex-1">
                        <span className="p-inputgroup-addon">+1 </span>
                        <input
                          name="phone"
                          id="phone"
                          type="text"
                          // value={phoneNumber}
                          value={phone}
                          // onChange={handleChange}

                          onChange={(e) =>
                            // setPhone(formatPhoneNumber(e.target.value))
                            {
                              const enteredPhoneNumber = e.target.value;
                              setPhone(formatPhoneNumber(enteredPhoneNumber));

                              // Check if the entered phone number has at least 10 digits
                              if (
                                enteredPhoneNumber.replace(/\D/g, "").length <
                                10
                              ) {
                                setError(true);
                              } else {
                                setError(false);
                              }
                            }
                          }
                          placeholder="xxx-xxx-xxxx"
                          className={classNames("card-inputBox-phone pl-2")}
                          // className={classNames({
                          //   "p-invalid": isFormFieldInvalid("phone"),
                          //   "card-inputBox-phone pl-2": true,
                          // })}
                        />
                      </div>
                      {error ? (
                        <small className="p-error">
                          {" "}
                          Please enter 10 digit phone number
                        </small>
                      ) : (
                        <small className="p-error">&nbsp;</small>
                      )}
                    </div>
                    <div className="d-flex col-4 justify-content-start align-items-center">
                      <Button
                        className="mr-4 btn-nav w-7rem justify-content-center"
                        onClick={resetForm}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn-nav w-7rem justify-content-center"
                        onClick={(e) => {
                          e.preventDefault();
                          formik.handleSubmit();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="">
              <DataTableComponent
                valueData={exitDate}
                fieldsElements={dataTableElements}
                isPaginationAllowed={true}
                scrollable={true}
                className="gridcell deletebtn"
                maxHeight={"calc(-210px + 100vh)"}
                isFullView={true}
                setOpenDailog={setOpenDailog}
                openDailog={openDailog}
                resizableColumns={true}
              />
            </div>
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
