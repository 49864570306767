import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";

import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
// import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { GetPartnerLevelCashDistributionsDetailsThunk } from "../../../store/features/taxmanagement/pro-ratacashdistributions/getpartnerlevelcashdistributionsdetails/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import { exportExcel } from "../../../utils/excel-dataformat";
import { convertExcelHeaders } from "../../../utils/helper";
// import dateFormatter from "../../../utils/date-formatter";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";
export default function InvestorShareOfCashDistributions() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const dt = useRef<DataTable>(null);
  const previousYear: any = new Date().getFullYear();
  const currentYear = previousYear - 1;
  // const portState = useAppSelector((state) => state.GetMenuList.loading);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const GetMenuList = useAppSelector(
    (state) => state.GetPartnerLevelCashDistributionsDetails?.data
  );
  const GetList = useAppSelector(
    (state) => state.GetPartnerLevelCashDistributionsDetails?.data1
  );

  const GetList2 = useAppSelector(
    (state) => state.GetPartnerLevelCashDistributionsDetails?.data2
  );

  const [visible, setVisible] = useState<any>(false);
  // const [exitDate, setExitDate] = useState<any>();
  const [dataTableElements, setdataTableElements] = useState<any>();
  const [dataCreateElemnts, setDataCreateElemnts] = useState<any>();
  const [exportExcelHeader, setExportExcelHeader] = useState<any>([]);

  // let exitDate: any;
  const datatableArrayObj: any = [];
  // const columnSums: { [key: string]: any } = {}; // Object to store column sums
  const [columnSums, setColumnSums] = useState<{ [key: string]: any }>({});
  const [loading, setloading] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);

  const footer = GetList2 ? JSON.parse(GetList2!) : [];

  console.log("footer sjdnfknsfkj", footer);

  const formatCurrency = (value: any) => {
    // Format the value as currency
    const formattedValue = currencyFormat(value);
    // const formattedValue = new Intl.NumberFormat("en-US", {
    //   style: "currency",
    //   currency: "USD", // Change this to your desired currency code
    // }).format(value);

    return formattedValue;
  };

  const prepareData = (data: any) => {
    if (data !== undefined && data !== "") {
      const value = JSON.parse(GetMenuList!);
      const header = JSON.parse(GetList!);
      // const footer = JSON.parse(GetList2!);

      console.log("value=====", value);
      console.log("header====", header);

      header?.push(
        {
          KEY: "LPPartnerID",
          VALUE: "LPPartnerID",
        },
        {
          KEY: "Investor Account",
          VALUE: "Investor Account",
        },
        {
          KEY: "Total Partner Cash Distributions Allocated",
          VALUE: "Total Partner Cash Distributions Allocated",
        }
      );

      // for (let i = 0; i < header.length; i++) {}
      // let dataRet: any;
      // 18 bar loop hoga

      // ... your processing logic ...

      for (let i = 0; i < value?.length; i++) {
        // ... your processing logic ...
        const totalObj = { ...value?.[0] };
        console.log("prepareData totalObj", Object.keys(totalObj));
        const objTotal: any = {};
        for (const val of value) {
          // Loop through the lists to calculate column sums
          let totalSum = 0;
          console.log("prepareData totalObj", val);

          for (const key in val) {
            if (key in objTotal) {
              objTotal[key] += val[key];
            } else {
              objTotal[key] = val[key];
            }

            if (key === "4") {
              totalSum += val[key];
              console.log("4ajsjdsj", totalSum, val[key], key);
            } else {
              totalSum += val[key];
            }
          }
          const newData = Object.fromEntries(
            Object.entries(objTotal).filter(
              ([key]) => key !== "LPPartnerID" && key !== "Investor Account"
            )
          );

          console.log("newData", newData);
          console.log("prepareData objTotal", objTotal);

          setColumnSums(newData);
        } // Update columnSums state
      }
      for (let i = 0; i < value?.length; i++) {
        let count = 0;
        // let lengthData = 0;
        const obj: any = {};

        for (const key in value[i]) {
          obj[header[count]?.VALUE] =
            key === "LPPartnerID" || key === "Investor Account"
              ? value[i][key]
              : formatCurrency(value[i][key]);

          count++;
        }

        datatableArrayObj.push(obj);
      }

      return datatableArrayObj;
    } else {
      return [];
    }
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const createcolumns = (data: any) => {
    if (data !== undefined && data !== "") {
      const header = JSON.parse(GetList!);

      header?.push(
        {
          KEY: "LPPartnerID",
          VALUE: "LPPartnerID",
        },
        {
          KEY: "Investor Account",
          VALUE: "Investor Account",
        },
        {
          KEY: "Total Partner Cash Distributions Allocated",
          VALUE: "Total Partner Cash Distributions Allocated",
        }
      );

      const allData = header?.map((key: any, value: any) => {
        // console.log("key", key);
        // console.log("value", value);
        return {
          field: key.VALUE,
          header: key.VALUE,
          style:
            key.VALUE !== "LPPartnerID"
              ? { minWidth: "550px" }
              : { minWidth: "140px" },
        };
      });

      const partnerIDIndex = allData?.findIndex(
        (item: any) => item.field === "LPPartnerID"
      );
      const partnerNameIndex = allData?.findIndex(
        (item: any) => item.field === "Investor Account"
      );

      // If PartnerID and PartnerName are found, move them to indexes 0 and 1 respectively
      if (partnerIDIndex !== -1) {
        const partnerIDField = allData?.splice(partnerIDIndex, 1)[0];
        allData?.splice(0, 0, partnerIDField);
      }

      if (partnerNameIndex !== -1) {
        const partnerNameField = allData?.splice(partnerNameIndex, 1)[0];
        allData?.splice(1, 0, partnerNameField);
      }

      return allData;
    } else {
      return [];
    }
  };

  const formik = useFormik({
    initialValues: {
      Year: currentYear.toString(),
    },
    validate: (data: any) => {
      //
      const errors: any = {};

      if (!data.Year) {
        errors.Year = "Please enter year";
      }

      return errors;
    },
    onSubmit: async () => {
      setloading(true);
      const params = {
        LegalEntityId: fundDropdown.fundid,
        TaxYear: formik.values.Year.toString(),
      };
      dispatch(GetPartnerLevelCashDistributionsDetailsThunk(params))
        .then(() => {
          setVisible(true);
          setloading(false);

          // setdataTableElements(prepareData(exitDate));
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    },
  });
  const columnSumsKeys: any = Object.values(columnSums);
  const resultArray = footer.map((item: any, index: any) => {
    // console.log("columnSumsKeys", columnSumsKeys);
    // console.log("itemm", columnSumsKeys[index]);
    console.log("amount", item.Amount);
    console.log("amount 1", item.Amount, columnSumsKeys[index]);

    return typeof columnSumsKeys[index] === "number"
      ? {
          ProductID: item.ProductID,
          Amount: columnSumsKeys[index] - item.Amount,
        }
      : item;
  });

  console.log("column -footer", columnSums.key);
  console.log("columnSums -footer", footer);
  console.log("-footer", resultArray);

  // console.log("Object.keys(columnSums)", Object.entries(columnSums));
  // console.log("footer", footer);
  // console.log("resultArray", resultArray);

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Calculated Total Cash Distributions "
          colSpan={2}
          // footerStyle={{ textAlign: "right" }}
        />
        {Object.keys(columnSums).map((key, index) => (
          <Column
            key={index}
            footer={
              typeof columnSums[key] !== "string"
                ? formatCurrency(columnSums[key])
                : ""
            }
          />
        ))}
      </Row>
      <Row>
        <Column
          footer="Total Reported from K-1 Form"
          colSpan={2}
          // footerStyle={{ textAlign: "right" }}
        />

        {footer.map((i: any) => {
          return <Column key={i.ChildName} footer={formatCurrency(i.Amount)} />;
        })}
      </Row>
      <Row>
        <Column
          footer="Delta"
          colSpan={2}
          // footerStyle={{ textAlign: "right" }}
        />
        {resultArray.map((amount: any, index: any) =>
          typeof amount !== "undefined" ? (
            <Column key={index} footer={formatCurrency(amount.Amount)} />
          ) : undefined
        )}
      </Row>
    </ColumnGroup>
  );
  // const getExitDateConversion = (data: any) => {
  //   return [...(data || [])].map((d) => {
  //     const modifiedExitDate = {
  //       ...d,
  //       fundingDate: new Date(d.fundingDate),
  //     };
  //     return modifiedExitDate;
  //   });
  // };

  useEffect(() => {
    // setExitDate(getExitDateConversion(GetList));
    const header = createcolumns(GetList);
    setDataCreateElemnts(header);
    setExportExcelHeader(
      convertExcelHeaders(header, "stringCurrency", [
        "LPPartnerID",
        "Investor Account",
      ])
    );
    setdataTableElements(prepareData(GetList));
    const col = header;
    console.log("col", col);
  }, [GetList, formik.values.Year, GetMenuList]);

  useEffect(() => {
    setVisible(false);
    formik.resetForm();
  }, [fundDropdown.fundid]);

  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    setVisible(false);
  }, [formik.values.Year]);

  useEffect(() => {
    if (formik.values.Year.toString() === currentYear.toString()) {
      setloading(true);
      const params = {
        LegalEntityId: fundDropdown.fundid,
        TaxYear: formik.values.Year.toString(),
      };
      dispatch(GetPartnerLevelCashDistributionsDetailsThunk(params))
        .then(() => {
          setVisible(true);
          setloading(false);

          // setdataTableElements(prepareData(exitDate));
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
    } else {
      setVisible(false);
    }
  }, [formik.values.Year]);
  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">
              K-1: investor&apos;s share of cash distributions
            </h1>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  exportExcelHeader,
                  dataTableElements,
                  "K-1: investor's share of cash distributions",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                dataTableElements === undefined ||
                dataTableElements?.length === 0 ||
                dataTableElements === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  dataTableElements === undefined ||
                  dataTableElements?.length === 0 ||
                  dataTableElements === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
        {/* <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div> */}
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card tabWithoutboxshadow">
            {/* <Card> */}
            <>
              {" "}
              <div className="">
                <div className="formgrid grid col">
                  <div className="d-block sm:d-flex sm:flex-column mr-2 md:col-6 col-12">
                    {/* <label className="inputLabel">Year *</label> */}
                    <div className="card flex justify-content-center">
                      <Calendar
                        name="Year"
                        placeholder="Select Year"
                        value={
                          formik.values.Year === null ||
                          formik.values.Year === ""
                            ? undefined
                            : new Date(+formik.values.Year, 0, 1)
                        }
                        onChange={(e: any) => {
                          const selectedYear = new Date(
                            Number(e.value)
                          ).getFullYear();
                          formik.setFieldValue("Year", selectedYear);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Year"),
                          "card-inputBox": true,
                        })}
                        view="year"
                        dateFormat="yy"
                      />
                      {getFormErrorMessage("Year")}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="mr-4 btn-nav w-7rem justify-content-center"
                      onClick={(e: any) => {
                        e.preventDefault();
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      className="btn-nav w-7rem justify-content-center"
                      onClick={(e: any) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        console.log("clicked");
                        formik.handleSubmit();
                      }}
                    >
                      Show
                    </Button>
                  </div>
                </div>
              </div>
            </>{" "}
            {visible && (
              <div className="mt-2">
                <DataTableComponent
                  valueData={dataTableElements}
                  fieldsElements={dataCreateElemnts}
                  ref={dt}
                  isDownload={true}
                  footerElement={dataTableElements.length > 0 && footerGroup}
                  isPaginationAllowed={true}
                  fileName="K-1: investor's share of cash distributions"
                  scrollable={true}
                  className="gridcell"
                  columnResizeMode="expand"
                  maxHeight={"calc(-265px + 100vh)"}
                  isFullView={true}
                  setOpenDailog={setOpenDailog}
                  openDailog={openDailog}
                />
              </div>
            )}
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
