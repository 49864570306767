import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { RiAddFill, RiExternalLinkFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import ConfirmDailogbox from "../../../../components/dialogbox/confirm-dailogbox/ConfirmDailogbox";
import Loader from "../../../../components/loader/Loader";
import { getThunkDropdown } from "../../../../store/features/dropdown/dropdown-list/slice";
// import { addThunkInvestorK1Document } from "../../../../store/features/taxmanagement/upload-investork1/add-k1-document/slice";
import { getprofileData } from "../../../../store/features/profile/slice";
import {
  addThunkInvestorK1Document,
  deleteThunkInvestorK1Document,
  getThunkUploadInvestorK1List,
} from "../../../../store/features/taxmanagement/upload-investork1/k1-document-list/slice";
import { getThunkUploadK1RevisionDropdown } from "../../../../store/features/taxmanagement/upload-investork1/revision-dropdown/slice";
// import { getThunkInvestorK1ViewDocument } from "../../../../store/features/taxmanagement/upload-investork1/view-k1-document/slice";
import { getThunkInvestorList } from "../../../../store/features/view-gather-softcommit/investor-list-dropdown/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getSasKeyThunk } from "../../../../store/sas-key/slice";
import { isValidUrlRegexNew } from "../../../../utils/investing-approval/multi-investing-account/validation";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";

export default function UploadInvestorK1s() {
  const dispatch = useAppDispatch();
  const fileRef = useRef<any>(null);
  const toast = useRef<Toast>(null);
  const [add, setAdd] = useState(false);
  const [docLink, setDocLink] = useState("");
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
  const [visibleViewUpload, setVisibleViewUpload] = useState(false);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const [DocName, setDocName] = useState("");
  const [loading, setLoading] = useState(false);
  const [investorDocumentK1sId, setInvestorDocumentK1sId] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  // const [documentLinkUpdate, setDocumentLinkUpdate] = useState("");
  const [contentType, setContentType] = useState("");
  const stateDropDownLists = useAppSelector((state) => state.dropdownList.data);
  const partnerList = useAppSelector((state) => state.investorList.data);
  const [sasKey, setsasKey] = useState("");
  const regexTest = /(.*?).(pdf|png|jpeg|jpg)$/;
  const listUploadInvestorK1 = useAppSelector(
    (state) => state.getUploadInvestorK1List.data
  );
  const revisionDropdown = useAppSelector(
    (state) => state.revisionDropdown.data
  );

  const sponsorData = useAppSelector(getprofileData);

  const [openDailog, setOpenDailog] = useState(false);
  const formik = useFormik({
    initialValues: {
      partnerId: 0,
      year: "",
      revisionId: 0,
      documentDescription: "",
      documentUrl: "",
      mediaType: "File",
      files: undefined,
    },
    validate: (data) => {
      const errors: any = {};
      if (!data.partnerId) {
        errors.partnerId = " Select partner";
      }
      if (!data.year) {
        errors.year = "Year is required";
      }
      if (formik.values.mediaType === "URL" && !data.documentUrl) {
        errors.documentUrl = "Document url is required";
      }
      if (data.documentUrl && !isValidUrlRegexNew(data.documentUrl)) {
        errors.documentUrl = "Enter a valid url";
      }
      if (
        data.documentUrl &&
        data.documentUrl.includes(sponsorData![0].storageAccountName)
      ) {
        errors.documentUrl =
          'Links to Avestor Storage Accounts are protected with multiple keys and hence cannot be added as an URL. Please upload a copy of this document using the Media Type as "File".';
      }

      if (!data.revisionId) {
        errors.revisionId = "Select revision";
      }
      return errors;
    },
    onSubmit: async (dataObj) => {
      console.log(dataObj);
      const data = new FormData();
      setLoading(true);
      if (formik.values.mediaType === "URL") {
        data.append("documentLink", formik.values.documentUrl);
      }
      if (formik.values.mediaType === "File") {
        data.append(
          "files",
          formik.values.files === undefined ? " " : formik.values.files
        );
        data.append("documentLink", docLink);
        data.append("docType", contentType);
      }

      data.append("legalEntityID", fundDropdown.fundid.toString());
      data.append("id", investorDocumentK1sId.toString());
      data.append("taxYear", formik.values.year.toString());
      data.append("partnerId", formik.values.partnerId.toString());
      data.append("revisionId", formik.values.revisionId.toString());
      data.append("mediaType", formik.values.mediaType);
      data.append("doNotCompress", "false");
      data.append("documentDesc", formik.values.documentDescription);
      data.append("docExtension", "");

      data.append("documentName", DocName);

      try {
        await dispatch(addThunkInvestorK1Document(data)).then((response) => {
          if (response.meta.requestStatus === "rejected") {
            errorToastMessage(toast);
          } else if (response.meta.requestStatus === "fulfilled") {
            if (response.payload._response.success === false) {
              errorToastMessage(toast, "File not Uploaded");
            } else {
              successToastMessage(toast, "File Uploaded");

              setInvestorDocumentK1sId(0);
              formik.resetForm();
              setAdd(false);
              setDocName("");
              setDocLink("");
              setOpenDocumentDialog(false);
              // setDocumentLinkUpdate("");
              setContentType("");
            }
          }
        });
        setLoading(false);
        fileRef?.current.clear();
      } catch (error) {
        // fileRef?.current.clear();
        formik.resetForm();
        console.log("Opps something went wrong", error);
        setLoading(false);
      }
    },
  });
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const generateSASKey = (legalEntityId: any) => {
    return dispatch(getSasKeyThunk(legalEntityId))
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          return response.payload.data.sasKey;
        }
      })
      .catch(() => {
        errorToastMessage(toast);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const uploadFile = async (e: any) => {
    const file = e.files[0];

    console.log("File Here", file);

    const regex = /(.*?).(docx|doc|pdf|xls|png|jpeg|xlsx|jpg)$/;
    if (!regex.test(file.name.toLowerCase())) {
      errorToastMessage(
        toast,
        "Upload failed. Please Use Images, PDF, Excel, Word format only"
      );
      fileRef?.current.clear();
      return;
    }

    const fileSize = file.size / 1024 / 1024;
    console.log("FILES", fileSize);
    if (fileSize > 10) {
      errorToastMessage(
        toast,
        "Upload failed. Please ensure the file size does not exceed 10mb"
      );
      fileRef?.current.clear();
      return;
    }

    setDocName(file.name);
    formik.setFieldValue("files", file);
    setsasKey("");
    setDocLink(URL.createObjectURL(file));

    setContentType(file.type);
    fileRef?.current.clear();
  };
  const onAddClick = () => {
    formik.resetForm();
    setInvestorDocumentK1sId(0);
    setAdd(true);
    setOpenDocumentDialog(true);
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "mediaType") {
      setDocLink("");
      setDocName("");
      formik.setFieldValue("documentUrl", "");
      if (fileRef.current !== null) {
        fileRef?.current.clear();
        formik.setFieldValue("files", "");
      }
    }
    if (name === "year") {
      if (value !== null) {
        const onlyYear = value.getFullYear();

        formik.setFieldValue(name, onlyYear);
      }
    } else {
      formik.setFieldValue(name, value);
    }
  };

  const DocumentName = (rowData: any) => {
    return (rowData.documentName === "" || rowData.documentName === null) &&
      rowData.docType === null ? (
      <div className="left-align" title={rowData.documentLink}>
        {rowData.documentLink}
      </div>
    ) : (
      <div className="left-align" title={rowData.documentName}>
        {rowData.documentName}
      </div>
    );
  };
  const editButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleEdit = async (id: any) => {
      setDocName("");
      formik.setFieldValue("year", rowData.taxYear);
      formik.setFieldValue("partnerId", rowData.partnerId);
      formik.setFieldValue("revisionId", rowData.revisionId);
      if (rowData.documentDesc !== null) {
        formik.setFieldValue("documentDescription", rowData.documentDesc);
      }
      if (rowData.docType === null) {
        formik.setFieldValue("documentUrl", rowData.documentLink);
        formik.setFieldValue("mediaType", "URL");
      } else {
        // setDocumentLinkUpdate(rowData.documentLink);
        setDocLink(rowData.documentLink);
        formik.setFieldValue("mediaType", "File");
        formik.setFieldValue("files", rowData.documentLink);
      }

      setContentType(rowData.docType);
      let key = "";
      setLoading(true);
      key = await generateSASKey(fundDropdown.fundid.toString());
      setsasKey(key);
      setInvestorDocumentK1sId(rowData.id);
      setDocName(rowData.documentName ? rowData.documentName : "");
      setAdd(false);
      setOpenDocumentDialog(true);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={() => {
              handleEdit(rowData.Id);
            }}
          >
            <span className="text-white">Edit</span>
          </Button>
        </div>
      </div>
    );
  };

  const deleteButton = (rowData: any) => {
    // Pass rowData as a parameter
    const handleDelete = (id: any) => {
      //
      setInvestorDocumentK1sId(rowData.id);
      setOpenDialog(true);
      setDocName("");
      formik.resetForm();
      // setDocumentLinkUpdate("");
      setDocLink("");
      setAdd(false);
    };

    return (
      <div className="flex flex-column">
        <div className="flex align-items-center justify-content-center">
          <Button
            className=" btn-dialog widthdeletebtn align-items-center justify-content-around sm:mr-2 mx-0 "
            onClick={() => handleDelete(rowData.Id)}
          >
            <span className="text-white">Delete</span>
          </Button>
        </div>
      </div>
    );
  };

  const ViewTemplateButton = (rowData: any) => {
    const viewUploadedFile = (key: any) => {
      setDocLink(rowData.documentLink);
      setVisibleViewUpload(true);
    };

    const openDoc = async (e: any) => {
      e.preventDefault();
      let key = "";
      setLoading(true);
      key = await generateSASKey(fundDropdown.fundid.toString());
      setsasKey(key);
      viewUploadedFile(key);
    };
    return (
      <React.Fragment>
        {rowData.docType !== null ? (
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-text iconButton"
            onClick={(e) => openDoc(e)}
          />
        ) : (
          <NavLink
            to={
              rowData.documentLink === null
                ? ""
                : rowData?.documentLink.startsWith("http")
                ? rowData.documentLink
                : `http://${rowData.documentLink}`
            }
            target={rowData.documentLink === null ? undefined : "_blank"}
            className="icons-Link"
          >
            <div>
              <RiExternalLinkFill />
            </div>
          </NavLink>
        )}
      </React.Fragment>
    );
  };
  const dataTableElements = [
    {
      header: "View Document",
      body: ViewTemplateButton,
      bodyClassName: "text-center",
      style: { minWidth: "100px" },
    },
    {
      field: "legalEntityName",
      header: "Fund Name",
      style: { minWidth: "230px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.legalEntityName}>
          {rowData?.legalEntityName}
        </div>
      ),
    },
    {
      field: "taxYear",
      header: "Year",
      style: { minWidth: "230px" },
    },
    {
      field: "partnerName",
      header: "Investor Account",
      style: { minWidth: "230px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.partnerName}>
          {rowData?.partnerName}
        </div>
      ),
    },
    {
      field: "revisionName",
      header: "Revision",
      style: { minWidth: "230px" },
    },
    {
      field: "documentDesc",
      header: "Document Description",
      style: { minWidth: "230px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.documentDesc}>
          {rowData?.documentDesc}
        </div>
      ),
    },
    {
      field: "documentName",
      header: "Document Name",
      body: DocumentName,
      style: { minWidth: "300px" },
    },
    {
      //  field: "edit",
      header: "",
      body: (rowData: any) => editButton(rowData), // Pass rowData to editButton
      style: { minWidth: "170px" },
    },
    {
      // field: "delete",
      header: "",
      body: (rowData: any) => deleteButton(rowData), // Pass rowData to editButton
      style: { minWidth: "170px" },
    },
  ];

  const onDelete = () => {
    setLoading(true);
    const deleteParms = {
      docId: investorDocumentK1sId,
      legalEntityId: fundDropdown.fundid,
    };
    dispatch(deleteThunkInvestorK1Document(deleteParms)).then((response) => {
      // call the list
      console.log(response);
      if (response.meta.requestStatus === "rejected") {
        errorToastMessage(toast);
      } else if (response.meta.requestStatus === "fulfilled") {
        if (response.payload._response.success === true) {
          successToastMessage(toast, "Data is deleted successfully");
          setLoading(false);
          setInvestorDocumentK1sId(0);
        } else {
          errorToastMessage(toast, "Data is not deleted");
        }
        setLoading(false);
      }
    });

    setOpenDialog(false);
  };
  const onCancel = () => {
    setInvestorDocumentK1sId(0);
    setOpenDialog(false);
  };

  useEffect(() => {
    setLoading(true);
    formik.resetForm();
    setInvestorDocumentK1sId(0);
    setDocLink("");
    setDocName("");
    setAdd(true);
    setOpenDocumentDialog(false);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameters = {
        legalEntityID: fundDropdown.fundid,
        supplierID: 0,
      };

      dispatch(getThunkDropdown(parameters));
      dispatch(getThunkUploadInvestorK1List(fundDropdown.fundid)).finally(
        () => {
          setLoading(false);
        }
      );
      const parameter = {
        legalEntityId: fundDropdown.fundid,
      };

      dispatch(getThunkInvestorList(parameter));
    }
    dispatch(getThunkUploadK1RevisionDropdown());
  }, [fundDropdown.fundid]);

  const openDoc = async (e: any) => {
    e.preventDefault();
    // let key = "";
    // setLoading(true);
    // key = await generateSASKey(fundDropdown.fundid.toString());
    // setsasKey(key);
    setVisibleViewUpload(true);
  };
  console.log("DOCLink=>", docLink);

  const onHideAddDocument = () => {
    setOpenDocumentDialog(false);
    setInvestorDocumentK1sId(0);
    formik.resetForm();
    setContentType("");
    setDocLink("");
    setDocName("");
  };

  const partnerOptionTemplate = (option: any) => {
    return (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "inherit",
          cursor: "pointer",
        }}
        title={option.userName}
      >
        {option.userName}
      </div>
    );
  };

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  return (
    <>
      <Dialog
        header=""
        // maximizable
        visible={visibleViewUpload}
        // style={{ width: "50vw" }}
        style={window.innerWidth > 600 ? { width: "50%" } : { width: "100%" }}
        onHide={() => {
          setVisibleViewUpload(false);
        }}
        closable={true}
      >
        {!docLink?.includes("jpeg") &&
        !docLink?.includes("jpg") &&
        !docLink?.includes("png") &&
        !docLink?.includes("pdf") &&
        !docLink?.includes("jpeg") &&
        !regexTest.test(DocName ? DocName.toLowerCase() : "") ? (
          <div>The File is Downloading!!!</div>
        ) : (
          <></>
        )}

        <div className="m-0">
          <iframe
            src={docLink + sasKey}
            allowFullScreen
            style={{ width: "100%", height: "80vh" }}
          ></iframe>
        </div>
      </Dialog>

      <ConfirmDailogbox
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onConfirm={onDelete}
        onCancel={onCancel}
        textContent="Are you sure you want to delete ?"
      />

      <Dialog
        visible={openDocumentDialog}
        className="w-100 md:w-5"
        onHide={() => onHideAddDocument()}
        header={add === true ? "Add Document" : "Edit Document"}
        headerClassName="text-center flex justify-content-center align-item-center pageHeader display-1"
        contentClassName="top-lr-content p-0"
      >
        <div className="m-0">
          <hr className="m-0" />
          <form>
            <div className="mt-1">
              <div className="d-block sm:d-flex sm:flex-column  col-12">
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                    <label className="inputLabel">Partner Type *</label>

                    <Dropdown
                      inputId="partnerId"
                      name="partnerId"
                      value={
                        formik.values.partnerId === 0
                          ? ""
                          : formik.values.partnerId
                      }
                      options={partnerList === null ? [] : partnerList}
                      filter
                      optionLabel="userName"
                      itemTemplate={partnerOptionTemplate}
                      optionValue="userId"
                      placeholder="Select Partner"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("partnerId"),
                        "card-inputBox": true,
                      })}
                    />
                    {getFormErrorMessage("partnerId")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                    <label className="inputLabel">Year *</label>
                    <Calendar
                      name="year"
                      value={
                        formik.values.year === null || formik.values.year === ""
                          ? undefined
                          : new Date(+formik.values.year, 0, 1)
                      }
                      onChange={(e) => handleChange(e)}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("year"),
                        "card-inputBox": true,
                      })}
                      view="year"
                      dateFormat="yy"
                      placeholder="Select year"
                      readOnlyInput
                    />
                    {getFormErrorMessage("year")}
                  </div>
                </div>
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                    <label className="inputLabel">Revision Type *</label>

                    <Dropdown
                      inputId="revisionId"
                      name="revisionId"
                      value={
                        formik.values.revisionId === 0
                          ? ""
                          : formik.values.revisionId
                      }
                      options={
                        revisionDropdown === null ? [] : revisionDropdown
                      }
                      filter
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select Revision"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("revisionId"),
                        "card-inputBox": true,
                      })}
                    />
                    {getFormErrorMessage("revisionId")}
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column pt-2 col-12 sm:col-6">
                    <label className="inputLabel">Document Description</label>
                    <InputText
                      name="documentDescription"
                      value={formik.values.documentDescription}
                      onChange={(e) => handleChange(e)}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("documentDescription"),
                        "card-inputBox": true,
                        "p-filled": true,
                      })}
                      placeholder="Enter document description"
                    />
                    {getFormErrorMessage("documentDescription")}
                  </div>
                </div>
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column col-12 sm:col-6">
                    <label className="inputLabel">Media Type *</label>
                    <Dropdown
                      inputId="mediaType"
                      name="mediaType"
                      value={formik.values.mediaType}
                      options={
                        stateDropDownLists === null
                          ? []
                          : stateDropDownLists.mediaTypes
                      }
                      filter
                      optionLabel="value"
                      optionValue="key"
                      placeholder="Select media type"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className={classNames({
                        "p-invalid": isFormFieldInvalid("mediaType"),
                        "card-inputBox": true,
                      })}
                    />
                    {getFormErrorMessage("mediaType")}
                  </div>

                  {formik.values.mediaType === "URL" ? (
                    <div className="d-flex col-4 flex-column col-12 sm:col-6">
                      <label className="inputLabel">Document Url *</label>
                      <InputText
                        name="documentUrl"
                        value={formik.values.documentUrl}
                        onChange={(e: any) => {
                          // setproductdocLink(e.target.value);
                          setDocLink(e.target.value);
                          handleChange(e);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("documentUrl"),
                          "card-inputBox": true,
                        })}
                        placeholder="Enter Document Url"
                      />
                      {getFormErrorMessage("documentUrl")}
                    </div>
                  ) : (
                    <>
                      <div className="d-flex col-4 flex-column justify-content-center col-12 sm:col-6 ">
                        <FileUpload
                          mode="basic"
                          name="demo"
                          ref={fileRef}
                          auto={true}
                          customUpload={true}
                          chooseLabel="Select File"
                          className="btn-dialog border-round-md w-100 UploadAccreBtn"
                          uploadHandler={uploadFile}
                        />
                      </div>

                      <div className="d-flex flex-column col-12 align-self-end">
                        {DocName === "" || DocName === undefined ? (
                          <></>
                        ) : (
                          <label
                            className="inputLabel"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              cursor: "pointer",
                              height: "20px",
                            }}
                          >
                            File Name:{" "}
                            <NavLink
                              className="document-link"
                              onClick={(e) => openDoc(e)}
                              to={""}
                              title={DocName}
                            >
                              {DocName}
                            </NavLink>
                          </label>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="d-block sm:d-flex sm:flex-column  col-12">
                <div className="flex flex-row flex-wrap sm:flex-none justify-content-center col-12 ">
                  <div className="d-flex flex-column r p-2 col-12 sm:col-5 annualClass">
                    <Button
                      className="btn-dialog "
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        // uploadDoc();
                        // setOpenAddDialog(true);
                      }}
                      disabled={
                        (formik.values.files === undefined ||
                          formik.values.files === "") &&
                        docLink === "" &&
                        formik.values.documentUrl === ""
                      }
                    >
                      <span className="ml-auto mr-auto ">Add Document</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      {loading ? <Loader /> : <></>}

      <div className="grid">
        <Toast ref={toast} className="themeToast" />

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">Upload Investor K-1s</h1>
          </div>
          <div className="flex align-items-center mb-2">
            <Button
              className="btn-navActive"
              type="button"
              icon="pi pi-window-maximize"
              disabled={
                listUploadInvestorK1 === undefined ||
                listUploadInvestorK1?.length === 0 ||
                listUploadInvestorK1 === null
              }
              onClick={() => setOpenDailog(true)}
              title="View Full Screen"
            />
          </div>
        </div>
        <div className="grid col-12">
          <div className="col-12">
            <div className="card tabWithoutboxshadow">
              {/* <Card> */}
              <div className="grid justify-content-start m-0">
                {" "}
                <Button
                  onClick={onAddClick}
                  // className={
                  //   showTab ? "tabHeadText Active" : "tabHeadText "
                  // }
                  className="btn-navActive mr-0 m-2"
                >
                  <span className="ml-auto mr-auto ">
                    {" "}
                    Add Document{" "}
                    <span className="pl-2">
                      <RiAddFill />
                    </span>
                  </span>
                </Button>
              </div>

              <div className="col-12">
                <DataTableComponent
                  valueData={listUploadInvestorK1 || []}
                  fieldsElements={dataTableElements}
                  isPaginationAllowed={true}
                  scrollable={true}
                  className="gridcell deletebtn"
                  columnResizeMode="expand"
                  maxHeight={"calc(-250px + 100vh)"}
                  isFullView={true}
                  setOpenDailog={setOpenDailog}
                  openDailog={openDailog}
                />
              </div>
              {/* </Card> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
