import { IGetInvestorIncomeListparameters } from "../../store/features/taxmanagement/1099/InterestIncomeByInvestor/getinvestorincomelist";
import { ISupplierDropDownListParameter } from "../../store/features/taxmanagement/1099/input-form-1099/supplier-dropdown";
import { IGetFundLevelReconcileIncomeparameters } from "../../store/features/taxmanagement/1099/interestincomeatfundlevel/getfundlevelreconcileincome";
import { IGetCapitalLossDetailsparameters } from "../../store/features/taxmanagement/baddebtexpenses/getcapitallossdetails";
import { IGetcalculateExpensesparameters } from "../../store/features/taxmanagement/fundexpensesproratacalculation/getcalculateexpenses";
import { ISaveFinakK1Generationparameters } from "../../store/features/taxmanagement/generation/savegeneration/savefinakk1generation";
import { IGetPartnerCapitalAccCalparameters } from "../../store/features/taxmanagement/getpartnercapitalacccal";
import { IGetProdductListparameters } from "../../store/features/taxmanagement/getpartnerprodatabyinvestment/getproductlist";
import { IGetRevisionListparameters } from "../../store/features/taxmanagement/getrevisionliist";
import { IK1InputSupplierParameter } from "../../store/features/taxmanagement/k1-input-supplier/save-form";
import { IGetK1ReconciliationByInvestmentparameters } from "../../store/features/taxmanagement/k1reconcilationinvestment/getfundinvestmentsreport";
import { IK1ReconciliationByInvestorparameters } from "../../store/features/taxmanagement/k1reconciliationbyinvestot/k1reconciliationbyinvestor";
import { IGetLPWiseManagementListparameters } from "../../store/features/taxmanagement/managefeespaid/getLPwisemanagementlist";
import { IGetAllocatedInvestmentparameters } from "../../store/features/taxmanagement/ownershipbyproductdata/getallocatedinvestment";
import { IGetLPOwnershipDataparameters } from "../../store/features/taxmanagement/ownershipchangereport";
import { IGetK1PartnerInformationparameters } from "../../store/features/taxmanagement/partnerinformation/getk1partnerinformation";
import { IGetPartnerLevelCashDistributionsDetailsparameters } from "../../store/features/taxmanagement/pro-ratacashdistributions/getpartnerlevelcashdistributionsdetails";
import { IGetSummaryDetailsparameters } from "../../store/features/taxmanagement/summaryreportbypartner/getsummarydetails";
import apiFactory from "../../utils/api";
import { envVariable } from "../../utils/sponsor/helper";
import { IGetK1GenDetailsparameters } from "./../../store/features/taxmanagement/generation/getk1gendetails/index";
import { IGetPartnerProDataByInvestmentparameters } from "./../../store/features/taxmanagement/getpartnerprodatabyinvestment/index";
import { IGetK1ReconcilationInfoparameters } from "./../../store/features/taxmanagement/k1-reconcilationreport/getk1reconcilationinfo/index";

export function GetRevisionListService(data: IGetRevisionListparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetRevisionList?LegalEntityId=${
      data.LegalEntityId
    }&TaxYear=${data.TaxYear}&InvestmentID=${data.InvestmentId}
    `
  );
}

export function GetPartnerProDataByInvestmentService(
  data: IGetPartnerProDataByInvestmentparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetPartnerProDataByInvestment?legalEntityId=${
      data.LegalEntityId
    }&TaxYear=${data.TaxYear}&InvestmentId=${data.InvestmentId}&RevisionId=${
      data.RevisionId
    }
    `
  );
}

export function GetPartnerLevelCashDistributionsDetailsService(
  data: IGetPartnerLevelCashDistributionsDetailsparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetPartnerLevelCashDistributionsDetails?LegalEntityID=${
      data.LegalEntityId
    }&TaxYear=${data.TaxYear}
    `
  );
}

export function GetAllocatedInvestmentService(
  data: IGetAllocatedInvestmentparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetAllocatedInvestment?legalEntityId=${
      data.legalEntityId
    }&InvestmentId=${data.InvestmentId}&yearEndDate=${data.yearEndDate}
    `
  );
}

export function GetK1PartnerInformationService(
  data: IGetK1PartnerInformationparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetK1PartnerInformation?LegalEntityId=${data.LegalEntityId}
    `
  );
}

export function GetK1GenDetailsService(data: IGetK1GenDetailsparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetK1GenDetails?LegalEntityId=${data.LegalEntityId}&Year=${
      data.Year
    }&PartnerId=${data.PartnerId}
    `
  );
}

export function BindRevisionListService() {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_TAX_URL}TaxManagement/BindRevisionList
    `
  );
}

export function GetProdductListService(data: IGetProdductListparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetInvestmentList?LegalEntityId=${data.LegalEntityId}
    `
  );
}

export function GetLPWiseManagementListService(
  data: IGetLPWiseManagementListparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetLPWiseManagementList?LegalEntityId=${
      data.LegalEntityId
    }&Year=${data.Year}
    `
  );
}

export function GetLPOwnershipDataService(data: IGetLPOwnershipDataparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetLPOwnershipData?LegalEntityId=${
      data.LegalEntityId
    }&Year=${data.Year}

    `
  );
}

export function GetPartnerCapitalAccCalService(
  data: IGetPartnerCapitalAccCalparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetPartnerCapitalAccCal?LegalEntityId=${
      data.LegalEntityId
    }&Year=${data.Year}

    `
  );
}

export function GetK1ReconcilationInfoService(
  data: IGetK1ReconcilationInfoparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetK1ReconcilationInfo?LegalEntityId=${
      data.LegalEntityId
    }&Year=${data.Year}

    `
  );
}

export function GetcalculateExpensesService(
  data: IGetcalculateExpensesparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetcalculateExpenses?LegalEntityId=${
      data.LegalEntityId
    }&Year=${data.Year}

    `
  );
}

export function GetCapitalLossDetailsService(
  data: IGetCapitalLossDetailsparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetCapitalLossDetails?LegalEntityId=${
      data.LegalEntityId
    }&Year=${data.Year}

    `
  );
}

export function K1ReconciliationLPBreakdownService(
  data: IGetCapitalLossDetailsparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/K1ReconciliationLPBreakdown?LegalEntityId=${
      data.LegalEntityId
    }&Year=${data.Year}

    `
  );
}

export function GetK1ReconciliationByInvestmentService(
  data: IGetK1ReconciliationByInvestmentparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetK1ReconciliationByInvestment?LegalEntityId=${
      data.LegalEntityId
    }&TaxYear=${data.TaxYear}`
  );
}
// 1099-Div input-form
export function GetInputForm1099DivListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetTaxForm1099DIVData?LegalEntityId=${
      data.legalEntityId
    }&Year=${data.year}&SupplierId=${data.supplierId}
    `
  );
}

export function InputForm1099DivCUDService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_Tax_URL}/TaxManagement/TaxForm1099DIVCUD
    `,
    data
  );
}
export function InputForm1099DivDeleteService(data: any) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/DeleteTaxForm1099DIVById?Action=delete&Id=${data.id}
    `
  );
}
// end
// 1099 input-form
export function GetInputForm1099ListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetListTaxForm1O9OInitilization?LegalEntityId=${
      data.legalEntityId
    }&Year=${data.year}&SupplierId=${data.supplierId}
    `
  );
}

export function K1ReconciliationByInvestorService(
  data: IK1ReconciliationByInvestorparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/K1ReconciliationByInvestor?LegalEntityId=${
      data.LegalEntityId
    }&TaxYear=${data.TaxYear}`
  );
}

export function AddInputForm1099Service(data: any) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/AddUpdate1099FormSupplier`,
    data
  );
}

export function GetSupplierDropdownListService(
  data: ISupplierDropDownListParameter
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetDropDownFromSupplierForm?LegalEntityId=${
      data.legalEntityId
    }
    `
  );
}

export function GetSummaryDetailsService(data: IGetSummaryDetailsparameters) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetSummaryDetails?LegalEntityId=${
      data.LegalEntityId
    }&TaxYear=${data.TaxYear}&LPPartnerId=${data.LPPartnerId}`
  );
}

export function DeleteInputForm1099Service(data: any) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/Delete1099FormSupplier?TaxForm1099Id=${data.id}
    `
  );
}

// end

// uploadInvestork1s

export function GetUploadInvestorK1ListService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetK1DocumentList?LegalEntityId=${data}
    `
  );
}

export function GetViewDocumentInvestorK1Service(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetPartnerK1Documents?DocId=${data}
    `
  );
}

export function GetFundLevelReconcileIncomeService(
  data: IGetFundLevelReconcileIncomeparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetFundLevelReconcileIncome?Year=${
      data.Year
    }&LegalEntityId=${data.LegalEntityId}

    `
  );
}
export function GetUploadK1RevisionDropdownService() {
  return apiFactory().get(
    `${envVariable!.REACT_APP_API_Tax_URL}/TaxManagement/BindRevisionList
    `
  );
}

export function GetInvestorIncomeListService(
  data: IGetInvestorIncomeListparameters
) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetInvestorIncomeList?Year=${data.Year}&legalEntityId=${
      data.legalEntityId
    }

    `
  );
}
export function AddUploadK1InvestorDocumentService(data: any) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_Tax_URL}/TaxManagement/AddUpdateK1Document
    `,
    data
  );
}

export function DeleteUploadK1InvestorDocumentService(data: any) {
  return apiFactory().delete(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/DeleteK1Document?DocId=${data.docId}&LegalEntityId=${
      data.legalEntityId
    }
    `
  );
}

// end

// K1- input from supplier
export function GetK1InputSupplierLabelService(data: any) {
  return apiFactory().get(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/GetK1LabelInformation?legalEntityId=${
      data.legalEntityId
    }&TaxYear=${data.taxYear}&ProductId=${data.equityInvestmentId}&RevisionId=${
      data.revisionId
    }`
  );
}

export function AddK1InputSupplierFormService(data: IK1InputSupplierParameter) {
  return apiFactory().post(
    `${
      envVariable!.REACT_APP_API_Tax_URL
    }/TaxManagement/AddUpdatInputK1Supplier`,
    data
  );
}

export function SaveFinakK1GenerationService(
  data: ISaveFinakK1Generationparameters
) {
  return apiFactory().post(
    `${envVariable!.REACT_APP_API_Tax_URL}/TaxManagement/SaveFinakK1Generation
    `,
    data
  );
}
