import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Image } from "primereact/image";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";
import { setInvestorProfileDetailsRestLoading } from "../../../../store/Investor/investor-approval/verify-profile/get-data/slice";
import { GetInvestorListThunk } from "../../../../store/features/investor/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  // dateFormatter,
  convertUTCToLocalDate,
} from "../../../../utils/date-formatter";

interface IProps {
  openDialog?: boolean;
  setOpenDialog?: any;
  filterValue?: string;
}

const ManageInvestor: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const Viewapproveregistor = useAppSelector(
    (state) => state.GetInvestorList.data
  );
  const portState = useAppSelector((state) => state.GetInvestorList.loading);

  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  useEffect(() => {
    if (fundDropdown?.fundid) {
      const parameters = {
        LegalEntityId: fundDropdown?.fundid,
        ApprovalStatus: "A",
      };
      dispatch(setInvestorProfileDetailsRestLoading("loading"));
      dispatch(GetInvestorListThunk(parameters));
    }
  }, [fundDropdown?.fundid]);

  console.log("Viewapproveregistor", Viewapproveregistor);

  const actionBodyViewTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          // icon="pi pi-pencil"
          className="btn-nav"
          onClick={() => {
            // navigate(
            //   `../InvestorInformation/${rowData?.investorId}/${fundDropdown?.fundid}/${rowData?.investingAccountId}`
            // )

            navigate(
              `../ApprovedInvestor/${rowData?.investorId}/${fundDropdown?.fundid}/${rowData?.investingAccountId}`
            );
          }}
          type="button"
        >
          <span className="ml-auto mr-auto ">View Details</span>
        </Button>
      </React.Fragment>
    );
  };

  // const fullNameTemplate = (rowData: any) => {
  //   return `${rowData.firstName} ${rowData?.lastName}`;
  // };

  // const fundingDateFormater = (rowData: any) => {
  //   return rowData.fundingDate
  //     ? convertUTCToLocalDate(rowData.fundingDate)
  //     : "";
  // };
  const accredationLastVerifiedDateFormater = (rowData: any) => {
    return rowData.accredationLastVerifiedDate
      ? convertUTCToLocalDate(rowData.accredationLastVerifiedDate, "mm/dd/yyyy")
      : "";
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const dataElement = [
    {
      // field: "",
      header: "",
      body: actionBodyViewTemplate,
      bodyClassName: "text-center",
      style: { minWidth: "12rem" },
    },
    {
      field: "fullName",
      header: "Investor Name",
      style: { minWidth: "150px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.fullName}>
          {rowData?.fullName}
        </div>
      ),
      // body: fullNameTemplate,
      // filter: fullNameTemplate,
    },
    // {
    //   field: "noofInvestingAccounts",
    //   header: "# of investing accounts",
    // },
    {
      field: "iA_NickName",
      header: "Investing Account Name",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.iA_NickName}>
          {rowData?.iA_NickName}
        </div>
      ),
    },
    {
      field: "emailAddress",
      header: "Email Address",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.emailAddress}>
          {rowData?.emailAddress}
        </div>
      ),
    },
    {
      field: "city",
      header: "City",
      style: { minWidth: "150px" },
    },
    {
      field: "usState",
      header: "State",
      style: { minWidth: "120px" },
    },
    // {
    //   field: "fundingDate",
    //   header: "Funding Date",
    //   body: fundingDateFormater,
    //   filterElement: dateFilterTemplate,
    // },
    {
      field: "accredationLastVerifiedDate",
      header: "Accredation Date Last Verified",
      body: accredationLastVerifiedDateFormater,
      filterElement: dateFilterTemplate,
      style: { minWidth: "250px" },
    },
  ];
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  let content;
  switch (portState) {
    case "loading":
      content = <Skeleton className="col-12 md:col-6" height="5rem"></Skeleton>;
      break;
    case "succeeded":
      content = (
        <>
          <div className="col-12 col-sm-11 col-md-12  col-xl-12 ">
            <div className="card ProdCard m-2">
              <Card>
                <DataTableComponent
                  valueData={Viewapproveregistor}
                  fieldsElements={dataElement}
                  isPaginationAllowed={true}
                  openDailog={props.openDialog}
                  setOpenDailog={props.setOpenDialog}
                  globalFilter={props.filterValue}
                  scrollable={true}
                  columnResizeMode="expand"
                  className={"manageinvestortable gridcell dark-icon view"}
                  noGridLines={true}
                  maxHeight="calc(-190px + 100vh)"
                />
              </Card>
            </div>
          </div>
        </>
      );
      break;
  }

  return <>{content}</>;
};
export default ManageInvestor;
