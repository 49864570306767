// import { Button } from "primereact/button";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
// import { RiSearchLine } from "react-icons/ri";
import DataTableComponent from "../../../../components/data-table/DataTableComponent";

import Loader from "../../../../components/loader/Loader";
import { getPortfolioProductList } from "../../../../store/features/dropdown/productlist/slice";
// import { getPortfolioGetLegalEntityList } from "../../../../store/features/dropdown/slice";
// import { getInvestorsinFundCommits } from "../../../../store/features/reports/Investors-in-Fund/slice";
import {
  getInvestmentAllocationReport,
  setInvestmentAllocationReport,
} from "../../../../store/features/reports/investment-allocation-report/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

// import currencyFormat from "../../../../utils/currency-formatter";
import currencyFormat from "../../../../utils/currency-formatter";
import {
  convertUTCToLocalDate,
  getListDateConversion,
} from "../../../../utils/date-formatter";
// import { ExcelDataDownload } from "../../../../utils/excel-dataformat";

import { exportExcel } from "../../../../utils/excel-dataformat";
import InvestmentAllocationReportSkeleton from "./InvestmentAllocationReportSkeleton";

export default function InvestmentAllocationReport() {
  const dispatch = useAppDispatch();
  const portState = useAppSelector((state) => state.legalEntityList.loading);
  // const dropdownModel = useAppSelector((state) => state.legalEntityList.data);
  const productdropdownModel = useAppSelector(
    (state) => state.productList.data
  );
  const supplierdropdownModel = useAppSelector(
    (state) => state.productList.supplierList
  );
  const investmentAllocationmodel = useAppSelector(
    (state) => state.investmentAllocation.data
  );
  // const investmentAllocation = useAppSelector(
  //   (state) => state.investmentAllocation.data2
  // );

  const [exitData, setExitData] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [excelData, setExcelData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [productid, setproductid] = useState<any>();
  const [dataTableElements, setdataTableElements] = useState<any>();
  const [loading, setloading] = useState<any>(false);
  const [openDailog, setOpenDailog] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [totalInvestmentAmount, setTotalInvestmentAmount] = useState(0);
  const [capitalAllocatedToInvestors, setCapitalAllocatedToInvestors] =
    useState(0);
  const [capitalNotAllocatedToInvestors, setCapitalNotAllocatedToInvestors] =
    useState(0);
  // const [excelDataExport, setExcelDataExport] = useState<any>([]);
  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const staticColumns = [
    {
      field: "InvestorName",
      header: "Investor Account",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.InvestorName}>
          {rowData?.InvestorName}
        </div>
      ),
    },
    {
      field: "AmountAllocated",
      header: "Amount Allocated",
      body: (rowData: any) => currencyFormat(rowData.AmountAllocated),
      style: { minWidth: "200px" },
    },
    {
      field: "PercentageAllocated",
      header: "% Allocated",
      style: { minWidth: "200px" },
    },
    {
      field: "EmailAddress",
      header: "Email Address",
      style: { minWidth: "200px" },
      body: (rowData: any) => (
        <div className="left-align" title={rowData?.EmailAddress}>
          {rowData?.EmailAddress}
        </div>
      ),
    },
    {
      field: "InvestmentStartDate",
      header: "Investment Start Date",
      body: (rowData: any) =>
        convertUTCToLocalDate(rowData.InvestmentStartDate),
      filterElement: dateFilterTemplate,
      dataType: "date",
      style: { minWidth: "200px" },
    },
    {
      field: "InvestorAllocationDate",
      header: "Investor Allocation Date",
      body: (rowData: any) =>
        convertUTCToLocalDate(rowData.InvestorAllocationDate),
      filterElement: dateFilterTemplate,
      dataType: "date",
      style: { minWidth: "200px" },
    },
  ];
  // const exportExcelHeader = [
  //   {
  //     field: "InvestorName",
  //     header: "Investor Account",
  //   },
  //   {
  //     field: "AmountAllocated",
  //     header: "Amount Allocated",
  //     body: (rowData: any) => currencyFormat(rowData.AmountAllocated),
  //     dataType: "currency",
  //   },
  //   {
  //     field: "PercentageAllocated",
  //     header: "% Allocated",
  //   },
  //   {
  //     field: "EmailAddress",
  //     header: "Email Address",
  //   },
  //   {
  //     field: "InvestmentStartDate",
  //     header: "Investment Start Date",
  //     body: (rowData: any) =>
  //       convertUTCToLocalDate(rowData.InvestmentStartDate),
  //     dataType: "date",
  //   },
  //   {
  //     field: "InvestorAllocationDate",
  //     header: "Investor Allocation Date",
  //     body: (rowData: any) =>
  //       convertUTCToLocalDate(rowData.InvestorAllocationDate),
  //     dataType: "date",
  //   },
  //   {
  //     field: "InvestmentName",
  //     header: "Investment Name",
  //   },
  //   {
  //     field: "LookupCode",
  //     header: "LookupCode",
  //   },
  // ];

  // useEffect(() => {
  //   setExcelData(ExcelDataDownload(exportExcelHeader, exitData));
  // }, [exitData]);
  // console.log("Header Change", ExcelDataDownload(exportExcelHeader, exitData));
  const exportExcelHeader = [
    {
      field: "InvestorName",
      header: "Investor Account",
    },
    {
      field: "AmountAllocated",
      header: "Amount Allocated",
      dataType: "currency",
    },
    {
      field: "PercentageAllocated",
      header: "% Allocated",
    },
    {
      field: "EmailAddress",
      header: "Email Address",
    },
    {
      field: "LookupCode",
      header: "Lookup Code",
    },
    {
      field: "InvestorAllocationDate",
      header: "Investor Allocation Date",
      dataType: "date",
    },
    {
      field: "InvestmentStartDate",
      header: "Investment Start Date",
      dataType: "date",
    },
  ];
  // console.log(dataTableComponent);

  // const columns = createcolumns();
  // console.log("COLUMN=>", columns);

  // console.log("columns", columns);
  // const legalEntityList: { code: number; name: string }[] = [];
  // for (let i = 0; i < dropdownModel?.length; i++) {
  //   legalEntityList.push({
  //     code: dropdownModel![i].legalEntityID,
  //     name: dropdownModel![i].legalEntityName,
  //   });
  // }
  const productList: { code: number; name: string }[] = [];
  for (let i = 0; i < productdropdownModel?.length; i++) {
    productList?.push({
      code: productdropdownModel![i].productID,
      name: productdropdownModel![i].productName,
    });
  }

  const suppilerList: { code: number; name: string }[] = [];
  for (let i = 0; i < supplierdropdownModel?.length; i++) {
    suppilerList.push({
      code: supplierdropdownModel![i].productID,
      name: supplierdropdownModel![i].supplierProductCode,
    });
  }

  // useEffect(() => {
  //   const downloadList = exitData?.map((elem: any) => {
  //     const obj = {
  //       Manager: elem?.InvestorName,
  //       Amount_Allocated: currencyFormat(elem?.AmountAllocated),
  //       Allocated_Percentage: elem?.PercentageAllocated,
  //       EmailAddress: elem?.EmailAddress,
  //       Investment: elem?.InvestmentName,
  //       LookupCode: elem?.LookupCode,
  //       Investment_Start_Date: convertUTCToLocalDate(elem?.InvestmentStartDate),
  //       Investor_Allocation_Date: convertUTCToLocalDate(
  //         elem?.InvestorAllocationDate
  //       ),
  //     };
  //     return obj;
  //   });
  //   console.log(downloadList);
  //   setExcelDataExport(downloadList);
  // }, [exitData]);
  // const formatCurrency = (rowData: any) => {
  //   const finalValue = Number(rowData.returnRange).toFixed(2);
  //   const parseValue = currencyFormat(Number(finalValue));

  //   return `${parseValue}`;
  // };

  // let parameters = {
  //   legalEntityId:3 ,
  // };

  // const [leagalentityerror, setleagalentityerror] = useState<any>("");
  const [producterror, setproducterrorerror] = useState<any>("");

  const dropdown = useAppSelector((state) => state.navbardropdown.data);
  const fatchData = () => {
    let isvaild = true;
    setNoDataFound(true);
    dispatch(
      setInvestmentAllocationReport({ data: "", data2: "", totalCount: 0 })
    );

    setproducterrorerror("");
    if (!productid) {
      setproducterrorerror("Product name is required");
      isvaild = false;
    }
    if (isvaild) {
      const filerentityListparameters = {
        LegalEntityId: dropdown.fundid,
        ProductID: productid,
      };
      setloading(true);
      dispatch(getInvestmentAllocationReport(filerentityListparameters))
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    }
  };

  useEffect(() => {
    setFilterData(
      getListDateConversion(exitData, [
        "InvestmentStartDate",
        "InvestorAllocationDate",
      ])
    );
  }, [exitData]);
  console.log("ExitDataHere", filterData);

  useEffect(() => {
    if (dropdown.fundid !== undefined && dropdown.fundid > 0) {
      setExitData([]);
      setloading(true);
      const parameters = {
        legalEntityId: dropdown.fundid,
      };
      dispatch(getPortfolioProductList(parameters)).finally(() => {
        setloading(false);
        setproductid("");
        setNoDataFound(false);
        setproducterrorerror("");
      });
    }
    dispatch(
      setInvestmentAllocationReport({ data: "", data2: "", totalCount: 0 })
    );
  }, [dropdown.fundid]);

  useEffect(() => {
    // setExitDate(
    //   getListDateConversion(investmentAllocationmodel, ["fundingDate"])
    // );
    setdataTableElements(staticColumns);
    // setdataTableElements(createcolumns());
    const returndata = JSON.parse(JSON.stringify(investmentAllocationmodel));
    if (returndata !== undefined && returndata !== "") {
      setExitData(JSON.parse(returndata));
      if (exitData.length > 0) {
        setTotalInvestmentAmount(returndata[0]?.TotalInvestmentAmount);
        setCapitalAllocatedToInvestors(
          returndata[0]?.CapitalAllocatedToInvestors
        );
        setCapitalNotAllocatedToInvestors(
          returndata[0]?.CapitalNotAllocatedToInvestors
        );
      }
    } else {
      setExitData([]);
      setTotalInvestmentAmount(0);
      setCapitalAllocatedToInvestors(0);
      setCapitalNotAllocatedToInvestors(0);
    }
  }, [investmentAllocationmodel]);

  useEffect(() => {
    if (exitData.length > 0) {
      setTotalInvestmentAmount(exitData[0]?.TotalInvestmentAmount);
      setCapitalAllocatedToInvestors(exitData[0]?.CapitalAllocatedToInvestors);
      setCapitalNotAllocatedToInvestors(
        exitData[0]?.CapitalNotAllocatedToInvestors
      );
    }
  }, [exitData]);
  useEffect(() => {
    setExitData([]);
    setNoDataFound(false);
    setTotalInvestmentAmount(0);
    setCapitalAllocatedToInvestors(0);
    setCapitalNotAllocatedToInvestors(0);
  }, [productid]);
  console.log("Data Table Element=>", dataTableElements);
  // useEffect(() => {
  //   setExcelDataExport(ExcelDataDownload(dataTableComponent, exitData));
  // }, [exitData]);

  // const rowDta = 10;
  const dt = useRef<DataTable>(null);
  // const exportCSV = (selectionOnly: any) => {
  //   dt.current!.exportCSV({ selectionOnly });
  // };
  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  let content;
  switch (portState) {
    case "loading":
      content = <InvestmentAllocationReportSkeleton />;

      break;
    case "succeeded":
      content = (
        <>
          {loading && <Loader />}
          <div className="grid col-12 flex align-items-center p-0">
            <div className="col-12 md:col-9  pl-1 p-0">
              <h1 className="pageHeader fs-2">Investment Cap Table</h1>
              <p className="pageSubHeader"> </p>
            </div>
            <div className="flex justify-content-end gap-2 md:col-3">
              <div className="flex align-items-center gap-2 mb-2">
                <Button
                  className="remove-shadow"
                  type="button"
                  // icon="pi pi-file"
                  // rounded
                  onClick={() =>
                    exportExcel(
                      exportExcelHeader,
                      filterData,
                      "Investment Cap Table",
                      true
                    )
                  }
                  data-pr-tooltip="exportExcel"
                  disabled={
                    filterData === undefined ||
                    filterData?.length === 0 ||
                    filterData === null
                  }
                >
                  {" "}
                  <span className="blueButtonShadowText">Download </span>
                </Button>
              </div>
              <div className="flex align-items-center gap-2 mb-2">
                <Button
                  className="btn-navActive"
                  type="button"
                  icon="pi pi-window-maximize"
                  disabled={
                    filterData === undefined ||
                    filterData?.length === 0 ||
                    filterData === null
                  }
                  onClick={() => setOpenDailog(true)}
                  title="View Full Screen"
                />
              </div>
            </div>
          </div>
          {/* <div className="grid"> */}
          {/* <div className="col-12"> */}
          <div className="card">
            {/* <Card> */}
            {/* <h4 className="cardHeader">Investment Cap Table</h4>
            <div className="row my-4 d-flex justify-content-between"> </div> */}

            <div className="row mm:col-6 wrap align-items-center col-12 ml-2 ">
              <div className="col-12 md:col-4 m-0 mb-md-0 ">
                <label className="inputLabel">Select Investment Name*</label>
                <Dropdown
                  name="Select product name *"
                  value={productid}
                  //   value={}
                  onChange={(e) => {
                    setproductid(e.value);
                    e.value && setproducterrorerror("");
                  }}
                  resetFilterOnHide={true}
                  showFilterClear={true}
                  style={{ width: "100%" }}
                  options={productList!}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Select Investment Name"
                  filter
                />
                <p className="errormsg">{producterror}</p>
              </div>
              <div className="col-12 md:col-3 m-0 mb-md-0 ">
                <label className="inputLabel">Lookup Code</label>
                <Dropdown
                  name="Select supplier product code *"
                  value={productid}
                  //   value={}
                  onChange={(e) => {
                    setproductid(e.value);
                  }}
                  style={{ width: "100%" }}
                  options={suppilerList}
                  optionLabel="name"
                  optionValue="code"
                  resetFilterOnHide={true}
                  showFilterClear={true}
                  placeholder="Select Lookup Code"
                  filter
                />
                <p className="errormsg"></p>
              </div>
              <div className="col-12 md:col-2 mb-2 m-0 mb-md-0 mt-4">
                <div className="d-flex align-items-center mb-2">
                  <Button
                    className=" btn-nav  justify-content-center"
                    type="button"
                    // icon="pi pi-file"
                    // rounded
                    onClick={() => fatchData()}
                    data-pr-tooltip="Show"
                  >
                    {" "}
                    <span className="ml-auto mr-auto ">Show </span>
                  </Button>
                </div>
              </div>
            </div>

            {exitData?.length > 0 ? (
              <>
                <div className="formgrid grid px-2">
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                    <label className="inputLabel">
                      Total Investment Amount{" "}
                    </label>
                    <InputText
                      name="Total Investment Amount"
                      value={currencyFormat(totalInvestmentAmount)}
                      // onChange={(e) => handleChange(e)}

                      className="card-inputBox"
                      disabled
                    />
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                    <label className="inputLabel">Total Allocated</label>
                    <InputText
                      name="Total Allocated"
                      value={currencyFormat(capitalAllocatedToInvestors)}
                      // onChange={(e) => handleChange(e)}
                      className="card-inputBox"
                      disabled
                    />
                  </div>
                  <div className="d-block sm:d-flex sm:flex-column p-2 col-12 sm:col-4">
                    <label className="inputLabel">Total Not Allocated </label>
                    <InputText
                      name="Total Not Allocated"
                      value={currencyFormat(capitalNotAllocatedToInvestors)}
                      // onChange={(e) => handleChange(e)}
                      className="card-inputBox"
                      disabled
                    />
                  </div>
                </div>
                <DataTableComponent
                  valueData={filterData}
                  fieldsElements={staticColumns}
                  isPaginationAllowed={true}
                  openDailog={openDailog}
                  setOpenDailog={setOpenDailog}
                  // isDownload={true}
                  ref={dt}
                  className="gridcell"
                  scrollable={true}
                  maxHeight="calc(-345px + 100vh)"
                  // excelData={excelData}
                  fileName="Investment Cap Table"
                  columnResizeMode="expand"
                />
              </>
            ) : noDataFound && !loading ? (
              <div>No Data Found</div>
            ) : (
              <></>
            )}
          </div>
        </>
      );
      break;
  }
  return <>{content}</>;
}
