/* eslint-disable unicorn/no-null */
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import React, { memo, useEffect, useRef, useState } from "react";
import { RiFilterLine } from "react-icons/ri";
import { getInvestingAccountsLookupsThunk } from "../../store/Investor/investor-approval/investing-accounts-lookups/slice";
import { getThunkLookupCode } from "../../store/features/dropdown/lookup-code/slice";
import { getThunkSupplier } from "../../store/features/dropdown/supplier/slice";
import { getThunkInvestmentByFilter } from "../../store/features/investment/investment-filter/slice";
import { getThunkInvestment } from "../../store/features/investment/list/slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

interface IProps {
  listPage: boolean;
  filterReset?: boolean;
  setFilterReset?: any;
  pageName?: any;
  setProductObjectByFilter?: any;
  productObjectByFilter?: any;
  productState?: any;
  productId?: any;

  setsupplierId: any;
  setcity?: any;
  setstateCode?: any;
  setlookUpCode?: any;
  setproductId?: any;
  Serchfilters?: any;
  setProductFilterchipsFilterchips?: any;
  setsupplierchips?: any;
  setcitychips?: any;
  setstateCodechips?: any;
  setlookUpCodechips?: any;
  setCountryChips?: any;
  supplierId?: any;
  city?: any;
  stateCode?: any;
  lookUpCode?: any;
  countryId?: any;
  setCountryId?: any;
  pooledFund?: string;
}

function InvestmentFilter(props: IProps) {
  const op = useRef<any>(null);
  const PreventFirstTimeRun = useRef(true);
  const [filter, setFilter] = useState(false);
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const [supplierchips, setsupplierchips] = useState<any>();
  const [citychips, setcitychips] = useState<any>();
  const [stateCodechips, setstateCodechips] = useState<any>();
  const [lookUpCodechips, setlookUpCodechips] = useState<any>();
  const [countryChips, setCountryChips] = useState<any>();

  const supplier = useAppSelector((state) => state.supplierList.data);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const countryDropdown = useAppSelector(
    (state) => state.investingAccountsLookups.countriesDropdown
  );
  const investmentFilerId = useAppSelector(
    (state) => state.investmentFilterById.data
  );
  const pageUpdateName = useAppSelector((state) => state.updatePage.data);
  const [productInfo, setProductInfo] = useState<any>();
  const lookupDropDownState = useAppSelector(
    (state) => state.lookupDropDown.data
  );
  const listDealInvestment = useAppSelector(
    (state) => state.investmentByFilter.data
  );
  const [InvDrpDownProductId, setInvDrpDownProductId] = useState<any>();
  const [supplierId, setsupplierId] = useState<any>();
  const [city, setcity] = useState<any>();
  const [stateCode, setstateCode] = useState<any>();
  const [lookUpCode, setlookUpCode] = useState<any>();
  const [countryId, setCountryId] = useState<any>(0);

  const countryValue: any = countryDropdown
    ? countryDropdown?.find((item) => item.value === "United States")
    : 0;
  const supplierDropdownList: any = [];

  supplier?.map((item: any) => {
    //
    return supplierDropdownList.push({
      name: item.supplierName,
      code: item.supplierID,
    });
  });
  const formik = useFormik({
    initialValues: {
      productName: 0,
      supplierId: 0,
      supplierProductCode: "",
      city: "",
      stateCode: "",
      countryId: 0,
    },
    onSubmit: (data) => {},
  });
  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        supplierId:
          formik.values.supplierId === 0 ? 0 : formik.values.supplierId,
        productState: pageUpdateName.offering,
      };

      dispatch(getThunkLookupCode(parameter));
    }
  }, [fundDropdown, formik.values.supplierId, pageUpdateName.offering]);

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        ProductState:
          props.productState === "" ? pageUpdateName.offering : "PreFunded",
        pooledFund: props.pooledFund ? props.pooledFund : "all",
      };
      dispatch(getThunkInvestmentByFilter(parameter)).finally(() => {});
    }
  }, [fundDropdown.fundid, pageUpdateName.offering]);

  useEffect(() => {
    if (fundDropdown.fundid && PreventFirstTimeRun.current === false) {
      if (props.supplierId === null) {
        setsupplierId(null);
        setsupplierchips(null);
      }
      if (props.lookUpCode === null) {
        setlookUpCode(null);
        setlookUpCodechips(null);
      }
      if (props.countryId === null) {
        setCountryId(null);
        setCountryChips(null);
      }
      if (props.city === null) {
        setcity(null);
        setcitychips(null);
      }
      if (props.stateCode === null) {
        setstateCodechips(null);
        setstateCode(null);
      }
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        ProductState:
          props.productState === "" ? pageUpdateName.offering : "PreFunded",
        SupplierId: supplierId,
        City: props.city,
        StateCode: stateCode,
        LookUpCode: lookUpCode,
        CountryId: countryId,
        pooledFund: props.pooledFund ? props.pooledFund : "all",
      };
      dispatch(getThunkInvestmentByFilter(parameter)).finally(() => {});
    }
    PreventFirstTimeRun.current = false;
  }, [
    props.supplierId,
    props.stateCode,
    props.city,
    props.lookUpCode,
    props.productId,
    props.countryId,
  ]);
  const handleApiCall = async () => {
    console.log("formik", formik.values);
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameters = {
        legalEntityId: fundDropdown.fundid,
        supplierId: formik.values.supplierId,
        lookUpCode: formik.values.supplierProductCode,
        city: formik.values.city,
        stateCode: formik.values.stateCode,
        productName: formik.values.productName,
        productState: "",
        productId: investmentFilerId.productId,
        countryId: formik.values.countryId || null,
        pooledFund: props.pooledFund ? props.pooledFund : "all",
      };
      await dispatch(getThunkInvestmentByFilter(parameters)).then(
        (response) => {
          if (response.meta.requestStatus === "rejected") {
            //
          } else if (response.meta.requestStatus === "fulfilled") {
            if (response.payload.data !== null) {
              setProductInfo(response.payload.data.investmentsAndDeals);
              //  setProductFilter(response.payload.data.investmentsAndDeals);
            } else {
              // errorToastMessage(toast, "No data found");
              toast.current?.show({
                severity: "info",
                summary: "Info Message",
                detail: "No data found",
                life: 3000,
              });
              setProductInfo([]);
            }
          }
        }
      );
    }
  };
  const DropdownList: any = [];

  listDealInvestment?.map((item: any) => {
    //
    return DropdownList.push({
      key: item.productName,
      code: item.productID,
    });
  });

  useEffect(() => {
    if (props.filterReset === true) {
      console.log("reset inside");
      setFilter(false);
      formik.resetForm();
    }
  }, [props.filterReset === true]);
  console.log("d", props.filterReset);
  const handleChange = (e?: any) => {
    formik.setFieldValue("productName", "");
  };

  // set Filter id to object
  const handleNavigation = (idp: any) => {
    console.log(idp);
  };

  const productNameChange = (e: any) => {
    if (props.setFilterReset) {
      props.setFilterReset(false);
    }
    formik.setFieldValue("productName", e.value ? e.value : e.value);
  };
  const serachinvestment = () => {
    props.setcity(city);
    props.setlookUpCode(lookUpCode);
    props.setstateCode(stateCode);
    props.setCountryId(countryId);
    props.setsupplierId(supplierId);
    setInvDrpDownProductId(props.productId);
    props.setsupplierchips(supplierchips);
    props.setcitychips(citychips);
    props.setstateCodechips(stateCodechips);
    props.setlookUpCodechips(lookUpCodechips);
    props.setCountryChips(countryChips);
    const parameter = {
      legalEntityId: fundDropdown.fundid,
      ProductState:
        props.productState === "" ? pageUpdateName.offering : "PreFunded",
      SupplierId: supplierId,
      City: city,
      StateCode: stateCode,
      LookUpCode: lookUpCode,
      CountryId: countryId || null,
      pooledFund: props.pooledFund ? props.pooledFund : "all",
    };
    dispatch(getThunkInvestmentByFilter(parameter)).finally(() => {});
    const parameterinvestment = {
      AvailablePageNo: 1,
      pageSize: 30,
      legalEntityId: fundDropdown.fundid,
      productState: pageUpdateName.offering,
      supplierId,
      city,
      stateCode,
      lookUpCode,
      InvDrpDownProductId,
      CountryId: countryId || null,
    };
    const updateBoday = {
      ...parameterinvestment,
      AvailablePageNo: 1,
    };
    console.log("getThunkInvestment called");
    dispatch(getThunkInvestment(updateBoday));
    // props.Serchfilters();
  };

  useEffect(() => {
    if (fundDropdown.fundid !== undefined && fundDropdown.fundid > 0) {
      const parameters = {
        legalEntityId: fundDropdown.fundid,
        productState: pageUpdateName.offering,
      };

      dispatch(getThunkSupplier(parameters));
      dispatch(getInvestingAccountsLookupsThunk());
    }
    formik.resetForm();
    props.setproductId(null);
    props.setsupplierId(null);
    props.setcity(null);
    props.setlookUpCode(null);
    props.setCountryId(null);
    props.setstateCode(null);
  }, [fundDropdown, pageUpdateName.offering]);

  // to select the product based on the supplier code and investmentId
  useEffect(() => {
    console.log("productInfo", productInfo);
    if (productInfo !== undefined) {
      if (
        formik.values.supplierProductCode !== "" &&
        productInfo.length === 1
      ) {
        formik.setFieldValue("productName", productInfo[0].productName);
        handleNavigation(productInfo[0]);
        op.current.hide();
        setFilter(false);
        if (props.setFilterReset) {
          props.setFilterReset(false);
        }
      } else if (
        investmentFilerId.productId !== 0 &&
        productInfo.length === 1
      ) {
        formik.setFieldValue("productName", productInfo[0].productName);
        handleNavigation(productInfo[0]);
        op.current.hide();
        setFilter(false);
        if (props.setFilterReset) {
          props.setFilterReset(false);
        }
      }
    }
  }, [productInfo]);

  useEffect(() => {
    console.log(
      "productObjectByFilter",
      props.productObjectByFilter?.productID
    );
    console.log("investmentId useeffect", investmentFilerId);
    formik.values.supplierId = 0;
    formik.values.supplierProductCode = "";
    formik.values.city = "";
    formik.values.stateCode = "";
    formik.values.productName = 0;
    formik.resetForm();
    console.log("dd", investmentFilerId.productId);
    if (
      investmentFilerId.productId !== undefined &&
      investmentFilerId.productId !== 0
    ) {
      formik.resetForm();

      handleApiCall();
    }
  }, [investmentFilerId]);

  const onHide = () => {
    setFilter(false);
  };

  const resetFunction = () => {
    props.setcitychips("");
    props.setstateCodechips("");
    props.setlookUpCodechips("");
    props.setsupplierchips("");
    props.setCountryChips("");
    props.setProductFilterchipsFilterchips("");

    const parameter = {
      legalEntityId: fundDropdown.fundid,
      ProductState: pageUpdateName.offering,
      pooledFund: props.pooledFund ? props.pooledFund : "all",
    };
    dispatch(getThunkInvestmentByFilter(parameter)).finally(() => {});

    props.Serchfilters();
  };
  useEffect(() => {
    setProductInfo([]);
  }, []);
  return (
    <>
      <Toast ref={toast} className="themeToast" />

      <div className="formgrid flex p-0 xl:col-12 md:col-11 ">
        <div className="d-flex flex-column p-0 col-10 ">
          {props.listPage !== true ? (
            <label className="inputLabel">
              {" "}
              {props.pageName === "AllocateEarning"
                ? "Investment Name"
                : "Deal/Investment Name"}
            </label>
          ) : (
            <></>
          )}

          <div>
            <div className="p-0 col-12 autocomplete-wrap">
              <Dropdown
                inputId="productID"
                name="productName"
                value={props.productId}
                options={DropdownList === null ? [] : DropdownList}
                filter
                resetFilterOnHide={true}
                showFilterClear={true}
                optionLabel="key"
                optionValue="code"
                style={{ width: "100%" }}
                aria-label="key"
                placeholder="Select Deal/Investment Name"
                onChange={(e: any) => {
                  props.setproductId(e.value);
                  productNameChange(e);
                  console.log("evenet", e);
                  props.setProductFilterchipsFilterchips(
                    e?.originalEvent?.target?.ariaLabel
                  );
                }}
                className="card-inputBox"
              />
            </div>{" "}
          </div>
        </div>

        <div className="d-flex flex-column pl-2">
          {props.listPage !== true ? (
            <label className="inputLabel">&nbsp;</label>
          ) : (
            <></>
          )}
          <Button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setFilter(!filter);
              op.current.toggle(e);
              // filterFields();
            }}
            className={
              filter ? "btn-navActive squareBtn p-2 " : "btn-nav squareBtn p-2 "
            }
          >
            <RiFilterLine className="iconInvestment" />
          </Button>
        </div>
        <OverlayPanel ref={op} showCloseIcon onHide={onHide} className="p-2">
          <div className="flex-wrap p-0 sm:flex-none col-12">
            <div className="d-flex flex-column pl-0 pt-2 p-1">
              <label className="inputLabel" style={{ fontSize: "16px" }}>
                Supplier
              </label>
              <Dropdown
                inputId="supplierId"
                name="supplierId"
                value={supplierId}
                options={
                  supplierDropdownList === null ? [] : supplierDropdownList
                }
                filter
                resetFilterOnHide={true}
                showFilterClear={true}
                optionLabel="name"
                optionValue="code"
                aria-label="name"
                placeholder="Select supplier"
                className="card-inputBox"
                onChange={(e: any) => {
                  handleChange(e);
                  formik.setFieldValue("supplierId", e.value);
                  setsupplierId(e.value);
                  setsupplierchips(e?.originalEvent?.target?.ariaLabel);
                }}
              />
            </div>
            <div className="d-flex flex-column pt-2 p-1 ">
              <label className="inputLabel" style={{ fontSize: "16px" }}>
                Lookup code
              </label>
              <Dropdown
                inputId="supplierProductCode"
                name="supplierProductCode"
                value={lookUpCode}
                options={
                  lookupDropDownState === null
                    ? []
                    : lookupDropDownState?.lookupDetails
                }
                filter
                resetFilterOnHide={true}
                showFilterClear={true}
                optionLabel="value"
                optionValue="value"
                aria-label="value"
                placeholder="Select Lookup code"
                className="card-inputBox"
                onChange={(e) => {
                  handleChange(e);
                  formik.setFieldValue("supplierProductCode", e.value);
                  setlookUpCode(e.value);
                  setlookUpCodechips(e.value);
                }}
              />
            </div>
            <div className="d-flex flex-column pt-2 p-1 ">
              <label className="inputLabel" style={{ fontSize: "16px" }}>
                City
              </label>
              <Dropdown
                inputId="city"
                name="city"
                value={city}
                options={
                  lookupDropDownState === null ? [] : lookupDropDownState?.city
                }
                filter
                resetFilterOnHide={true}
                showFilterClear={true}
                optionLabel="value"
                optionValue="value"
                placeholder="Select city"
                className="card-inputBox"
                disabled={supplierDropdownList.length === 0}
                onChange={(e) => {
                  handleChange(e);
                  formik.setFieldValue("city", e.value);
                  setcity(e.value);
                  setcitychips(e.value);
                  //
                }}
              />
            </div>
            <div className="d-flex flex-column pt-2 p-1 ">
              <label className="inputLabel" style={{ fontSize: "16px" }}>
                Country
              </label>
              <Dropdown
                inputId="countryId"
                name="countryId"
                value={countryId}
                options={countryDropdown === null ? [] : countryDropdown}
                filter
                resetFilterOnHide={true}
                showFilterClear={true}
                optionLabel="value"
                optionValue="key"
                aria-label="value"
                placeholder="Select Country"
                className="card-inputBox"
                disabled={supplierDropdownList.length === 0}
                onChange={(e: any) => {
                  handleChange(e);
                  formik.setFieldValue("countryId", e.value);
                  setCountryId(e.value);
                  // setstateCode(e.value);
                  setCountryChips(e?.originalEvent?.target?.ariaLabel);
                  setstateCode(null);
                  setstateCodechips(null);
                  //
                }}
              />
            </div>
            <div className="d-flex flex-column pt-2 p-1 ">
              <label className="inputLabel" style={{ fontSize: "16px" }}>
                State
              </label>
              {countryId === countryValue.key ||
              countryId === 0 ||
              countryId === null ? (
                <Dropdown
                  inputId="stateCode"
                  name="stateCode"
                  value={stateCode}
                  options={
                    lookupDropDownState === null
                      ? []
                      : lookupDropDownState?.stateDetails
                  }
                  filter
                  resetFilterOnHide={true}
                  showFilterClear={true}
                  optionLabel="value"
                  optionValue="key"
                  aria-label="value"
                  placeholder="Select State"
                  className="card-inputBox"
                  disabled={supplierDropdownList.length === 0}
                  onChange={(e: any) => {
                    handleChange(e);
                    formik.setFieldValue("stateCode", e.value);
                    setstateCode(e.value);
                    setstateCodechips(e?.originalEvent?.target?.ariaLabel);
                    //
                  }}
                />
              ) : (
                <InputText
                  name="stateCode"
                  value={stateCode}
                  onChange={(e) => {
                    handleChange(e);
                    formik.setFieldValue("stateCode", e.target.value);
                    setstateCode(e.target.value);
                    setstateCodechips(e.target.value);
                  }}
                  className="card-inputBox"
                  placeholder="State"
                  maxLength={100}
                />
              )}
            </div>

            <div className="d-flex flex-row pt-2 p-1">
              <div className="d-flex flex-column p-2">
                <Button
                  className="btn-navActive"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setproductId(null);
                    setsupplierId(null);
                    setcity(null);
                    setlookUpCode(null);
                    setstateCode(null);
                    props.setproductId(null);
                    props.setsupplierId(null);
                    props.setcity(null);
                    props.setlookUpCode(null);
                    props.setstateCode(null);
                    props.setCountryId(null);
                    setCountryId(null);
                    formik.resetForm();
                    resetFunction();
                    op.current.toggle(false);
                  }}
                >
                  <span className="ml-auto mr-auto ">Clear Filters</span>
                </Button>
              </div>
              <div className="d-flex flex-column p-2">
                <Button
                  className="btn-navActive"
                  onClick={(e) => {
                    e.preventDefault();
                    setFilter(false);
                    serachinvestment();
                    op.current.toggle(false);
                  }}
                >
                  <span className="ml-auto mr-auto ">Apply Filter</span>
                </Button>
              </div>
            </div>
          </div>
        </OverlayPanel>
      </div>
    </>
  );
}

export default memo(InvestmentFilter);
