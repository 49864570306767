import * as xlsx from "xlsx";
import { ExcelColoumHeader } from "../types";
import { formatHelper } from "./helper";

export const saveAsExcelFile = (buffer: any, fileName: string) => {
  import("file-saver").then((module) => {
    if (module && module.default) {
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });

      module.default.saveAs(
        data,
        fileName +
          //  + "_export_" + Date.now()
          EXCEL_EXTENSION
      );
    }
  });
};

export const exportDataFormat = (
  columnHeader: ExcelColoumHeader[] | undefined,
  values: any,
  columnCheckConditionByPass: boolean
) => {
  console.log("columnHeader", columnHeader, "values", values);
  if (
    !columnHeader ||
    columnHeader?.length === 0 ||
    !values ||
    values?.length === 0
  ) {
    return [];
  }
  console.time("check data");
  const requiredList: any = [];
  // const columnHeaderFields = new Set(columnHeader.map((col) => col.field));
  for (const val of values) {
    const obj: any = {};
    for (const col of columnHeader) {
      if (
        Object.prototype.hasOwnProperty.call(val, col.field) ||
        columnCheckConditionByPass
      ) {
        obj[col.header] = formatHelper(
          col.dataType ? col.dataType : "",
          val[col.field]
        );
      }
    }
    requiredList.push(obj);
  }
  console.timeEnd("check data");
  return requiredList;
};

export const getIndexs = (arr: any, key: string) => {
  const indexes: number[] = [];
  for (const [i, element] of arr.entries()) {
    if (element?.dataType === key) {
      indexes.push(i);
    }
  }
  return indexes;
};

// export function excelDateFormat(date: Date) {
//   const formatter = new Intl.DateTimeFormat("en-US", {
//     day: "2-digit",
//     month: "2-digit",
//     year: "numeric",
//   });
//   return formatter.format(date);
// }

function getColumnLabel(n: any) {
  let label = "";
  while (n >= 0) {
    label = String.fromCodePoint((n % 26) + 65) + label;
    n = Math.floor(n / 26) - 1;
  }
  return label;
}

export const exportExcel = (
  headers: ExcelColoumHeader[] | undefined,
  values: any[],
  fileName: string | undefined,
  isFormat = false,
  columnCheckConditionByPass = false
) => {
  const worksheet = isFormat
    ? xlsx.utils.json_to_sheet(
        exportDataFormat(headers, values, columnCheckConditionByPass)
      )
    : xlsx.utils.json_to_sheet(values);

  if (isFormat && headers) {
    const datesIndexs: number[] = getIndexs(headers, "date");
    const currencyIndexs: number[] = getIndexs(headers, "currency");
    const percentIndexs: number[] = getIndexs(headers, "percent");
    const percentageIndexs: number[] = getIndexs(headers, "percentage");
    const strCurrencyIndexs: number[] = getIndexs(headers, "stringCurrency");
    const diffCurrencyIndexs: number[] = getIndexs(headers, "diffcurrency");
    for (let i = 2; i <= values.length + 1; i++) {
      for (const d of datesIndexs) {
        const char = String.fromCodePoint(65 + d);
        let dateValue = values[i - 2][headers[d].field]; // Get date value from data
        dateValue =
          typeof dateValue === "string" ? new Date(dateValue) : dateValue;
        // eslint-disable-next-line unicorn/prefer-number-properties
        if (dateValue instanceof Date && !isNaN(dateValue.getTime())) {
          worksheet[`${char}${i}`].t = "d"; // Set cell type to date
          worksheet[`${char}${i}`].z = "mm/dd/yyyy";
          worksheet[`${char}${i}`].v = dateValue;
        }
      }
      for (const n of percentIndexs) {
        const value = values[i - 2][headers[n].field];
        const char = getColumnLabel(n);
        const cellKey = `${char}${i}`;
        // Determine the number of decimal places
        const decimalPlaces = (
          value?.toString()?.replace("%", "")?.split(".")[1] || []
        ).length;
        let formatString = "0%";
        formatString =
          decimalPlaces > 0
            ? `0.${"0".repeat(decimalPlaces)}%;-0.${"0".repeat(
                decimalPlaces
              )}%;0.${"0".repeat(decimalPlaces)}%`
            : `0%;-0%;0%`;
        if (worksheet[cellKey]) {
          let numericValue = value;
          if (typeof value === "string" && value.includes("%")) {
            if (/^-?\d+(\.\d+)?%$/.test(value)) {
              numericValue = Number.parseFloat(value.replace("%", ""));
            } else {
              // Skip if it's not a valid percentage format
              continue;
            }
          }
          numericValue = Number(numericValue);
          worksheet[cellKey].t = "n"; // Set cell type as number
          worksheet[cellKey].z = formatString; // Apply the custom format string
          worksheet[cellKey].v = numericValue / 100; // Set the numeric value in the cell
        }
      }
      for (const n of percentageIndexs) {
        const value = values[i - 2][headers[n].field];
        const char = getColumnLabel(n);
        const cellKey = `${char}${i}`;
        // Determine the number of decimal places
        const decimalPlaces = (
          value?.toString()?.replace("%", "")?.split(".")[1] || []
        ).length;
        let formatString = "0%";
        formatString =
          decimalPlaces > 0
            ? `0.${"0".repeat(decimalPlaces)}%;-0.${"0".repeat(
                decimalPlaces
              )}%;0.${"0".repeat(decimalPlaces)}%`
            : `0%;-0%;0%`;
        if (worksheet[cellKey]) {
          let numericValue = value;
          if (typeof value === "string" && value.includes("%")) {
            if (/^-?\d+(\.\d+)?%$/.test(value)) {
              numericValue = Number.parseFloat(value.replace("%", ""));
            } else {
              // Skip if it's not a valid percentage format
              continue;
            }
          }
          numericValue = Number(numericValue);
          worksheet[cellKey].t = "n"; // Set cell type as number
          worksheet[cellKey].z = formatString; // Apply the custom format string
          worksheet[cellKey].v = numericValue / 100; // Set the numeric value in the cell
        }
      }

      for (const n of currencyIndexs) {
        // const char = String.fromCodePoint(65 + n);
        // worksheet[`${char}${i}`].z = '"$"#,##,##0.00_);-"$"#,##,##0.00';

        const char = getColumnLabel(n);
        const cellKey = `${char}${i}`;
        if (worksheet[cellKey]) {
          worksheet[cellKey].z = '"$"#,##0.00';
        }
      }
      for (const n of strCurrencyIndexs) {
        // const char = String.fromCodePoint(65 + n);
        // worksheet[`${char}${i}`].z = '"$"#,##,##0.00_);-"$"#,##,##0.00';

        const char = getColumnLabel(n);
        const cellKey = `${char}${i}`;
        if (worksheet[cellKey]) {
          worksheet[cellKey].z = '"$"#,##0.00';
        }
      }
      for (const n of diffCurrencyIndexs) {
        // const char = String.fromCodePoint(65 + n);
        // worksheet[`${char}${i}`].z = '"$"#,##,##0.00_);-"$"#,##,##0.00';

        const char = getColumnLabel(n);
        const cellKey = `${char}${i}`;
        if (worksheet[cellKey]) {
          worksheet[cellKey].z = '"$"#,##0.00';
        }
      }
    }
  }
  console.log("worksheet", worksheet);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  const excelBuffer = xlsx.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  saveAsExcelFile(excelBuffer, fileName || "Data");
};
