import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
// import { Column } from "primereact/column";

import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
// import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

// import DropdownComponent from "../../../../components/dropdpwn/DropDownComponent";

import InvestmentDropdownFilter from "../../../../components/investment-filter/InvestmentDropdownFilter";
import Loader from "../../../../components/loader/Loader";
// import { GetNonRegisteredInvestorListThunk } from "../../../../store/Investor/setupinvestorfees/getnonregisteredinvestorlist/slice";
import { AddExpenseReserveCUDThunk } from "../../../../store/features/accounting/debitcreditinvestoraccounts/AddExpenseReserveCUD/slice";
import { GetGPPartnerThunk } from "../../../../store/features/accounting/debitcreditinvestoraccounts/GetGPPartner/slice";
import { ManagerFeePayoutCUDThunk } from "../../../../store/features/accounting/debitcreditinvestoraccounts/ManagerFeePayoutCUD/slice";
import { GetLPPartnersByInvestmentIdThunk } from "../../../../store/features/accounting/debitcreditinvestoraccounts/lppartnerbyinvestment/slice";
import { OrdinaryDividendsCUDThunk } from "../../../../store/features/accounting/debitcreditinvestoraccounts/ordinary-dividends/slice";
import { SavePartnerAccountTransactionThunk } from "../../../../store/features/accounting/debitcreditinvestoraccounts/savepartneraccounttransaction/slice";
import { GetTransactionTypeThunk } from "../../../../store/features/accounting/debitcreditinvestoraccounts/transactiontype/slice";
import { ManageFeeCUDThunk } from "../../../../store/features/accounting/managefeecud/slice";
import { getThunkPortfolioRegisterInvestorListDropdown } from "../../../../store/features/dropdown/registeredinvestorlist/slice";
import { getThunkInvestmentByFilter } from "../../../../store/features/investment/investment-filter/slice";
import { getThunkInvestmentIdByProduct } from "../../../../store/features/operation/allocate-earning/get-investment-id-product/slice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { convertLocalDateToUTC } from "../../../../utils/date-formatter";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../../utils/toast-message";
// import currencyFormat from "../../../../utils/currency-formatter";
// import dateFormatter from "../../../../utils/date-formatter";
export default function DebitCreditInvestorAccounts() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  // const todayDate = new Date();
  const [loading, setloading] = useState(false);

  const profileData = useAppSelector((state) => state.profile.data![0]);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);

  const investorList = useAppSelector((state) => state.registerinvestor?.data);
  const partnerList = useAppSelector(
    (state) => state.GetLPPartnersByInvestmentId?.data
  );

  const partnerListObjData = useAppSelector(
    (state) => state.GetLPPartnersByInvestmentId?.objData
  );

  const GetGPPartner = useAppSelector((state) => state.GetGPPartner?.data);
  const transactionType = useAppSelector(
    (state) => state.GetTransactionType?.data
  );

  const filterInvestment = useAppSelector(
    (state) => state.investmentByFilter.data!
  );
  const investmentDropDown: any = [];

  filterInvestment?.map((item: any) => {
    //
    return investmentDropDown.push({
      name: item.productName,
      code: item.productID,
    });
  });
  //   const [name, setName] = useState<any>("");
  const [typeName, setTypeName] = useState<any>();
  const [productObjectByFilter, setProductObjectByFilter] = useState<any>();
  const [productIdFilter, setProductIdFilter] = useState("");
  const [error, setError] = useState(false);
  const [messageText, setMessage] = useState(false);
  const [lpPartnerType, setLPPartnerType] = useState("");
  // const [error, setError] = useState(false);
  useEffect(() => {
    typeName === "Debt Interest Earnings" ||
    typeName === "Ordinary Dividends" ||
    (typeName === "Expense Reserve" &&
      productObjectByFilter?.productID > 0 &&
      productObjectByFilter?.productID !== undefined)
      ? // Checking if investor account is selected
        setError(false)
      : setError(true);
  }, [productObjectByFilter]);
  console.log("transactionType 3  ", error);

  const formik = useFormik({
    initialValues: {
      Date: "",
      Remark: "",
      type: "",
      inv: "",
      partner: "",
      Withdraw: undefined,
      Deposit: undefined,
      productName: "",
    },
    validate: (data) => {
      //
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter transaction date";
      }
      if (!data.type) {
        errors.type = "Please select transaction type";
      }
      if (!data.inv) {
        errors.inv = "Please select investor account";
      }
      if (
        (!data.Deposit && typeName === "Cash Deposit to Account") ||
        (!data.Deposit && typeName === "Supplier Cash Interest") ||
        (!data.Deposit && typeName === "Debt Interest Earnings") ||
        (!data.Deposit && typeName === "Ordinary Dividends") ||
        (!data.Deposit && typeName === "Manager Fee" && lpPartnerType === "GP")
      ) {
        errors.Deposit = "Enter deposit amount";
      }

      if (data.Deposit && data.Deposit > 20_000_000_000) {
        errors.Deposit =
          "The deposit amount should be less than or equal to $20B";
      }

      if (
        (!data.Withdraw && typeName === "Cash Withdrawal from Account") ||
        (!data.Withdraw && typeName === "Expense Reserve") ||
        // (!data.Withdraw && typeName === "Manager Fee") ||
        (!data.Withdraw &&
          typeName === "Manager Fee" &&
          lpPartnerType === "LP") ||
        (!data.Withdraw && typeName === "Manager Fee Payout")
      ) {
        errors.Withdraw = "Enter withdrawal value";
      }

      if (data.Withdraw && data.Withdraw > 20_000_000_000) {
        errors.Withdraw =
          "The withdrawal amount should be less than or equal to $20B";
      }

      if (
        (typeName === "Debt Interest Earnings" ||
          typeName === "Expense Reserve" ||
          typeName === "Manager Fee" ||
          typeName === "Manager Fee Payout") &&
        !productObjectByFilter &&
        productObjectByFilter?.productID === undefined
      ) {
        setError(true);
      } else if (
        (typeName === "Debt Interest Earnings" ||
          typeName === "Expense Reserve" ||
          typeName === "Manager Fee" ||
          typeName === "Manager Fee Payout") &&
        productObjectByFilter?.productID > 0 &&
        productObjectByFilter?.productID !== undefined
      ) {
        setError(false);
      }
      console.log("errore", productObjectByFilter);
      return errors;
    },
    onSubmit: async (data) => {
      setloading(true);
      console.log("onSubmit", data);

      let feeAmount: any = formik.values.Withdraw! || 0;

      if (lpPartnerType === "GP" && typeName === "Manager Fee") {
        feeAmount = data.Deposit;
      } else if (lpPartnerType === "LP" && typeName === "Manager Fee") {
        feeAmount = data.Withdraw;
      }

      const param = {
        lpPartnerID: +formik.values.inv,
        transactionTypeID: +formik.values.type,
        credit: data.Deposit ? data.Deposit : 0,
        debit: data.Withdraw ? data.Withdraw : 0,
        transactionDate: convertLocalDateToUTC(formik.values.Date),
        notes: data.Remark,
        loginId: profileData.userID,
      };
      const reqParam = {
        lpPartnerId: +formik.values.inv,
        investmentId: +productIdFilter,
        transactionTypeId:
          typeName === "Ordinary Dividends"
            ? Number(formik.values.type.split("_")[0])
            : +formik.values.type,
        credit: formik.values.Deposit! || 0,
        debit: formik.values.Withdraw! || 0,
        transactionDate: convertLocalDateToUTC(formik.values.Date),
        notes: formik.values.Remark,
      };
      console.log("productIdFilter", reqParam);

      const manageParam = {
        legalEntityId: fundDropdown.fundid,
        lpPartnerId: +formik.values.inv,
        transactionDate: convertLocalDateToUTC(formik.values.Date),
        feeAmount,
        notes: formik.values.Remark,
      };

      console.log("param lpPartnerType", lpPartnerType, "typeName", typeName);
      const p = {
        action: "Create",
        legalEntityId: fundDropdown.fundid,
        gpPartnerId: +formik.values.inv,
        transactionDate: convertLocalDateToUTC(formik.values.Date),
        feeAmount,
        notes: formik.values.Remark,
      };

      console.log("param", p);

      if (typeName === "Ordinary Dividends") {
        const oridnaryDivObj = { ...reqParam, action: "Create" };
        console.log("tets", oridnaryDivObj);
        dispatch(OrdinaryDividendsCUDThunk(oridnaryDivObj))
          .then((res: any) => {
            console.log("Himani", res);
            if (res.payload) {
              successToastMessage(toast, "Data saved successfully");
              setTypeName("");
              formik.resetForm();
            } else {
              errorToastMessage(toast);
              formik.resetForm();
            }
          })
          .finally(() => {
            setloading(false);
            setTypeName("");
          });
      }
      if (
        typeName === "Debt Interest Earnings" ||
        typeName === "Expense Reserve"
      ) {
        dispatch(AddExpenseReserveCUDThunk(reqParam))
          .then((res: any) => {
            console.log("fgdfghg,jhn", res);
            if (res.payload) {
              successToastMessage(toast, "Data saved successfully");
              formik.resetForm();
              formik.setFieldValue("Date", new Date().toISOString());
              setTypeName("");
            } else {
              errorToastMessage(toast);
              formik.resetForm();
            }
          })
          .finally(() => {
            setloading(false);
            setTypeName("");
          });
      }
      if (
        typeName === "Cash Deposit to Account" ||
        typeName === "Supplier Cash Interest" ||
        typeName === "Cash Withdrawal from Account"
      ) {
        dispatch(SavePartnerAccountTransactionThunk(param))
          .then((res: any) => {
            console.log("fgdfghg,jhn", res);
            if (res.payload) {
              successToastMessage(toast, "Data saved successfully");
              // formik.setFieldValue("type", "");
              formik.setFieldValue("Date", new Date().toISOString());

              setTypeName("");
              formik.resetForm();
            } else {
              errorToastMessage(toast);
              formik.resetForm();
            }
          })
          .finally(() => {
            setloading(false);
            setTypeName("");
          });
      }
      if (typeName === "Manager Fee") {
        dispatch(ManageFeeCUDThunk(manageParam))
          .then((res: any) => {
            console.log("fgdfghg,jhn", res);
            if (res.payload) {
              successToastMessage(toast, "Data saved successfully");
              formik.setFieldValue("Date", new Date().toISOString());

              // formik.setFieldValue("type", "");
              setTypeName("");
              formik.resetForm();
            } else {
              errorToastMessage(toast);
              formik.resetForm();
            }
          })
          .finally(() => {
            setloading(false);
            setTypeName("");
          });
      }
      if (typeName === "Manager Fee Payout") {
        dispatch(ManagerFeePayoutCUDThunk(p))
          .then((res: any) => {
            console.log("fgdfghg,jhn", res);
            if (res.payload) {
              successToastMessage(toast, "Data saved successfully");
              formik.setFieldValue("Date", new Date().toISOString());

              // formik.setFieldValue("type", "");
              setTypeName("");
              formik.resetForm();
              setMessage(false);
            } else {
              errorToastMessage(toast);
              formik.resetForm();
            }
          })
          .finally(() => {
            setloading(false);
            setTypeName("");
          });
      }
    },
  });
  console.log(" formik.values.Deposit", productObjectByFilter === "");

  // const handleDropdownChange = (selectedValue: any) => {
  //   if (selectedValue) {
  //     formik.setFieldError("inv", "");
  //   } else {
  //     formik.setFieldError("inv", "Please select investor account");
  //   }
  // };
  console.log("type", typeName);
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    if (
      productObjectByFilter?.productID > 0 &&
      productObjectByFilter?.productID !== undefined
    ) {
      dispatch(
        getThunkInvestmentIdByProduct({
          productId: productObjectByFilter?.productID,
        })
      ).then((resp) => {
        if (resp.payload._response.message === "No data found") {
          setProductIdFilter(resp.payload._response.data.externalInvestmentId);
          console.log("productObjectByFilter inside if");
        } else {
          setProductIdFilter(
            resp.payload._response.data.externalInvestmentId.key
          );
          console.log("productObjectByFilter inside else");
        }
      });
    }
  }, [productObjectByFilter?.productID]);
  console.log("productObjectByFilter inside", productObjectByFilter);

  useEffect(() => {
    // setloading(true);
    // setMessage(false);
    formik.setFieldValue("Date", new Date().toISOString());

    // dispatch(GetTransactionTypeThunk())
    //   .then(() => {
    //     setloading(false);
    //   })
    //   .catch(() => {
    //     setloading(false);
    //   })
    //   .finally(() => {
    //     setloading(false);
    //   });
  }, []);

  useEffect(() => {
    if (fundDropdown.fundid) {
      setloading(true);
      setMessage(false);
      formik.setFieldValue("Date", new Date().toISOString());
      dispatch(GetTransactionTypeThunk())
        .then(() => {
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        })
        .finally(() => {
          setloading(false);
        });
      const parameter = {
        legalEntityId: fundDropdown.fundid,
        supplierId: 0,
        // productState: "PreFunded",
      };

      dispatch(getThunkInvestmentByFilter(parameter));
      formik.setFieldValue("Deposit", "");
      formik.setFieldValue("Withdraw", "");
      formik.setFieldValue("Date", "");
      formik.setFieldValue("type", "");
      formik.setFieldValue("inv", "");
      setTypeName("");
      setMessage(false);
      formik.setFieldValue("Remark", "");
    }
  }, [fundDropdown.fundid]);

  useEffect(() => {
    const reqParam = {
      LegalEntityId: fundDropdown.fundid,
    };
    // dispatch(GetNonRegisteredInvestorListThunk(reqParam));
    if (typeName) {
      console.log("inside if");
      if (
        typeName === "Cash Deposit to Account" ||
        typeName === "Supplier Cash Interest" ||
        typeName === "Cash Withdrawal from Account"
      ) {
        console.log("123");
        dispatch(getThunkPortfolioRegisterInvestorListDropdown(reqParam));
        formik.setFieldValue("Remark", "");
      }
      if (
        typeName === "Debt Interest Earnings" ||
        typeName === "Ordinary Dividends" ||
        typeName === "Expense Reserve" ||
        typeName === "Manager Fee"
      ) {
        console.log("123 -1");
        setloading(true);
        dispatch(
          GetLPPartnersByInvestmentIdThunk({
            InvestmentId: +productIdFilter,
          })
        ).finally(() => {
          setloading(false);
        });

        if (typeName && typeName === "Expense Reserve") {
          formik.setFieldValue("Remark", "Expense Reserve");
        }
        if (
          typeName &&
          typeName === "Expense Reserve" &&
          productObjectByFilter?.productID > 0 &&
          productObjectByFilter?.productID !== undefined
        ) {
          formik.setFieldValue(
            "Remark",
            `Expense Reserve for ${
              productObjectByFilter?.productID > 0 &&
              productObjectByFilter?.productID !== undefined
                ? productObjectByFilter?.productName
                : ""
            }`
          );
        }
        if (typeName && typeName === "Debt Interest Earnings") {
          formik.setFieldValue("Remark", `Debt Interest `);
        }
        if (
          typeName &&
          typeName === "Debt Interest Earnings" &&
          productObjectByFilter?.productID > 0 &&
          productObjectByFilter?.productID !== undefined
        ) {
          formik.setFieldValue(
            "Remark",
            `Debt Interest for ${
              productObjectByFilter?.productID > 0 &&
              productObjectByFilter?.productID !== undefined
                ? productObjectByFilter?.productName
                : ""
            }`
          );
        }

        if (typeName && typeName === "Manager Fee") {
          formik.setFieldValue("Remark", `Manager Fee `);
        }
        if (
          typeName &&
          typeName === "Manager Fee" &&
          productObjectByFilter?.productID > 0 &&
          productObjectByFilter?.productID !== undefined
        ) {
          formik.setFieldValue(
            "Remark",
            `Manager Fee for ${
              productObjectByFilter?.productID > 0 &&
              productObjectByFilter?.productID !== undefined
                ? productObjectByFilter?.productName
                : ""
            }`
          );
        }
      }
      if (typeName === "Manager Fee Payout") {
        console.log("123-1-2");

        dispatch(GetGPPartnerThunk(reqParam));
        if (typeName && typeName === "Manager Fee Payout") {
          formik.setFieldValue("Remark", "Manager Fee Payout");
        }
        if (
          typeName &&
          typeName === "Manager Fee Payout" &&
          productObjectByFilter?.productID > 0 &&
          productObjectByFilter?.productID !== undefined
        ) {
          formik.setFieldValue(
            "Remark",
            `Manager Fee Payout for ${
              productObjectByFilter?.productID > 0 &&
              productObjectByFilter?.productID !== undefined
                ? productObjectByFilter?.productName
                : ""
            }`
          );
        }
      }
    }

    // const parameter = {
    //   legalEntityId: fundDropdown.fundid,
    //   supplierId: 0,
    //   // productState: "PreFunded",
    // };

    // dispatch(getThunkInvestmentByFilter(parameter));
  }, [productIdFilter, typeName, productObjectByFilter?.productName]);

  useEffect(() => {
    setProductObjectByFilter("");
    setProductIdFilter("");
  }, [typeName]);

  useEffect(() => {
    formik.setFieldValue("Deposit", "");
    formik.setFieldValue("Withdraw", "");
    formik.setFieldValue("Date", "");
  }, [formik.values.type]);

  return (
    <>
      {loading && <Loader />}
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        <div className="col-12 md:col-6 mt-2">
          <div className="card pgHeaderWrap md:my-2 mt-3">
            <h1 className="pageHeader">Debit/Credit Investor Accounts</h1>
            <p className="pageSubHeader"> </p>
          </div>
        </div>
        <div className="col-12 md:col-6 mt-2">
          <div></div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card my-3 tabWithoutboxshadow">
            <Card>
              <>
                {" "}
                <div className="mb-4">
                  <div className="formgrid grid px-2 col">
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 md:col-4 ">
                      <label className="inputLabel">Transaction Type *</label>
                      <Dropdown
                        name="type"
                        style={{ width: "100%" }}
                        options={transactionType}
                        optionLabel="value"
                        optionValue="key"
                        placeholder="Select.."
                        onChange={(e) => {
                          formik.setFieldValue("type", e.value);
                          setTypeName(e.originalEvent.currentTarget.innerHTML);
                          e.originalEvent.currentTarget.innerHTML ===
                          "Manager Fee Payout"
                            ? setMessage(true)
                            : setMessage(false);
                        }}
                        value={formik.values.type}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("type"),
                          "card-inputBox": true,
                        })}
                      />
                      {messageText ? (
                        <span className="text-Dis">
                          Use this transaction type to reflect the transaction
                          for withdrawal of funds that were due to management
                          entity
                        </span>
                      ) : (
                        <></>
                      )}
                      {getFormErrorMessage("type")}
                    </div>
                    {(typeName && typeName === "Debt Interest Earnings") ||
                    typeName === "Ordinary Dividends" ||
                    typeName === "Expense Reserve" ||
                    typeName === "Manager Fee" ? (
                      <div className="d-block sm:d-flex sm:flex-column p-2 col-12 md:col-8 md:mt-1 ">
                        <InvestmentDropdownFilter
                          listPage={false}
                          setProductObjectByFilter={setProductObjectByFilter}
                          productObjectByFilter={productObjectByFilter}
                          productState="all"
                          pageName="productName"
                        />
                        {error && (
                          <small className="p-error">
                            Select investment name
                          </small>
                        )}
                        {getFormErrorMessage("productName")}
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 md:col-4">
                      <label className="inputLabel">Investor Account </label>
                      <Dropdown
                        name="inv"
                        style={{ width: "100%" }}
                        options={
                          typeName &&
                          (typeName === "Debt Interest Earnings" ||
                            typeName === "Ordinary Dividends" ||
                            typeName === "Expense Reserve" ||
                            typeName === "Manager Fee")
                            ? partnerList
                            : (typeName &&
                                typeName === "Cash Deposit to Account") ||
                              typeName === "Supplier Cash Interest" ||
                              typeName === "Cash Withdrawal from Account"
                            ? investorList
                            : typeName && typeName === "Manager Fee Payout"
                            ? GetGPPartner
                            : []
                        }
                        optionLabel={
                          typeName &&
                          (typeName === "Debt Interest Earnings" ||
                            typeName === "Ordinary Dividends" ||
                            typeName === "Expense Reserve" ||
                            typeName === "Manager Fee")
                            ? "value"
                            : (typeName &&
                                typeName === "Cash Deposit to Account") ||
                              typeName === "Supplier Cash Interest" ||
                              typeName === "Cash Withdrawal from Account"
                            ? "investorName"
                            : typeName && typeName === "Manager Fee Payout"
                            ? "lpPartnerName"
                            : ""
                        }
                        optionValue={
                          typeName &&
                          (typeName === "Debt Interest Earnings" ||
                            typeName === "Ordinary Dividends" ||
                            typeName === "Expense Reserve" ||
                            typeName === "Manager Fee")
                            ? "key"
                            : typeName === "Cash Deposit to Account" ||
                              typeName === "Supplier Cash Interest" ||
                              typeName === "Cash Withdrawal from Account"
                            ? "investorID"
                            : typeName === "Manager Fee Payout"
                            ? "lpPartnerID"
                            : ""
                        }
                        // optionLabel={"investorName"}
                        // optionValue={"investorID"}
                        placeholder="Select.."
                        onChange={(e) => {
                          formik.setFieldValue("inv", e.value);
                          console.log("e.value", e.value);
                          formik.setFieldValue("Deposit", "");
                          formik.setFieldValue("Withdraw", "");
                          console.log(
                            "partnerListObjData e.value",
                            partnerListObjData[e.value]
                          );
                          setLPPartnerType(
                            partnerListObjData[e.value].partnerType
                          );
                        }}
                        value={formik.values.inv}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("inv"),
                          "card-inputBox": true,
                        })}
                      />
                      {getFormErrorMessage("inv")}
                    </div>

                    <div
                      className={
                        typeName === "Cash Withdrawal from Account" ||
                        typeName === "Expense Reserve" ||
                        typeName === "Manager Fee" ||
                        typeName === "Manager Fee Payout"
                          ? "d-block sm:d-flex sm:flex-column p-2 col-12 md:col-4 annualClass"
                          : "d-block sm:d-flex sm:flex-column p-2 col-12 md:col-4   "
                      }
                    >
                      <label className="inputLabel">Deposit</label>
                      <InputNumber
                        name="Deposit"
                        className={classNames(
                          typeName === "Cash Withdrawal from Account" ||
                            typeName === "Expense Reserve" ||
                            (typeName === "Manager Fee" &&
                              lpPartnerType === "LP")
                            ? " disabled-Icon card-inputBox "
                            : "w-100"
                        )}
                        value={formik.values.Deposit}
                        disabled={
                          typeName === "Cash Withdrawal from Account" ||
                          typeName === "Expense Reserve" ||
                          (typeName === "Manager Fee" &&
                            lpPartnerType === "LP") ||
                          typeName === "Manager Fee Payout"
                        }
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        onValueChange={(e) => {
                          formik.setFieldValue("Deposit", e.target.value);
                        }}
                      />
                      {getFormErrorMessage("Deposit")}
                    </div>
                    <div
                      className={
                        typeName === "Cash Deposit to Account" ||
                        typeName === "Supplier Cash Interest" ||
                        typeName === "Debt Interest Earnings" ||
                        typeName === "Ordinary Dividends" ||
                        (typeName === "Manager Fee" && lpPartnerType === "GP")
                          ? "d-block sm:d-flex sm:flex-column p-2 col-12 md:col-4 annualClass"
                          : "d-block sm:d-flex sm:flex-column p-2 col-12 md:col-4   "
                      }
                    >
                      <label className="inputLabel">Charge/Withdraw</label>

                      <InputNumber
                        name="Withdraw"
                        className={classNames(
                          typeName === "Cash Deposit to Account" ||
                            typeName === "Debt Interest Earnings" ||
                            typeName === "Supplier Cash Interest" ||
                            typeName === "Ordinary Dividends" ||
                            (typeName === "Manager Fee" &&
                              lpPartnerType === "GP")
                            ? " disabled-Icon card-inputBox "
                            : "w-100"
                        )}
                        value={
                          formik.values.Withdraw === 0
                            ? undefined
                            : formik.values.Withdraw
                        }
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        onValueChange={(e) => {
                          formik.setFieldValue("Withdraw", e.target.value);
                        }}
                        disabled={
                          (typeName === "Manager Fee" &&
                            lpPartnerType === "GP") ||
                          typeName === "Cash Deposit to Account" ||
                          typeName === "Supplier Cash Interest" ||
                          typeName === "Debt Interest Earnings" ||
                          typeName === "Ordinary Dividends"
                        }
                      />
                      {getFormErrorMessage("Withdraw")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 md:col-4 ">
                      <label className="inputLabel">Transaction Date *</label>
                      <Calendar
                        name="Date"
                        value={
                          formik.values.Date === null ||
                          formik.values.Date === ""
                            ? undefined
                            : new Date(formik.values.Date)
                        }
                        onChange={(e) =>
                          formik.setFieldValue("Date", e.target.value)
                        }
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Date"),
                          "card-inputBox": true,
                        })}
                        // minDate={todayDate}
                        placeholder="mm/dd/yyyy"
                      />
                      {getFormErrorMessage("Date")}
                    </div>
                    <div className="d-block sm:d-flex sm:flex-column p-2 col-12 md:col-4 ">
                      <label className="inputLabel">Remark</label>

                      <InputText
                        name="Remark"
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Remark"),
                          "card-inputBox": true,
                        })}
                        onChange={(e) =>
                          formik.setFieldValue("Remark", e.target.value)
                        }
                        value={formik.values.Remark}
                      />
                      {getFormErrorMessage("Remark")}
                    </div>
                  </div>
                  <div className="formgrid grid px-2 col"></div>
                  <div className="formgrid grid px-2 col"></div>{" "}
                </div>
                <div className="d-flex justify-content-center mb-3 mt-4">
                  <Button
                    className="mr-4 btn-nav w-7rem justify-content-center"
                    onClick={(e) => {
                      e.preventDefault();
                      formik.resetForm();
                      setProductObjectByFilter("");
                      setTypeName("");
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="btn-nav w-7rem justify-content-center"
                    onClick={(e) => {
                      e.preventDefault();
                      // setIsFormSubmitted(true);
                      console.log("clicked");
                      formik.handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </>{" "}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
