import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Image } from "primereact/image";
import React from "react";
import { useNavigate } from "react-router-dom";
import ImageNotFound from "../../../../images/ImageNotFoundNew.png";
import "./card-component.scss";
import currencyFormat from "../../../../utils/currency-formatter";

interface IProps {
  p: {
    pageName: string;
    productID: number;
    productName: string;
    city: string;
    state: string;
    country: string;
    productPrimaryImage: string;
    showRemainingAllocation: any;
    remainingAllocation: any;
    unitPriceCalculated: number;
    targetReturn: any;
    productState: any;
    category: string | null;
    pooledfunds: string | null;
  };
  layout: string;
}

const CardComponent: React.FC<IProps> = (props) => {
  const ID: string = props.p.productID.toString();
  const navigate = useNavigate();
  // const [imageError, setImageError] = useState<boolean>(false);
  // const imgRef = useRef<any>(null);

  // propTypeEquity
  const img = props.p.productPrimaryImage
    ? props.p.productPrimaryImage
    : ImageNotFound;

  // const handleImageError = () => {
  //   setImageError(true);
  // };
  const gridLayout =
    props.layout === "gridLayout"
      ? "col-12 col-sm-6 col-md-4  col-xl-3"
      : "col-12 col-sm-6 col-md-6  col-xl-3";

  const countryName =
    props.p.country === "United States" ? undefined : props.p.country;
  const cityStateName =
    props.p.city !== null && props.p.city !== ""
      ? `${props.p.city?.charAt(0).toUpperCase() + props.p.city?.slice(1)}  , ${
          props.p.state
        }`
      : "";

  const cityStateCountry = countryName
    ? `${cityStateName} , ${countryName}`
    : cityStateName;
  const remainingAllocationAmount = props.p.remainingAllocation
    ? currencyFormat(+props.p.remainingAllocation * props.p.unitPriceCalculated)
    : currencyFormat(0);
  return (
    <>
      <div className={gridLayout}>
        <div className="card ProdCard">
          <Card>
            <div>
              <div className="prodimg-wrap">
                {/* {!imageError ? ( */}
                <Image
                  src={img}
                  alt="Image"
                  imageClassName="ProdImg"
                  // ref={imgRef}
                  // onError={handleImageError}
                />
                {countryName ? (
                  <span className="imageIconOverlay" title="International">
                    <i className="bi bi-globe2"></i>
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="propDetail-wrap ">
                <div className="flex flex-row justify-space-evenly">
                  <div className="mr-1">
                    <div className="">
                      <div className=" propDetailTitle-wrap ">
                        <div className="propName">{props.p.productName}</div>
                        <div
                          className="propAddr propCityCountry"
                          title={cityStateCountry}
                        >
                          {cityStateCountry}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {props.p.category && (
                      <div
                        className={`bagde-btn ${
                          props.p?.category?.toLocaleLowerCase() === "equity"
                            ? "bagde-btn-bge"
                            : "bagde-btn-bgd"
                        }`}
                      >
                        {props.p.category}
                      </div>
                    )}
                  </div>
                </div>
                <div className="propDetails flex-row justify-content-between">
                  <div className="title">Target Return:</div>
                  <div className="value">
                    <span
                      className="custom-target-icon"
                      data-pr-tooltip={props.p.targetReturn}
                      data-pr-position="right"
                      title={props.p.targetReturn}
                    >
                      {props.p.targetReturn}
                    </span>
                  </div>
                </div>
                {props.p.productState === "PreFunded" &&
                  props.p.showRemainingAllocation && (
                    <div className="propDetails flex-row justify-content-between">
                      <div className="title">Remaining Allocation:</div>
                      <div className="value">{remainingAllocationAmount}</div>
                    </div>
                  )}
                <div className="button flex justify-content-center text-center pt-3">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      // localStorage.setItem("navigationPage", "Opportunities");
                      props.p.pooledfunds === "Continuous"
                        ? navigate(`/Continuous/ManageOffering/${ID}`)
                        : navigate(`/ManageOffering/${ID}`);
                    }}
                    className="btn-dialog "
                  >
                    View Details
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
export default CardComponent;
