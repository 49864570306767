import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
// import { Card } from "primereact/card";
// import { Column } from "primereact/column";
// import { Column } from "primereact/column";
// import { ColumnGroup } from "primereact/columngroup";
// import { Row } from "primereact/row";
// import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";

import React, { useEffect, useRef, useState } from "react";
// import { NavLink } from "react-router-dom";

import DataTableComponent from "../../../components/data-table/DataTableComponent";
// import DropdownComponent from "../../../components/dropdpwn/DropDownComponent";

import Loader from "../../../components/loader/Loader";
import { K1ReconciliationLPBreakdownThunk } from "../../../store/features/taxmanagement/lplevelbreakdown/k1reconciliationlpbreakdown/slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import currencyFormat from "../../../utils/currency-formatter";
import { exportExcel } from "../../../utils/excel-dataformat";
import { convertExcelHeaders } from "../../../utils/helper";
// import currencyFormat from "../../../utils/currency-formatter";
// import dateFormatter from "../../../utils/date-formatter";

export default function LPLevelBreakdown() {
  const dispatch = useAppDispatch();
  const toast = useRef<Toast>(null);
  const previousYear: any = new Date().getFullYear();
  const currentYear = previousYear - 1;
  // const portState = useAppSelector((state) => state.GetMenuList.loading);
  const fundDropdown = useAppSelector((state) => state.navbardropdown.data);
  const profileData = useAppSelector((state) => state.profile.data![0]);
  const [openDailog, setOpenDailog] = useState(false);

  const listData = useAppSelector(
    (state) => state.K1ReconciliationLPBreakdown?.data2
  );
  const titleData = useAppSelector(
    (state) => state.K1ReconciliationLPBreakdown?.data1
  );
  const [dataCreateElemnts, setDataCreateElemnts] = useState<any>();
  const [dataTableElements, setdataTableElements] = useState<any>();
  const [exportExcelHeader, setExportExcelHeader] = useState<any>([]);
  const [stateName, setName] = useState<any>(false);
  const [loading, setloading] = useState<any>(false);

  const datatableArrayObj: any[] = [];

  const prepareData = (data: any, value: any) => {
    if (data !== undefined && data !== "") {
      const values = value ? JSON.parse(value) : [];
      const header = JSON.parse(titleData!);

      header.push(
        { LPPartnerId: "ProdTotal", InvName: "Total" },
        { LPPartnerId: "TranType", InvName: "TranType" }
      );

      for (const valueObj of values) {
        const obj: any = {};
        console.log("valueObj", valueObj);
        const itemsKey = Object.keys(valueObj);
        console.log("valueObj", itemsKey);

        for (const headerItem of header) {
          if (
            headerItem.LPPartnerId === "ProdTotal" &&
            "ProdTotal" in valueObj
          ) {
            obj[headerItem!.InvName] = currencyFormat(valueObj.ProdTotal);
          } else if (
            headerItem.LPPartnerId === "TranType" &&
            "TranType" in valueObj
          ) {
            obj[headerItem!.InvName] = valueObj.TranType;
          } else if (
            headerItem.LPPartnerId !== "ProdTotal" &&
            headerItem.LPPartnerId !== "TranType"
          ) {
            obj[headerItem!.InvName] =
              valueObj[headerItem.LPPartnerId] === "" ||
              valueObj[headerItem.LPPartnerId] === null ||
              valueObj[headerItem.LPPartnerId] === undefined
                ? currencyFormat(0)
                : currencyFormat(+valueObj[headerItem.LPPartnerId]);
            // (+valueObj[headerItem.LPPartnerId]).toFixed(2); // Default value if not present
          }
        }
        console.log("obj", obj);

        datatableArrayObj.push(obj);
        console.log("datatableArrayObj", datatableArrayObj);
      }
      return datatableArrayObj;
    } else {
      return [];
    }
  };

  const createcolumns = (headers: any) => {
    const header = headers ? JSON.parse(headers) : [];

    header.push(
      { LPPartnerId: 33, InvName: "Total" },
      { LPPartnerId: 34, InvName: "TranType" }
    );

    const allData = header.map((key: any) => ({
      field: key.InvName,
      header: key.InvName === "TranType" ? "" : key.InvName,
      style: { minWidth: "280px" },

      // body: currencyFormat(key.InvName),
    }));

    const partnerIDIndex = allData.findIndex(
      (item: any) => item.field === profileData.name
    );

    // If PartnerID and PartnerName are found, move them to indexes 0 and 1 respectively
    if (partnerIDIndex !== -1) {
      const partnerIDField = allData.splice(partnerIDIndex, 1)[0];
      allData.splice(1, 0, partnerIDField);
    }

    const tranTypeIndex = allData.findIndex(
      (item: any) => item.field === "TranType"
    );

    // If TranType is found and not already at index 0, move it to the beginning
    if (tranTypeIndex !== -1 && tranTypeIndex !== 0) {
      allData.splice(tranTypeIndex, 1);
      allData.unshift({
        field: "TranType",
        header: "",
        style: { minWidth: "280px" },
      });
    }

    for (const item of allData) {
      if (item.field !== "ProdTotal" && item.field !== "TranType") {
        item.value = Number.parseFloat(item.value).toFixed(2);
      }
    }
    console.log("alldata", allData);
    return allData;
  };

  useEffect(() => {
    if (listData?.length && titleData?.length) {
      console.log("useEffect");
      const header = createcolumns(titleData);
      setDataCreateElemnts(header);
      setExportExcelHeader(
        convertExcelHeaders(header, "stringCurrency", ["TranType"])
      );
      setdataTableElements(prepareData(header, listData));
      // setDataCreateElemnts(createcolumns(data3, title, list));
    }
  }, [listData, titleData]);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    document.body.style.overflowX = "unset";
    document.documentElement.style.overflowX = "unset";
    return () => {
      document.body.classList.remove("no-scroll");
      document.body.style.overflowX = "hidden";
      document.documentElement.style.overflowX = "hidden";
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      Date: currentYear.toString(),
    },
    validate: (data) => {
      // Validation logic remains unchanged
      const errors: any = {};

      if (data.Date === "") {
        errors.Date = "Please enter year";
      }

      return errors;
    },
    onSubmit: async (data) => {
      setloading(true);

      const params = {
        LegalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };
      console.log("data====", data); // Logging the prepared data for debugging

      dispatch(K1ReconciliationLPBreakdownThunk(params))
        .then(() => {
          setName(false);
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    },
  });

  useEffect(() => {
    setName(true);
    formik.resetForm();
  }, [fundDropdown.fundid]);
  const formikTouched: any = formik.touched;
  const formikErrors: any = formik.errors;
  const isFormFieldInvalid = (name: any) =>
    !!(formikTouched[name] && formikErrors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formikErrors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    if (formik.values.Date.toString() === currentYear.toString()) {
      setloading(true);

      const params = {
        LegalEntityId: fundDropdown.fundid,
        Year: formik.values.Date,
      };

      dispatch(K1ReconciliationLPBreakdownThunk(params))
        .then(() => {
          setName(false);
          setloading(false);
        })
        .catch(() => {
          setloading(false);
        });
    } else {
      setName(true);
    }
  }, [formik.values.Date]);

  return (
    <>
      <div className="grid">
        <Toast ref={toast} className="themeToast" />
        {loading && <Loader />}

        <div className="flex justify-content-between col-12 ">
          <div className="card pgHeaderWrap">
            <h1 className="pageHeader fs-2">
              K1 Reconciliation LP Level Breakdown
            </h1>
          </div>
          <div className=" col-12 md:col-3 gap-2 flex justify-content-end">
            <Button
              className="remove-shadow"
              type="button"
              // icon="pi pi-file"
              // rounded
              onClick={() =>
                exportExcel(
                  exportExcelHeader,
                  dataTableElements,
                  "K1 Reconciliation LP Level Breakdown",
                  true
                )
              }
              data-pr-tooltip="exportExcel"
              disabled={
                dataTableElements === undefined ||
                dataTableElements?.length === 0 ||
                dataTableElements === null
              }
            >
              {" "}
              <span className="blueButtonShadowText">Download </span>
            </Button>
            <div className="flex align-items-center mb-2">
              <Button
                className="btn-navActive"
                type="button"
                icon="pi pi-window-maximize"
                disabled={
                  dataTableElements === undefined ||
                  dataTableElements?.length === 0 ||
                  dataTableElements === null
                }
                onClick={() => setOpenDailog(true)}
                title="View Full Screen"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="col-12">
          <div className="card  tabWithoutboxshadow">
            {/* <Card> */}
            <>
              <div>
                <div className="formgrid grid col">
                  <div className="d-block sm:d-flex sm:flex-column mr-2 md:col-6 col-12">
                    {/* <label className="inputLabel">Year *</label> */}
                    <div className="card flex justify-content-center">
                      <Calendar
                        name="Date"
                        value={
                          formik.values.Date === null ||
                          formik.values.Date === ""
                            ? undefined
                            : new Date(+formik.values.Date, 0, 1)
                        }
                        onChange={(e) => {
                          const selectedYear = new Date(
                            Number(e.value)
                          ).getFullYear();
                          formik.setFieldValue("Date", selectedYear);
                        }}
                        className={classNames({
                          "p-invalid": isFormFieldInvalid("Date"),
                          "card-inputBox": true,
                        })}
                        placeholder="Select year"
                        view="year"
                        dateFormat="yy"
                      />
                      {getFormErrorMessage("Date")}
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <Button
                      className="mr-4 btn-nav w-7rem justify-content-center"
                      onClick={(e) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        // console.log("clicked");
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      className="btn-nav w-7rem justify-content-center"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        // setIsFormSubmitted(true);
                        // console.log("clicked");
                        formik.handleSubmit();
                      }}
                    >
                      Show
                    </Button>
                  </div>
                </div>
              </div>
            </>{" "}
            {/* <div className=""> */}
            {dataTableElements && !stateName && (
              <DataTableComponent
                valueData={dataTableElements}
                fieldsElements={dataCreateElemnts}
                // footerElement={footerGroup}
                // isDownload={true}
                isPaginationAllowed={true}
                fileName="K1 LPLevel Breakdown Report"
                scrollable={true}
                className="gridcell"
                columnResizeMode="expand"
                maxHeight={"calc(-205px + 100vh)"}
                isFullView={true}
                setOpenDailog={setOpenDailog}
                openDailog={openDailog}
              />
            )}
            {/* </div> */}
            {/* </Card> */}
          </div>
        </div>
      </div>
    </>
  );
}
